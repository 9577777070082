import { TimeApiResponse } from '@/shared/services/getTimeApiDate';
import { useMutation } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

const useCreateSessionMutation = () => 
  useMutation({
    mutationKey: ['createSession'],
    mutationFn: async (localizationData?: TimeApiResponse): Promise<any> => {
      return await post<any>('/v1.0/session', localizationData);
    },
  });

export default useCreateSessionMutation;