import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { del } from '@truefit/http-utils';

import useCognito from '../../authentication/hooks/useCognito';
import { TimeApiResponse, useTimeApiData } from '@/shared/services/getTimeApiDate';
import { GetGlobalSSEUrl } from '@/util';
import useCreateSessionMutation from '@/features/authentication/hooks/useCreateSession';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import CustomNotificationCenter from './CustomNotificationCenter';

// Types
export enum LocalSessionState {
  NOT_LOADED,
  LOADING,
  NO_LOCAL_SESSION,
  HAS_LOCAL_SESSION,
  AUTHENTICATED,
  READY,
}

export type ProgressMessage = {
  id: string;
  message: string;
  status: 'info' | 'success' | 'error';
  timestamp: number;
};

export const getSessionStateString = (state: LocalSessionState) => {
  const stateMap = {
    [LocalSessionState.NOT_LOADED]: 'NOT_LOADED',
    [LocalSessionState.LOADING]: 'LOADING',
    [LocalSessionState.NO_LOCAL_SESSION]: 'NO_LOCAL_SESSION',
    [LocalSessionState.HAS_LOCAL_SESSION]: 'HAS_LOCAL_SESSION',
    [LocalSessionState.AUTHENTICATED]: 'AUTHENTICATED',
    [LocalSessionState.READY]: 'READY',
  };

  return stateMap[state] || 'UNKNOWN';
};

// Context type definition
type AuthContextType = {
  sessionState: LocalSessionState;
  loading: boolean;
  sessionHandler: string;
  isExpiringSession: boolean;
  progressMessages: ProgressMessage[];
  handleSignOut: (bypassSessionDeletion?: boolean) => Promise<void>;
  addProgressMessage: (message: string, status?: 'info' | 'success' | 'error') => void;
  setIsExpiringSession: (isExpiring: boolean) => void;
};

// Create context with default values
const AuthContext = createContext<AuthContextType>({
  sessionState: LocalSessionState.NOT_LOADED,
  loading: true,
  sessionHandler: '',
  isExpiringSession: false,
  progressMessages: [],
  handleSignOut: async () => {},
  addProgressMessage: () => {},
  setIsExpiringSession: () => {},
});

// Provider component
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const cognito = useCognito();
  const timeApiDataQuery = useTimeApiData();
  const interactiveSession = useCreateSessionMutation();
  const sse = useRef<ReconnectingEventSource | null>(null);

  // State declarations
  const [sessionState, setSessionState] = useState<LocalSessionState>(
    cognito.authenticated ? LocalSessionState.AUTHENTICATED : LocalSessionState.NOT_LOADED,
  );
  const [loading, setLoading] = useState(true);
  const [sessionHandler, setSessionHandler] = useState<string | null>(null);

  const [isExpiringSession, setIsExpiringSession] = useState(false);
  const [progressMessages, setProgressMessages] = useState<ProgressMessage[]>([]);

  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession>();

  // Add progress message helper
  const addProgressMessage = useCallback(
    (message: string, status: 'info' | 'success' | 'error' = 'info') => {
      setProgressMessages((prev) => {
        // Mark previous info messages as success
        const updatedPrev = prev.map((msg) =>
          msg.status === 'info' ? { ...msg, status: 'success' as const } : msg,
        );

        // Add new message
        return [
          ...updatedPrev,
          {
            id: uuidv4(),
            message,
            status,
            timestamp: Date.now(),
          },
        ];
      });
    },
    [],
  );

  // Initialize with first message
  useEffect(() => {
    addProgressMessage('Initializing new CXVH player...');
  }, []);

  // Handle sign out process
  const handleSignOut = useCallback(
    async (bypassSessionDeletion = false) => {
      if (cognito.authenticated) {
        try {
          // Sign out from Cognito
          await new Promise<void>((resolve, reject) => {
            cognito.signOut({
              onSuccess: () => resolve(),
              onFailure: (error) => reject(error),
            });
          });

          // Delete session if not bypassed
          if (!bypassSessionDeletion) {
            await del('/v1.0/session');
          }
        } catch (error) {
          console.error('Error during sign-out process:', error);
        }

        // Close SSE connection
        if (sse.current) {
          sse.current.close();
          sse.current = null;
        }

        // Reset state
        setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        setSessionHandler(null);
        setLoading(false);
      }
    },
    [cognito, addProgressMessage],
  );

  // Process real-time SSE events
  const processRealtimeEvent = useCallback(
    (data: any, sessionId: string) => {
      const { event, payload } = data;

      // Validate payload session ID matches current session
      if (payload?.sessionId && payload.sessionId !== sessionId) {
        return;
      }

      switch (event) {
        case 'initialized':
          if (timeApiDataQuery.data) {
            interactiveSession.mutate(timeApiDataQuery.data as TimeApiResponse);
          } else {
            interactiveSession.mutate();
          }
          break;

        case 'session-deploy-error':
          addProgressMessage(payload.message || 'Cloud player deployment error', 'error');
          console.error('Session deployment error:', payload.message);
          break;

        case 'session-deploy-progress':
          addProgressMessage(payload.message || 'Deploying new cloud player', 'info');
          break;

        case 'session-ready':
          addProgressMessage('Cloud player ready', 'success');
          setSessionState(LocalSessionState.READY);
          setLoading(false);
          break;

        case 'session-handler':
          if (payload.handler) {
            setSessionHandler(payload.handler);
          }
          break;

        case 'session-ended':
        //  addProgressMessage('Cloud player session ended', 'error');
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
          handleSignOut(true);
          break;

        case 'session-expiring':
          addProgressMessage('Session expiring soon', 'info');
          setIsExpiringSession(true);
          break;

        default:
          // Ignore unhandled event types
          break;
      }
    },
    [interactiveSession, timeApiDataQuery.data, addProgressMessage, handleSignOut],
  );

  // Update Cognito context when session handler changes
  useEffect(() => {
    if (sessionHandler && cognitoSession) {
      console.log('Updating Cognito context with session handler:', sessionHandler);
      cognito.setHttpAuth(cognitoSession, sessionHandler);
      cognito.updateCognitoContext({ sessionHandler });
    }
  }, [sessionHandler, cognitoSession, addProgressMessage]);

  // Initial session check
  useEffect(() => {
    if (sessionState === LocalSessionState.NOT_LOADED && cognito) {
      setSessionState(LocalSessionState.LOADING);

      cognito.getSession({
        onSuccess(userSession) {
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);

          setCognitoSession(userSession);
        },
        onFailure(error) {
          console.warn('No valid session found:', error);
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
          setLoading(false);
        },
      });
    }
  }, [cognito, sessionState, addProgressMessage]);

  useEffect(() => {
    if (cognito.authenticated) {
      if (cognito.userSession) {
        setCognitoSession(cognito.userSession);
        setSessionState(LocalSessionState.AUTHENTICATED);
      }
    }
  }, [cognito.authenticated]);

  useEffect(() => {
    if (cognitoSession) {
      const sseUrl = GetGlobalSSEUrl(cognitoSession.getIdToken().getJwtToken());

      if (!sse.current) {
        console.log('Connecting to SSE:', sseUrl);
        try {
          sse.current = new ReconnectingEventSource(sseUrl);

          sse.current.onopen = () => {
            console.log('SSE connection established');
          };

          sse.current.onerror = (error) => {
            console.error('SSE connection error:', error);
          };

          sse.current.onmessage = (e) => {
            try {
              const data = JSON.parse(e.data);
              processRealtimeEvent(data, cognito.user?.getUsername() || '');
            } catch (error) {
              console.error('Error processing SSE message:', error);
            }
          };
        } catch (error) {
          console.error('Error establishing SSE connection:', error);
        }
      }
    }

    // Cleanup SSE connection on unmount
    return () => {
      if (sse.current) {
        sse.current.close();
        sse.current = null;
      }
    };
  }, [cognitoSession]);

  useEffect(() => {
    if (sessionState === LocalSessionState.NO_LOCAL_SESSION) {
      console.log('Redirecting to sign-in page...');
      window.location.href = '/sign-in';
    }
  }, [sessionState]);

  // Provide context value
  const contextValue = {
    sessionState,
    loading,
    sessionHandler,
    isExpiringSession,
    progressMessages,
    handleSignOut,
    addProgressMessage,
    setIsExpiringSession,
    getToken: ()=> cognitoSession?.getIdToken().getJwtToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      <CustomNotificationCenter />
    </AuthContext.Provider>
  );
};

// Custom hook for using the auth context
export const useAuth = () => useContext(AuthContext);



export default AuthContext;
