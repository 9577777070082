import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { HookFormDatePicker } from '../../forms/components';
import useGameTeamsQuery from '../hooks/useGameTeamsQuery';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { FormControl, InputLabel, Select, SelectChangeEvent, Grid } from '@mui/material';
import useListSeasonsQuery from '../hooks/useListSeasonsQuery';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { TeamModel } from '@/features/teams/types/TeamModel';

type Props = {
  onChange: (values: FormValues) => unknown;
  showAvailableToggle?: boolean; // New prop to control whether to show the toggle
};

export type FormValues = {
  date?: Date;
  team?: string;
  streamType: string[];
  seasonId: string;
  leagueId?: string;
  orgId?: string;
  available: boolean; // New field for availability
};

export const DEFAULT_VALUES: FormValues = {
  date: undefined,
  team: undefined,
  streamType: ['live', 'recorded', 'scheduled'],
  seasonId: ' ',
  leagueId: undefined,
  orgId: undefined,
  available: true, // Default to false
};

const GamesForm = ({ onChange, showAvailableToggle = false }: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const { watch, handleSubmit, control, register, reset } = methods;
  const [currentSeason, setCurrentSeason] = useState('');
  const { currentLeague, currentOrg } = React.useContext(UserStateContext);

  // For the teams query, pass undefined for seasonId when it's empty or " "
  const seasonIdForQuery =
    currentSeason && currentSeason !== ' ' ? parseInt(currentSeason) : undefined;
  const teamsQuery = useGameTeamsQuery(currentLeague?.id, seasonIdForQuery);
  const listSeasonsQuery = useListSeasonsQuery(currentLeague?.id as number);
  const [availableStr, setAvailableStr] = useState('');

  const handleFormChange = (values: FormValues) => {
    const { leagueId, orgId } = values;
    if (!leagueId || !orgId) return;
    onChange(values);

    if (values.available) {
      setAvailableStr('Show all');
    } else {
      setAvailableStr('Hide games');
    }
  };

  const handleReset = () => {
    // Reset form to default values
    reset({
      ...DEFAULT_VALUES,
      date: null, // Explicitly set date to null instead of undefined
      leagueId: currentLeague?.id?.toString(),
      orgId: currentOrg?.id?.toString()
    });
  
    // Reset current season state
    if (listSeasonsQuery.data) {
      const defaultSeason = listSeasonsQuery.data.find((season) => season.is_active === true);
      if (defaultSeason) {
        setCurrentSeason(defaultSeason.id.toString());
        methods.setValue('seasonId', defaultSeason.id.toString());
      } else {
        setCurrentSeason(' ');
        methods.setValue('seasonId', ' ');
      }
    }
  
    // Trigger onChange to update parent components
    handleFormChange({
      ...DEFAULT_VALUES,
      date: null, // Explicitly set date to null here too
      leagueId: currentLeague?.id?.toString(),
      orgId: currentOrg?.id?.toString(),
    });
    
    console.log('Resetting form to default values with null date');
  }

  useEffect(() => {
    if (listSeasonsQuery.status === 'success') {
      const defaultSeason = listSeasonsQuery.data.find((season) => season.is_active === true);
      if (defaultSeason) {
        setCurrentSeason(defaultSeason.id.toString());
        methods.setValue('seasonId', defaultSeason.id.toString());
      } else {
        setCurrentSeason(' ');
        methods.setValue('seasonId', '');
      }
    }
  }, [listSeasonsQuery.data]);

  useEffect(() => {
    if (currentLeague?.id) {
      methods.setValue('leagueId', currentLeague.id.toString());
      listSeasonsQuery.refetch();
      teamsQuery.refetch();
    }

    if (currentOrg?.id) {
      methods.setValue('orgId', currentOrg.id.toString());
    }
  }, [currentLeague, currentOrg]);

  // Add a separate effect to refetch teams when season changes
  useEffect(() => {
    if (currentLeague?.id) {
      teamsQuery.refetch();
    }
  }, [currentSeason]);

  // submits the form onChange
  // from https://stackoverflow.com/a/70119332
  useEffect(() => {
    const subscription = watch(handleSubmit(handleFormChange));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onChange]);

  return (
    <FormProvider {...methods}>
      <input value={currentLeague?.id} type="hidden" {...register('leagueId')} />
      <input value={currentOrg?.id} type="hidden" {...register('orgId')} />

      <Box p={3}>
        {/* Top row with filters */}
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={12} sm={3}>
            <Controller
              name="seasonId"
              control={control}
              render={({ field: { onChange: lOnChange, value } }) => {
                function handleSeasonChange(event: SelectChangeEvent<string>): void {
                  const newSeasonValue = event.target.value;
                  setCurrentSeason(newSeasonValue);
                  lOnChange(newSeasonValue);
                  // Don't need to manually trigger teamsQuery.refetch() here
                  // as it will be handled by the useEffect that watches currentSeason
                }

                return (
                  <FormControl fullWidth>
                    <InputLabel>Season</InputLabel>
                    {listSeasonsQuery &&
                      listSeasonsQuery.data &&
                      listSeasonsQuery.data?.length > 0 && (
                        <>
                          <Select
                            labelId="season-select-label"
                            id="season-select"
                            value={currentSeason}
                            label="Season"
                            onChange={handleSeasonChange}
                          >
                            <MenuItem value=" ">All Seasons</MenuItem>

                            {listSeasonsQuery.data.map((season) => (
                              <MenuItem key={season.id} value={season.id}>
                                {season.name}{' '}
                                {season.seasonType ? ` - ${season.seasonType.toUpperCase()}` : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                  </FormControl>
                );
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <HookFormDatePicker
              name="date"
              defaultValue={DEFAULT_VALUES.date}
              TextFieldProps={{ label: 'Date', fullWidth: true }}
              DatePickerProps={{ label: 'Date', inputFormat: 'MM/dd/yyyy' }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="team"
              control={control}
              render={({ field }) => {
                // Find the complete team object matching the current abbreviation value
                const selectedTeam = field.value
                  ? teamsQuery.data?.find((team) => team.abbreviation === field.value)
                  : null;

                return (
                  <Autocomplete
                    onChange={(_e, v: TeamModel) => {
                      field.onChange(v?.id.toString() || '');
                    }}
                    groupBy={(option) => option.abbreviation[0].toUpperCase()}
                    isOptionEqualToValue={(option, value) => option.abbreviation === value}
                    value={selectedTeam || field.value}
                    id="team-selector"
                    getOptionLabel={(option: any) => {
                      // Handle both TeamModel objects and string values
                      if (!option) return '';
                      if (typeof option === 'string') return option;
                      return option.abbreviation;
                    }}
                    options={teamsQuery.data || []}
                    renderOption={(props, option: any) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="40"
                          src={getTeamLogoPath(option)}
                          alt={option.abbreviation}
                        />
                        {option.abbreviation}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Team"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: selectedTeam ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                              <img
                                loading="lazy"
                                width="24"
                                src={getTeamLogoPath(selectedTeam)}
                                alt={selectedTeam.abbreviation}
                              />
                            </Box>
                          ) : null,
                        }}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
              color="secondary"
            >
              Reset Filters
            </Button>
          </Grid>
        </Grid>

        {/* Available toggle control - only shown if showAvailableToggle is true */}
        {showAvailableToggle && (
          <Box sx={{ mb: 2 }}>
            <Controller
              name="available"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      onChange={(e) => onChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={availableStr}
                />
              )}
            />
          </Box>
        )}

        {/* Bottom row with status chips */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Controller
            name="streamType"
            control={control}
            render={({ field: { onChange: lOnChange, value } }) => {
              const handleClick = (clickedValue: string) => () => {
                let newValue: string[];
                // Remove 'all' option handling
                const currentValues = Array.isArray(value) ? value : [];

                if (currentValues.includes(clickedValue)) {
                  // Remove the value if it's already selected
                  newValue = currentValues.filter((v) => v !== clickedValue);
                  // If nothing is selected, default to all stream types
                  if (newValue.length === 0) newValue = ['live', 'recorded', 'scheduled'];
                } else {
                  // Add the new value
                  newValue = [...currentValues, clickedValue];
                }
                lOnChange(newValue);
              };

              const isSelected = (testValue: string) =>
                Array.isArray(value) && value.includes(testValue);

              const variant = (testValue: string) =>
                isSelected(testValue) ? 'filled' : 'outlined';

              const color = (testValue: string) =>
                isSelected(testValue) ? (testValue === 'live' ? 'error' : 'primary') : 'default';

              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    label="Live"
                    variant={variant('live')}
                    onClick={handleClick('live')}
                    color={color('live')}
                  />
                  <Chip
                    label="Recorded"
                    variant={variant('recorded')}
                    onClick={handleClick('recorded')}
                    color={color('recorded')}
                  />
                  <Chip
                    label="Upcoming"
                    variant={variant('scheduled')}
                    onClick={handleClick('scheduled')}
                    color={color('scheduled')}
                  />
                </Box>
              );
            }}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default GamesForm;
