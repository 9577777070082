import { Tooltip, IconButton, Box, CircularProgress } from '@mui/material';
import { Delete, Edit, Send, NoAccounts, PersonAddAlt } from '@mui/icons-material';
import { useState } from 'react';

interface Props {
  currentUser: { id: string };
  user: { id: string; disabled: boolean };
  onEdit: () => Promise<void>;
  onDelete: () => Promise<void>;
  onDisable: () => Promise<void>;
  onInvite: () => Promise<void>;
  invitees?: boolean;
}

const ActionMenu = ({
  currentUser,
  user,
  onEdit,
  onDelete,
  onDisable,
  onInvite,
  invitees = false
}: Props) => {
  const isCurrentUser = currentUser.id === user.id;
  
  // Loading states for each action
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDisableLoading, setIsDisableLoading] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);

  // Wrapper functions to handle loading states
  const handleEdit = async () => {
    try {
      setIsEditLoading(true);
      await onEdit();
    } catch (error) {
      console.error('Error editing user:', error);
    } finally {
      setIsEditLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleteLoading(true);
      await onDelete();
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handleDisable = async () => {
    try {
      setIsDisableLoading(true);
      await onDisable();
    } catch (error) {
      console.error('Error toggling user access:', error);
    } finally {
      setIsDisableLoading(false);
    }
  };

  const handleInvite = async () => {
    try {
      setIsInviteLoading(true);
      await onInvite();
    } catch (error) {
      console.error('Error resending invite:', error);
    } finally {
      setIsInviteLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {/* Resend Invite Action - Only visible for invitees */}
      {invitees && (
        <Tooltip title="Resend Invite">
          <span>
            <IconButton
              disabled={isCurrentUser || isInviteLoading}
              onClick={handleInvite}
              size="small"
            >
              {isInviteLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Send fontSize="small" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      )}

      {/* Edit User Action */}
      <Tooltip title="Edit User">
        <span>
          <IconButton
            disabled={isCurrentUser || isEditLoading}
            onClick={handleEdit}
            size="small"
          >
            {isEditLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Edit fontSize="small" />
            )}
          </IconButton>
        </span>
      </Tooltip>

      {/* Enable/Disable User Action */}
      <Tooltip title={`${user.disabled ? 'Enable' : 'Disable'} Access for User`}>
        <span>
          <IconButton
            disabled={isCurrentUser || isDisableLoading}
            onClick={handleDisable}
            size="small"
          >
            {isDisableLoading ? (
              <CircularProgress size={20} />
            ) : user.disabled ? (
              <PersonAddAlt fontSize="small" />
            ) : (
              <NoAccounts fontSize="small" />
            )}
          </IconButton>
        </span>
      </Tooltip>

      {/* Delete User Action */}
      <Tooltip title="Remove User">
        <span>
          <IconButton
            disabled={isCurrentUser || isDeleteLoading}
            onClick={handleDelete}
            size="small"
            sx={{ color: isCurrentUser ? 'inherit' : 'error.main' }}
          >
            {isDeleteLoading ? (
              <CircularProgress size={20} color="error" />
            ) : (
              <Delete fontSize="small" />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ActionMenu;