import { isValid } from 'date-fns';
import { 
  useQuery, 
  UseQueryResult, 
  QueryKey 
} from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

// Define the form values interface with more precise typing
export interface ClipsFormValues {
  date?: Date | null;
  team?: string | null;
  clipType: 'all' | 'clip' | 'bookmark';
  tags?: Array<{ name: string }> | null;
  status?: string | null;
}

// Define the server response type with comprehensive type safety
export interface ClipModelFromServer {
  id: string;
  note: string;
  mediatype: 'clip' | 'bookmark';
  game: {
    awayTeam: {
      id: number;
      name: string;
      abbreviation: string;
      teamColors?:any;
      players?: any;
    };
    homeTeam: {
      id: number;
      name: string;
      abbreviation: string;
      teamColors?:any;
      players?: any;
    };
    startTimestamp: string;
  };
  createdAt: string;
  updatedAt: string;
  status: 'READY' | 'PENDING' | 'ERROR' | 'CANCELED' | 'UNPROCESSED';
}

export type GetClipsResult = {
  rows: ClipModelFromServer[];
  rowCount: number;
};

// Comprehensive query params type
type QueryParams = {
  page: number;
  sort: 'desc' | 'asc';
  sortField?: string;
  pageSize: number;
  textSearch?: string;
} & Partial<ClipsFormValues>;

// Improved query key generation with more predictable caching
export const getQueryKey = (
  params: QueryParams
): QueryKey => ['clips', params];

// Robust fetch clips function with improved error handling
export const fetchClips = async (params: QueryParams): Promise<GetClipsResult> => {
  try {
    // Create a copy to avoid mutating the original params
    const searchParams = new URLSearchParams();
    
    // Pagination
    searchParams.set('page', params.page.toString());
    searchParams.set('pageSize', params.pageSize.toString());
    
    // Sorting
    searchParams.set('sortDirection', params.sort);
    searchParams.set('sortField', params.sortField || 'createdAt');
    
    // Optional filters with null/undefined checks
    if (params.tags?.length) {
      const tagNames = params.tags.map(t => t.name).filter(Boolean);
      if (tagNames.length) {
        searchParams.set('tags', tagNames.join(','));
      }
    }
    
    if (params.team) {
      searchParams.set('team', params.team);
    }
    
    if (params.textSearch) {
      searchParams.set('text', params.textSearch);
    }
    
    if (params.date && isValid(params.date)) {
      searchParams.set('date', params.date.toISOString());
    }
    
    if (params.status) {
      searchParams.set('status', params.status);
    }
    
    // Always set clip type with a default
    searchParams.set('clipType', params.clipType || 'all');
    
    // Fetch with error handling
    const response = await get<{ data: GetClipsResult }>(
      `/v1.0/clips?${searchParams.toString()}`
    );
    
    return response.data;

  } catch (error) {
    console.error('Failed to fetch clips:', error);
    throw error;
  }
};

// Enhanced query hook with better typing and configuration
const useAllClipsQuery = (
  formValues: ClipsFormValues,
  textSearch = '',
  pageSize = 10,
  page = 0,
  sort = 'desc',
  sortField = 'createdAt'
): UseQueryResult<GetClipsResult, Error> => {
  // Combine all parameters for query key and fetching
  const queryParams: QueryParams = {
    page,
    pageSize,
    textSearch,
    sort: sort as 'desc' | 'asc',
    sortField,
    ...formValues
  };

  return useQuery({
    // Generate a stable, comprehensive query key
    queryKey: getQueryKey(queryParams),
    
    // Async query function with full parameters
    queryFn: () => fetchClips(queryParams),
    
    // Advanced caching and refetching strategies
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 30,   // 30 minutes
    
    // Keep previous data for smooth UX during loading
    keepPreviousData: true,
    
    // Retry mechanism
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    
    // Error handling
    throwOnError: false,
    
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
};

export default useAllClipsQuery;