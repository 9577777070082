import React, { useState } from 'react';
import {
  Button,
  Typography,
  Stack,
  CircularProgress,
  Paper,
  Backdrop,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type ButtonColorType = 'primary' | 'error' | 'info' | 'secondary' | 'success' | 'inherit';

type Props = {
  cancelButtonColor?: ButtonColorType;
  cancelButtonText?: string;
  cancellable?: boolean;
  closable?: boolean;
  closeButtonColor?: ButtonColorType;
  closeButtonText?: string;
  message: string;
  onClose?: () => void;
  onRetry?: () => void;
  onYes: () => void;
  open: boolean;
  requiresConfirmation: boolean;
  retryable?: boolean;
  retryButtonColor?: ButtonColorType;
  retryButtonText?: string;
  secondaryMessage?: string | React.ReactNode;
  title: string;
  useDialog?: boolean;
};

type ConfirmationButtonsProps = {
  onYes: () => void;
  onNo: () => void;
  noText?: string;
  yesText?: string;
  questionText?: string;
};

// Helper components
const ConfirmationButtons = ({
  onYes,
  onNo,
  yesText = 'Yes',
  noText = 'No',
  questionText = 'Are you sure?',
}: ConfirmationButtonsProps) => (
  <Stack
    direction="row-reverse"
    width="100%"
    alignItems="center"
    justifyContent="center"
    sx={{ mt: 2 }}
  >
    <Button size="small" color="success" onClick={onYes}>
      {yesText}
    </Button>
    <Button size="small" color="error" onClick={onNo}>
      {noText}
    </Button>
    <Typography variant="caption">{questionText}</Typography>
  </Stack>
);

// Helper functions
const renderSecondaryMessage = (message: string | React.ReactNode): React.ReactNode => {
  if (typeof message === 'string') {
    return (
      <Typography
        mt={2}
        variant="body2"
        sx={{
          overflowWrap: 'break-word',
          width: '100%',
        }}
      >
        {message}
      </Typography>
    );
  }
  return message;
};

const ServerStatus = ({
  open,
  title,
  message,
  cancelButtonText = 'Cancel',
  closeButtonText = 'Close',
  cancellable = false,
  retryable = false,
  retryButtonText = 'Retry',
  closable = false,
  closeButtonColor = 'error',
  retryButtonColor = 'inherit',
  cancelButtonColor = 'error',
  onRetry,
  secondaryMessage,
  onClose,
  onYes,
  requiresConfirmation = true,
  useDialog = false,
}: Props) => {
  const [confirm, setConfirm] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const loading = !!(retryable || cancellable);

  const handleActionButtonClick = () => {
    if (requiresConfirmation) {
      setConfirm(true);
    } else {
      onYes();
    }
  };

  const handleConfirmAction = () => {
    onYes();
    setLoadingButton(true);
    setConfirm(false);
  };

  const handleCancelConfirmation = () => {
    setConfirm(false);
  };

  // Action buttons that appear in both Paper and Dialog variants
  const renderActionButtons = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      {cancellable && !confirm && (
        <LoadingButton
          sx={{ mt: 1 }}
          color={cancelButtonColor}
          loading={loadingButton}
          onClick={handleActionButtonClick}
          autoFocus
          variant="outlined"
        >
          {cancelButtonText}
        </LoadingButton>
      )}

      {retryable && (
        <LoadingButton
          sx={{ mt: 1 }}
          color={retryButtonColor}
          loading={loadingButton}
          onClick={onRetry}
          variant="contained"
        >
          {retryButtonText}
        </LoadingButton>
      )}

      {confirm && (
        <ConfirmationButtons onNo={handleCancelConfirmation} onYes={handleConfirmAction} />
      )}

      {closable && (
        <Button color={closeButtonColor} onClick={onClose}>
          {closeButtonText}
        </Button>
      )}
    </Box>
  );

  // Content that appears in both Paper and Dialog variants
  const renderContent = () => (
    <>
      <Typography variant="body2">{message}</Typography>
      {!!secondaryMessage && renderSecondaryMessage(secondaryMessage)}
    </>
  );

  // Paper variant styles
  const paperStyle = {
    backgroundColor: 'transparent',
    borderRadius: 2,
    px: 5,
    pt: 2,
    pb: 1,
    minWidth: 700,
    minHeight: 700,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };

  // Common backdrop styles
  const backdropStyle = {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  };

  return (
    <Backdrop sx={backdropStyle} open={open}>
      {!useDialog ? (
        <Paper sx={paperStyle}>
          <Typography variant="h5" sx={{ mb: 10 }}>
            {title}
          </Typography>

          {loading && !retryable && <CircularProgress color="inherit" />}

          <Typography variant="body2" sx={{ mt: 10 }}>
            {message}
          </Typography>

          {!!secondaryMessage && renderSecondaryMessage(secondaryMessage)}

          {renderActionButtons()}
        </Paper>
      ) : (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          slotProps={{
            backdrop: {
              sx: {
                backdropFilter: 'blur(4px)',
              },
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {loading && !loadingButton && (
              <CircularProgress size={15} sx={{ marginRight: 3 }} color="inherit" />
            )}
            {title}
          </DialogTitle>

          <DialogContent>{renderContent()}</DialogContent>

          <DialogActions>{renderActionButtons()}</DialogActions>
        </Dialog>
      )}
    </Backdrop>
  );
};

export default ServerStatus;
