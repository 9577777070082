import  { useState, useEffect, useRef, useMemo } from 'react';
import { CheckCircle, AlertCircle, ChevronUp, ChevronDown, LoaderCircle } from 'lucide-react';
import { useAuth, LocalSessionState, getSessionStateString } from './AuthContext'; // Adjust the import path as needed

// LoadingIcon component from your existing code
const LoadingIcon = () => {
  return (
    <div className="inline-block">
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <span style={{ display: 'inline-block' }}>
        <LoaderCircle color="white" style={{ animation: 'spin 2s linear infinite' }} />
      </span>
    </div>
  );
};

const NotificationIcon = ({ status }) => {
  switch (status) {
    case 'info':
      return <LoadingIcon />;
    case 'success':
      return <CheckCircle color="white" />;
    case 'error':
      return <AlertCircle color="white" />;
    default:
      return null;
  }
};

const CustomNotificationCenter = () => {
  const { progressMessages, sessionState } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const lastMessageRef = useRef(null);
  const hideTimeoutRef = useRef(null);

  // Use useMemo to prevent unnecessary recalculations
  const { latestMessage, allMessages } = useMemo(() => {
    const latest = progressMessages.length > 0 
      ? progressMessages[progressMessages.length - 1] 
      : null;
    
    // Get all messages in reverse chronological order (newest first)
    const all = [...progressMessages].reverse();
      
    return { latestMessage: latest, allMessages: all };
  }, [progressMessages]);
  
  // Handle auto-hiding when status is READY
  useEffect(() => {
    // Clear any existing timeout to prevent multiple timers
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    
    // When session state changes to READY, set a timeout to hide
    if (sessionState === LocalSessionState.READY) {
      setIsVisible(true); // Make sure it's visible during the delay
      hideTimeoutRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Hide after 3 seconds
    } else {
      setIsVisible(true); // Always visible for other states
    }
    
    // Cleanup timeout on unmount
    return () => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [sessionState]);

  // Handle expanding/collapsing the previous messages
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Style for the main notification container
  const getContainerStyle = (status) => {
    const baseStyle = {
      position: 'fixed',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 5000,
      borderRadius: '4px',
      boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
      padding: '12px 16px',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '400px',
      width: '100%',
      transition: 'all 0.3s cubic-bezier(0.0, 0.0, 0.2, 1)'
    };

    // Status-specific styles
    switch (status) {
      case 'info':
        return { ...baseStyle, backgroundColor: '#0932ec', color: 'white', border: '1px solid #0932ec' };
      case 'success':
        return { ...baseStyle, backgroundColor: '#12b76a', color: 'white', border: '1px solid #12b76a' };
      case 'error':
        return { ...baseStyle, backgroundColor: '#f44336', color: 'white', border: '1px solid #f04438' };
      default:
        return baseStyle;
    }
  };

  // Animation for message entry - using latestMessage.id to prevent unnecessary effects
  useEffect(() => {
    // If we have a new latest message, animate it
    if (lastMessageRef.current) {
      lastMessageRef.current.style.opacity = 0;
      const timer = setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.style.opacity = 1;
        }
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [latestMessage?.id]); // Only depend on the message ID

  // Manage visible messages for the history section
  useEffect(() => {
    if (isExpanded) {
      // Show messages one by one with a small delay
      const timer = setTimeout(() => {
        setVisibleMessages([...allMessages]); // All messages are already in reverse order
      }, 400);
      
      return () => clearTimeout(timer);
    } else {
      setVisibleMessages([]);
    }
  }, [isExpanded, allMessages.length]); // Only depend on the length

  if (!latestMessage || !isVisible) {
    return null;
  }

  return (
    <div 
      style={{
        ...getContainerStyle(latestMessage.status),
        animation: sessionState === LocalSessionState.READY ? 'fadeContainer 3s forwards' : 'none'
      }}
    >
      <style>
        {`
          @keyframes fadeContainer {
            0%, 80% { opacity: 1; transform: translateX(-50%); }
            100% { opacity: 0; transform: translateX(-50%) translateY(-20px); }
          }
        `}
      </style>
      {/* Latest message */}
      <div 
        ref={lastMessageRef}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          transition: 'opacity 0.3s cubic-bezier(0.0, 0.0, 0.2, 1)',
        }}
      >
        <div style={{ flexShrink: 0 }}>
          <NotificationIcon status={latestMessage.status} />
        </div>
        <div style={{ flexGrow: 1 }}>
          {latestMessage.message.toLowerCase()}
        </div>
        {allMessages.length > 1 && (
          <button 
            onClick={toggleExpand}
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4px',
              borderRadius: '50%',
              color: 'inherit',
            }}
          >
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </button>
        )}
      </div>

      {/* Message history in reverse chronological order */}
      {isExpanded && (
        <div
          style={{
            marginTop: '10px',
            overflow: 'hidden',
            transition: 'max-height 0.5s cubic-bezier(0.0, 0.0, 0.2, 1)',
            maxHeight: isExpanded ? '200px' : '0',
            opacity: isExpanded ? 1 : 0,
          }}
        >
          <div
            style={{
              maxHeight: '150px',
              overflowY: 'auto',
              borderTop: '1px solid rgba(255,255,255,0.2)',
              paddingTop: '10px',
            }}
          >
            {/* Skip the first item since it's already shown as the latest message */}
            {visibleMessages.slice(1).map((message, index) => (
              <div
                key={message.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '8px',
                  opacity: 0,
                  animation: `fadeIn 0.3s cubic-bezier(0.0, 0.0, 0.2, 1) forwards ${index * 0.1}s`,
                }}
              >
                <style>
                  {`
                    @keyframes fadeIn {
                      from { opacity: 0; transform: translateY(10px); }
                      to { opacity: 1; transform: translateY(0); }
                    }
                  `}
                </style>
                <div style={{ flexShrink: 0 }}>
                  <NotificationIcon status={message.status} />
                </div>
                <div style={{ fontSize: '0.9em' }}>
                  {message.message}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {/* Show current session state for debugging */}
      {sessionState !== undefined && (
        <div style={{
          position: 'absolute',
          bottom: '-18px',
          right: '5px',
          fontSize: '10px',
          opacity: 0.7,
          color: '#555',
        }}>
          {getSessionStateString(sessionState)}
        </div>
      )}
    </div>
  );
};

export default CustomNotificationCenter;