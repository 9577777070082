import React, { ReactNode, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useTimemachineState from '../hooks/useTimemachineState';
import { ClipModel, ClipStatus } from '@/features/clips/types';
import DownloadClipDialog from '@/shared/components/DownloadClipDialog';

type Props = {
  children: ReactNode;
};

export const TimemachineStateContext = React.createContext(undefined);

// Create a new context for the TimeMachine actions
export type TimemachineActions = {
  downloadClip: (clip: ClipModel) => void;
};

export const TimemachineActionsContext = React.createContext<TimemachineActions | undefined>(
  undefined,
);

/**
 * Helper function to format status message based on current state
 */
export const formatStatus = (state) => {
  if (!state.status) return '';

  if (state.clip) {
    const clipIdentifier = state.clip.note || `Clip ${state.clip.id}`;
    return `Exporting ${clipIdentifier}: ${state.status}`;
  }

  return `Exporting: ${state.status}`;
};

/**
 * Provider component that manages TimeMachine state and related UI components
 */
const TimeMachineStatusProvider = ({ children }: Props) => {
  const { timemachineState } = useTimemachineState();
  const { enqueueSnackbar } = useSnackbar();

  const [downloadClipDialogOpen, setDownloadClipDialogOpen] = React.useState({
    open: false,
    clip: undefined,
  });

  const handleCloseDownloadDialog = () => {
    setDownloadClipDialogOpen({ open: false, clip: undefined });
  };

  // Function to trigger clip download from any component
  const downloadClip = useCallback((clip: ClipModel) => {
    setDownloadClipDialogOpen({
      open: true,
      clip,
    });
  }, []);

  // Actions object that will be provided through context
  const timemachineActions: TimemachineActions = {
    downloadClip,
  };

  // Handle snackbar notifications based on clip status updates
  useEffect(() => {
    if (timemachineState.clipStatusUpdate) {
      const { clip , status } = timemachineState.clipStatusUpdate;
      const clipName = clip.note;

      switch (status) {
        case ClipStatus.PROCESSING:
          enqueueSnackbar(`Exporting ${clipName} in progress ...`, { variant: 'info' });
          break;
        case ClipStatus.CANCELED:
          enqueueSnackbar(`Exporting ${clipName} is canceled.`, { variant: 'warning' });
          break;
        case ClipStatus.READY:
          //@ts-expect-error
          enqueueSnackbar(`🎉 Your clip ${clipName} is ready!`, { variant: 'clipReady', persist: true , downloadHandler: ()=>downloadClip(clip)});
          break;
        case ClipStatus.ERROR:
          enqueueSnackbar(`😢 ${clipName} failed to export... the team is notified.`, {
            variant: 'error',
          });
          break;
      }
    }
  }, [timemachineState.clipStatusUpdate, enqueueSnackbar]);

  return (
    //@ts-expect-error
    <TimemachineStateContext.Provider value={timemachineState}>
      <TimemachineActionsContext.Provider value={timemachineActions}>
        <DownloadClipDialog
          open={downloadClipDialogOpen.open}
          //@ts-expect-error
          clip={downloadClipDialogOpen.clip as ClipModel}
          onClose={handleCloseDownloadDialog}
        />
        {children}
      </TimemachineActionsContext.Provider>
    </TimemachineStateContext.Provider>
  );
};

export default TimeMachineStatusProvider;
