import React, { useEffect, useRef, useState } from 'react';
import {
  List,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItemButton,
  Box,
  ListSubheader,
  Stack,
} from '@mui/material';
import { TeamModel } from '../types/TeamModel';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import TeamHeader from './TeamHeader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useCurrentGame } from '@/features/games/context/CurrentGameContext';

export type TeamAccordionProps = {
  teams: TeamModel[];
};

export const TeamAccordion = ({ teams }: TeamAccordionProps) => {

  const { gameLoaded } = useCurrentGame();

  const { trackEntity, singleCurrentlyTrackedObjectId: selectedId } = useServerStateContext();
  const elementRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>(100);

  const calculatePosition = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      setContainerHeight(window.innerHeight - rect.top); // Distance from the element's top to the bottom of the viewport
    }
  };

  useEffect(()=>{
    if(elementRef.current){
      calculatePosition();
    }
  },[elementRef.current?.getBoundingClientRect().top])

  useEffect(() => {
    // Initial calculation
    calculatePosition();

    // Add resize listener
    window.addEventListener('resize', calculatePosition);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, []);

  if (!gameLoaded) {
    return <></>;
  }
  

  return (
    <Box sx={{ height: containerHeight, overflowY: 'auto' }} ref={elementRef}>
      <PerfectScrollbar>
        {teams.map((team) => (
          <Stack key={team.id} direction={'row'} sx={{ minHeight: containerHeight / 2 }}>
            <TeamHeader
              team={team}
            />

            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  paddingLeft: 1,
                }}
              >
                {!!team.players &&
                  Object.keys(team.players).map((category) => (
                    <List
                      key={`${team.id}-${category}`}
                      dense
                      subheader={<ListSubheader sx={{ width: '95%' }}>{category}</ListSubheader>}
                      sx={{
                        padding: 0, // Ensure no padding for the List itself
                      }}
                    >
                      {team.players[category].map((player) => (
                        <React.Fragment key={player.id}>
                          <ListItemButton
                            sx={{ padding: 1 }}
                            selected={Number(selectedId) === player.id}
                            onClick={() => trackEntity(player, 'Team Accordion')}
                          >
                            <ListItemAvatar sx={{ padding: 0 }}>
                              <Avatar alt={player.displayName} src={player.headshot} />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{
                                padding: 0,
                                margin: 0, // Ensure no margin is applied to ListItemText
                                opacity: player.isActive ? 1 : 0.5,
                              }}
                              primary={`#${player.playerNumber} - ${player.displayName}`}
                              secondary={player.playerPosition}
                            />
                          </ListItemButton>
                          <Divider variant="inset" component="li" sx={{ margin: 0 }} />
                        </React.Fragment>
                      ))}
                    </List>
                  ))}
              </Box>
            </Box>
          </Stack>
        ))}
      </PerfectScrollbar>
    </Box>
  );
};

export default TeamAccordion;
