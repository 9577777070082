import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Org } from '@/types/user';
import { put } from '@truefit/http-utils';


export type EditDepartmentModel = {
    orgId: number;
    name: string;
    departmentId: number;
}

const useEditDepartmentMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: EditDepartmentModel) => {
            return await put<Org>(`/v1.0/org/${model.orgId}/departments/${model.departmentId}`, model);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['org'] });
        },
    });
};
export { useEditDepartmentMutation }
export default useEditDepartmentMutation;
