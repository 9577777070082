import { useState, useEffect } from 'react';
import { isUUID } from 'validator';

/**
 * Hook that extracts the current game ID from the URL path and
 * keeps it updated when the URL changes
 * 
 * @returns {string} The current game ID from the URL or empty string if not found
 */
const useCurrentGameId = () => {
  // Function to extract gameId from the current URL
  const extractGameId = () => {
    // Assuming URL pattern is /games/:gameId/...
    const pathParts = window.location.pathname.split('/');
    const gameIdIndex = pathParts.findIndex(part => part === 'games') + 1;
    
    if (gameIdIndex > 0 && gameIdIndex < pathParts.length) {
      const candidateId = pathParts[gameIdIndex];
      // Optionally validate the ID format
      return isUUID(candidateId) ? candidateId : '';
    }
    
    return '';
  };

  // Initialize state with current value from URL
  const [gameId, setGameId] = useState(extractGameId());

  useEffect(() => {
    // Update gameId when URL changes
    const handleUrlChange = () => {
      const newGameId = extractGameId();
      if (newGameId !== gameId) {
        setGameId(newGameId);
      }
    };

    // Listen for browser navigation events
    window.addEventListener('popstate', handleUrlChange);
    
    // For SPA navigation (since React Router doesn't always trigger popstate)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;
    
    window.history.pushState = function() {
      originalPushState.apply(this, arguments);
      handleUrlChange();
    };
    
    window.history.replaceState = function() {
      originalReplaceState.apply(this, arguments);
      handleUrlChange();
    };
    
    // Clean up on unmount
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [gameId]);

  return gameId;
};

export default useCurrentGameId;