import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Chip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirmationDialogContext from '@/shared/hooks/useConfirmationDialogContext';
import useRemoveDepartmentFromOrgMutation from '../hooks/useRemoveDepartmentFromOrgMutation';
import { useCallback } from 'react';

interface Props {
  onEdit: (department: any) => void;
  departments: any[];
}

const DepartmentsTable = ({ onEdit, departments }: Props) => {
  const setConfirmationDialog = useConfirmationDialogContext();

  const deleteDepartmentMutation = useRemoveDepartmentFromOrgMutation();

  const handleDeleteDepartment = useCallback(
    async (department: any) => {
      await deleteDepartmentMutation.mutateAsync({
        orgId: department.orgId,
        departmentId: department.id,
      });
    },
    [deleteDepartmentMutation],
  );

  const onDelete = useCallback(
    (department: any) => {
      setConfirmationDialog({
        confirmButtonStyle: 'error',
        title: `Delete Department`,
        message: (
          <Typography>
            This action cannot be undone.
            <br></br> Department 
            <Typography sx={{ fontWeight: '700',ml: 1, mr:1 }} component="span" >
              "{department.name}"
            </Typography>{' '}
            will be deleted.
          </Typography>
        ),
        confirmText: `Confirm`,
        cancelText: 'Cancel',
        onConfirm: () => handleDeleteDepartment(department),
      });
    },
    [setConfirmationDialog],
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Members</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {departments && departments.length > 0 ? (
            departments.map((department) => (
              <TableRow key={department.id}>
                <TableCell>{department.name}</TableCell>
                <TableCell>
                  {department.users && department.users.length > 0
                    ? department.users.map((user) => (
                        <Chip
                          key={user.id}
                          label={`${user.email}`}
                          size="small"
                          style={{ marginRight: 4, marginTop:4 }}
                        />
                      ))
                    : 'No members'}
                </TableCell>
                <TableCell align="right" sx={{minWidth: 100}}>
                  <IconButton size="small" onClick={() => onEdit(department)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton color="error" disabled={department.users && department.users.length>0} size="small" onClick={() => onDelete(department)}>
                    <DeleteIcon  fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No departments found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DepartmentsTable;
