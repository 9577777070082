import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import { Box, Container, Divider, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import useAllClipsQuery, { ClipsFormValues, getQueryKey } from '../hooks/useAllClipsQuery';
import ClipsForm, { DEFAULT_VALUES } from './ClipsForm';
import ClipsTable from './ClipsTable';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { AppPage } from '@/shared/components';

const ClipsPage = () => {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState<ClipsFormValues>(DEFAULT_VALUES);
  const [textSearch, setTextSearch] = useState('');

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);

  const clipsQuery = useAllClipsQuery(
    formValues,
    textSearch,
    paginationModel.pageSize,
    paginationModel.page,
  );

  const total = clipsQuery.data?.rowCount || 0;
  const { isLoading } = clipsQuery;
  const title = 'Clips & Bookmarks Management';

  const handleFilterAndSortChange = (values: ClipsFormValues) => {
    setFormValues(values);
  };

  useDocumentTitle(title);

  useEffect(() => {
    clipsQuery.refetch();
  }, [textSearch, paginationModel.page, queryClient, formValues, clipsQuery]);

  const handleTextInputChange = debounce((event) => {
    setTextSearch(event.target.value);
  }, 500);


  return (
    <AppPage title={title} requiredPermissions={['menu:clips']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">
          View clips and ookmarks that have been created in your departments
        </Typography>
        <Divider sx={{ my: 3 }} />

        <ClipsForm onChange={handleFilterAndSortChange} />

        <Stack spacing={2}>
          {clipsQuery.isError ? (
            <Typography color="error" sx={{ textAlign: 'center' }}>
              An error occurred while fetching clips.
            </Typography>
          ) : null}

          {total !== undefined ? (
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {total} Records
            </Typography>
          ) : null}

          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label="Search"
              variant="standard"
              onChange={handleTextInputChange}
              style={{ width: '300px' }}
            />
          </Box>

          <ClipsTable
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            setSortModel={setSortModel}
            sortModel={sortModel}
            isLoading={isLoading}
            error={clipsQuery.error}
            data={clipsQuery.data}
          />
        </Stack>
      </Container>
    </AppPage>
  );
};

export default ClipsPage;
