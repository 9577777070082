
// Define error handler types
export type ErrorHandler = (error: any) => Promise<never> | null;

// Database error handler (similar to your handlePrismaErrors)
export const handleApiDatabaseErrors: ErrorHandler = (error) => {
    // Only handle errors with specific properties
    if (error.response?.data?.code?.startsWith('P')) {
        const errorData = error.response.data;
        const customError = new Error(errorData.error || 'Database error');

        // Add all the properties from the error response
        Object.assign(customError, errorData);

        // Add some additional metadata
        customError.name = 'DatabaseError';
        customError.originalStatus = error.response.status;

        return Promise.reject(customError);
    }

    // Return null to indicate this handler didn't process the error
    return null;
};

// Authentication error handler
export const handleAuthErrors: ErrorHandler = (error) => {
    if (error.response?.status === 401) {
        // Redirect to login page
        window.location.replace('/sign-in');
        return Promise.reject(new Error('Authentication required'));
    }

    if (error.response?.status === 403) {
        const customError = new Error('Permission denied');
        customError.name = 'PermissionError';
        return Promise.reject(customError);
    }

    return null;
};

// Default error handler (catches all remaining errors)
export const defaultErrorHandler: ErrorHandler = (error) => {
    if (error.response) {
        // API returned an error response
        const customError = new Error(
            error.response.data?.error ||
            error.response.data?.message ||
            `Request failed with status ${error.response.status}`
        );

        customError.name = 'ApiError';
        customError.status = error.response.status;

        // Attach any additional data from the response
        if (error.response.data) {
            Object.assign(customError, error.response.data);
        }

        return Promise.reject(customError);
    }

    if (error.request) {
        // Request was made but no response received
        const networkError = new Error('Network error - no response received');
        networkError.name = 'NetworkError';
        return Promise.reject(networkError);
    }

    // Error setting up the request
    const genericError = new Error(error.message || 'Unknown error');
    genericError.name = 'GenericError';
    return Promise.reject(genericError);
};