import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Tab Panel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`camera-tabpanel-${index}`}
      aria-labelledby={`camera-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CameraSystemConfig = ({ initialConfig, setOutputJson }) => {
  // State for active tab
  const [activeTab, setActiveTab] = useState(0);

  // State for configuration
  const [config, setConfig] = useState(() => {
    // Create a deep copy of the initial config
    const initialConfigCopy = JSON.parse(JSON.stringify(initialConfig));
    if(!initialConfig) return {}
    // Ensure all cameras have the enabled property set to true if missing
    initialConfigCopy.cams = initialConfigCopy.cams.map((cam) => {
      if (cam.enabled === undefined) {
        cam.enabled = true;
      }
      // Initialize curveAngle and curveRadius arrays if they don't exist
      if (!cam.curveAngle) {
        cam.curveAngle = [0];
      }
      if (!cam.curveRadius) {
        cam.curveRadius = [0];
      }
      return cam;
    });

    return initialConfigCopy;
  });

  useEffect(() => {
    setOutputJson(config);
  }, [config]);

  // Handle units change
  const handleUnitsChange = (event) => {
    setConfig({
      ...config,
      units: event.target.value,
    });
  };

  // Handle camera field changes
  const handleCameraChange = (index, field, value) => {
    const updatedCams = [...config.cams];
    updatedCams[index] = {
      ...updatedCams[index],
      [field]: value,
    };

    setConfig({
      ...config,
      cams: updatedCams,
    });
  };

  // Handle camera nested field changes (position, forward)
  const handleCameraNestedChange = (camIndex, parent, field, value) => {
    const updatedCams = [...config.cams];
    updatedCams[camIndex] = {
      ...updatedCams[camIndex],
      [parent]: {
        ...updatedCams[camIndex][parent],
        [field]: parseFloat(value),
      },
    };

    setConfig({
      ...config,
      cams: updatedCams,
    });
  };

// Handle array item changes (curveAngle, curveRadius)
const handleArrayItemChange = (camIndex, arrayName, itemIndex, value) => {
  const updatedCams = [...config.cams];
  const updatedArray = [...updatedCams[camIndex][arrayName]];
  
  // Check if the value is a valid number or in the process of being typed
  if (value === '' || value === '-' || value === '.' || value === '-.') {
    // Allow empty field, negative sign alone, decimal point alone, or negative decimal
    updatedArray[itemIndex] = value;
  } else if (value.endsWith('.')) {
    // Preserve the decimal point if the user just typed it
    updatedArray[itemIndex] = value;
  } else {
    // Try to parse as float if it's not a special typing case
    const floatValue = parseFloat(value);
    updatedArray[itemIndex] = isNaN(floatValue) ? 0.0 : floatValue;
  }
  
  updatedCams[camIndex] = {
    ...updatedCams[camIndex],
    [arrayName]: updatedArray,
  };

  setConfig({
    ...config,
    cams: updatedCams,
  });
};

  // Add new item to array (curveAngle, curveRadius)
  const handleAddArrayItem = (camIndex, arrayName) => {
    const updatedCams = [...config.cams];
    const updatedArray = [...updatedCams[camIndex][arrayName], 0];
    
    updatedCams[camIndex] = {
      ...updatedCams[camIndex],
      [arrayName]: updatedArray,
    };

    // If adding to curveAngle, ensure curveRadius has the same length
    if (arrayName === 'curveAngle') {
      if (updatedCams[camIndex].curveRadius.length < updatedArray.length) {
        updatedCams[camIndex].curveRadius = [
          ...updatedCams[camIndex].curveRadius,
          ...Array(updatedArray.length - updatedCams[camIndex].curveRadius.length).fill(0)
        ];
      }
    } 
    // If adding to curveRadius, ensure curveAngle has the same length
    else if (arrayName === 'curveRadius') {
      if (updatedCams[camIndex].curveAngle.length < updatedArray.length) {
        updatedCams[camIndex].curveAngle = [
          ...updatedCams[camIndex].curveAngle,
          ...Array(updatedArray.length - updatedCams[camIndex].curveAngle.length).fill(0)
        ];
      }
    }

    setConfig({
      ...config,
      cams: updatedCams,
    });
  };

  // Remove item from array (curveAngle, curveRadius)
  const handleRemoveArrayItem = (camIndex, arrayName, itemIndex) => {
    const updatedCams = [...config.cams];
    const updatedArray = [...updatedCams[camIndex][arrayName]];
    updatedArray.splice(itemIndex, 1);
    
    // Ensure we have at least one item
    if (updatedArray.length === 0) {
      updatedArray.push(0);
    }
    
    updatedCams[camIndex] = {
      ...updatedCams[camIndex],
      [arrayName]: updatedArray,
    };

    // If removing from curveAngle, ensure curveRadius has the same length
    if (arrayName === 'curveAngle') {
      if (updatedCams[camIndex].curveRadius.length > updatedArray.length) {
        updatedCams[camIndex].curveRadius = updatedCams[camIndex].curveRadius.slice(0, updatedArray.length);
      }
    } 
    // If removing from curveRadius, ensure curveAngle has the same length
    else if (arrayName === 'curveRadius') {
      if (updatedCams[camIndex].curveAngle.length > updatedArray.length) {
        updatedCams[camIndex].curveAngle = updatedCams[camIndex].curveAngle.slice(0, updatedArray.length);
      }
    }

    setConfig({
      ...config,
      cams: updatedCams,
    });
  };

  // Handle tracking spec changes
  const handleTrackingChange = (field, value) => {
    setConfig({
      ...config,
      trackingSpec: {
        ...config.trackingSpec,
        [field]: parseFloat(value),
      },
    });
  };
  
  // Handle copying warp curves to all cameras
  const handleCopyWarpCurvesToAllCameras = (sourceIndex) => {
    const sourceCam = config.cams[sourceIndex];
    const updatedCams = config.cams.map((cam, index) => {
      if (index !== sourceIndex) {
        return {
          ...cam,
          curveAngle: [...sourceCam.curveAngle],
          curveRadius: [...sourceCam.curveRadius]
        };
      }
      return cam;
    });
    
    setConfig({
      ...config,
      cams: updatedCams
    });
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if(!initialConfig?.cams){
    return <>
    
    </>
  }
  
  return (
    <Box component="form" noValidate sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
      <Card>
        <CardHeader
          title="Camera System Configuration"
          subheader="Edit camera and tracking settings"
        />
        <Divider />

        <CardContent>
          {/* Global Settings */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Global Settings
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Units</InputLabel>
              <Select value={config.units} label="Units" onChange={handleUnitsChange}>
                <MenuItem value="feet">Feet</MenuItem>
                <MenuItem value="meters">Meters</MenuItem>
                <MenuItem value="inches">Inches</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Camera Tabs */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Cameras
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ flexGrow: 1 }}
              >
                {config.cams.map((cam, index) => (
                  <Tab key={index} label={cam.displayName || `Camera ${index}`} />
                ))}
              </Tabs>
            </Box>

            {config.cams.map((cam, index) => (
              <TabPanel key={index} value={activeTab} index={index}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    {/* Basic Camera Settings */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Camera Index"
                        type="number"
                        value={cam.camIndex}
                        onChange={(e) =>
                          handleCameraChange(index, 'camIndex', parseInt(e.target.value))
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Display Name"
                        value={cam.displayName}
                        onChange={(e) => handleCameraChange(index, 'displayName', e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cam.enabled || false}
                            onChange={(e) => handleCameraChange(index, 'enabled', e.target.checked)}
                          />
                        }
                        label="Enabled"
                      />
                    </Grid>

                    {/* Position Settings */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Position (x, y, z)
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Position X"
                        type="number"
                        step="0.01"
                        value={cam.position.x}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'position', 'x', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Position Y"
                        type="number"
                        step="0.01"
                        value={cam.position.y}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'position', 'y', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Position Z"
                        type="number"
                        step="0.01"
                        value={cam.position.z}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'position', 'z', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Forward Direction Settings */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Forward Direction (x, y, z)
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Forward X"
                        type="number"
                        step="0.1"
                        value={cam.forward.x}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'forward', 'x', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Forward Y"
                        type="number"
                        step="0.1"
                        value={cam.forward.y}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'forward', 'y', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Forward Z"
                        type="number"
                        step="0.1"
                        value={cam.forward.z}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'forward', 'z', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Tilt Setting */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Tilt (degrees)
                      </Typography>
                      <Slider
                        value={cam.tilt}
                        onChange={(e, value) => handleCameraChange(index, 'tilt', value)}
                        valueLabelDisplay="auto"
                        min={-90}
                        max={90}
                        marks={[
                          { value: -90, label: '-90°' },
                          { value: -45, label: '-45°' },
                          { value: 0, label: '0°' },
                          { value: 45, label: '45°' },
                          { value: 90, label: '90°' },
                        ]}
                      />
                    </Grid>

                    {/* Offset */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Offset"
                        type="number"
                        step="0.1"
                        value={cam.offset}
                        onChange={(e) =>
                          handleCameraChange(index, 'offset', parseFloat(e.target.value))
                        }
                      />
                    </Grid>

                    {/* Curve Angle and Radius Arrays */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Lens Warp Curves
                      </Typography>
                      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                      <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Curve Angles
                        </Typography>
                        {cam.curveAngle.map((angle, angleIndex) => (
                          <Box key={angleIndex} sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                            <TextField
                              label={`Angle ${angleIndex + 1}`}
                              value={angle}
                              onChange={(e) => 
                                handleArrayItemChange(index, 'curveAngle', angleIndex, e.target.value)
                              }
                              sx={{ mr: 1, flexGrow: 1 }}
                            />
                            <IconButton 
                              color="error" 
                              onClick={() => handleRemoveArrayItem(index, 'curveAngle', angleIndex)}
                              disabled={cam.curveAngle.length <= 1}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button 
                          startIcon={<AddIcon />} 
                          variant="outlined" 
                          onClick={() => handleAddArrayItem(index, 'curveAngle')}
                          sx={{ mt: 1 }}
                        >
                          Add Angle
                        </Button>
                      </Paper>

                      <Paper elevation={1} sx={{ p: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Curve Radius
                        </Typography>
                        {cam.curveRadius.map((radius, radiusIndex) => (
                          <Box key={radiusIndex} sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                            <TextField
                              label={`Radius ${radiusIndex + 1}`}
                              value={radius}
                              onChange={(e) => 
                                handleArrayItemChange(index, 'curveRadius', radiusIndex, e.target.value)
                              }
                              sx={{ mr: 1, flexGrow: 1 }}
                            />
                            <IconButton 
                              color="error" 
                              onClick={() => handleRemoveArrayItem(index, 'curveRadius', radiusIndex)}
                              disabled={cam.curveRadius.length <= 1}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button 
                          startIcon={<AddIcon />} 
                          variant="outlined" 
                          onClick={() => handleAddArrayItem(index, 'curveRadius')}
                          sx={{ mt: 1 }}
                        >
                          Add Radius
                        </Button>
                      </Paper>
                      </Stack>
                     
                      
                      {/* Copy Warp Curves Button */}
                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="Copy warp curve settings to all other cameras">
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<ContentCopyIcon />}
                            onClick={() => handleCopyWarpCurvesToAllCameras(index)}
                          >
                            Copy Warp Curves to All Cameras
                          </Button>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
            ))}
          </Box>

          {/* Tracking Specifications */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Tracking Specifications
            </Typography>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="SLERP Rate"
                    type="number"
                    step="0.01"
                    value={config.trackingSpec.slerpRate}
                    onChange={(e) => handleTrackingChange('slerpRate', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="LERP Rate"
                    type="number"
                    step="0.01"
                    value={config.trackingSpec.lerpRate}
                    onChange={(e) => handleTrackingChange('lerpRate', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="vDamp"
                    type="number"
                    step="0.1"
                    value={config.trackingSpec.vDamp}
                    onChange={(e) => handleTrackingChange('vDamp', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Maximum Distance"
                    type="number"
                    step="1"
                    value={config.trackingSpec.distMax}
                    onChange={(e) => handleTrackingChange('distMax', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Minimum Distance"
                    type="number"
                    step="1"
                    value={config.trackingSpec.distMin}
                    onChange={(e) => handleTrackingChange('distMin', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Maximum FOV"
                    type="number"
                    step="1"
                    value={config.trackingSpec.fovMax}
                    onChange={(e) => handleTrackingChange('fovMax', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Minimum FOV"
                    type="number"
                    step="1"
                    value={config.trackingSpec.fovMin}
                    onChange={(e) => handleTrackingChange('fovMin', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CameraSystemConfig;