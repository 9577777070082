import React from 'react';
import { Chip, Box } from '@mui/material';

// Color mapping for different environments
const envColorMap = {
  dev: 'info',
  development: 'info',
  staging: 'warning',
  production: 'error',
  qa: 'success',
  // Add more environments as needed
};

const EnvironmentTab = ({ environmentName = 'staging' }) => {
  // Convert to lowercase for consistency
  const envName = environmentName.toLowerCase();
  const env = envName.startsWith('pr') ? 'production' : envName.startsWith('st') ? 'staging' : envName.startsWith('qa') ? 'qa' : 'development';
  // Get the appropriate color for the environment, default to warning if not found
  const chipColor = envColorMap[env] || 'warning';

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 5000,
      }}
    >
      <Chip
        label={env}
        color={chipColor}
        sx={{
          borderRadius: '0 0 16px 16px',
          height: 28,
          fontWeight: 'medium',
          paddingX: 1,
          boxShadow: 2,
          textTransform: 'capitalize',
        }}
      />
    </Box>
  );
};

export default EnvironmentTab;
