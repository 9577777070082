import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, isEqual } from 'lodash';

import useRegistrationEntitiesQuery from '../../autoIso/hooks/useAutoIsoEntitiesQuery';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { setIsActive, sortPlayers } from '@/features/teams/functions';
import { AutoIsoModel, AutoIsoObjectType } from '@/features/autoIso/types/AutoIsoModel';
import { TeamModel } from '../types/TeamModel';

export interface CategoryPlayers {
  [category: string]: AutoIsoModel[];
}

export interface ProcessedTeam {
  players: CategoryPlayers;
}

export interface AutoIsoTeamSelectorState {
  processedTeams: ProcessedTeam[];
  includeInactivePlayers: boolean;
  setIncludeInactivePlayers: (value: React.SetStateAction<boolean>) => void;
  handleSearchChange: (value: string) => void;
}

export const useAutoIsoTeamSelectorState = (): AutoIsoTeamSelectorState => {
  // Server State
  const { gameStateManager } = useServerStateContext();
  const autoIsoQuery = useRegistrationEntitiesQuery();
  const { homeTeam, awayTeam, list = [] } = autoIsoQuery?.data || {};

  // Local State
  const [gameTrackingEntities, setGameTrackingEntities] = useState<AutoIsoModel[]>([]);
  const [includeInactivePlayers, setIncludeInactivePlayers] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeEntities, setActiveEntities] = useState<number[]>([]);

  // Event Handlers
  useEffect(() => {
    if (!gameStateManager) return;

    const handleTrackingEntitiesChange = (trackingEntities: AutoIsoModel[]) => {
      setGameTrackingEntities(trackingEntities);
    };

    gameStateManager.on('trackingEntitiesChange', handleTrackingEntitiesChange);
    return () => {
      gameStateManager.off?.('trackingEntitiesChange', handleTrackingEntitiesChange);
    };
  }, [gameStateManager]);

  useEffect(() => {
    if (gameTrackingEntities?.length) {
      const newIds = gameTrackingEntities.map((e) => e.id);
      if (!isEqual(newIds, activeEntities)) {
        setActiveEntities(newIds);
      }
    }
  }, [gameTrackingEntities, activeEntities]);

  // Memoized Filtering Logic
  const filteredRegistrationEntities = useMemo(() => {
    return list
      .filter((entity) => {
        if (!includeInactivePlayers && !activeEntities.includes(entity.id)) {
          return false;
        }

        if (!searchValue) return true;

        const searchLower = searchValue.toLowerCase();
            

        return (
          entity.displayName.toLowerCase().includes(searchLower) ||
          (entity.type === AutoIsoObjectType.Player && entity.playerNumber?.toString().includes(searchValue))
        );
      })
      .map(setIsActive(activeEntities));
  }, [list, activeEntities, includeInactivePlayers, searchValue]);

    // Add refs to store previous values for comparison
    const prevFilteredEntitiesRef = useRef<AutoIsoModel[]>([]);
    const prevHomeTeamRef = useRef<TeamModel | null>(undefined);
    const prevAwayTeamRef = useRef<TeamModel | null>(undefined);
    const prevProcessedTeamsRef = useRef<ProcessedTeam[]>([]);

    
  // Memoized Team Processing with optimization
  const processedTeams = useMemo((): ProcessedTeam[] => {
    // Check if inputs have changed
    const entitiesChanged = !isEqual(prevFilteredEntitiesRef.current, filteredRegistrationEntities);
    const homeTeamChanged = !isEqual(prevHomeTeamRef.current, homeTeam);
    const awayTeamChanged = !isEqual(prevAwayTeamRef.current, awayTeam);
    
    // If nothing has changed, return the previous result
    if (!entitiesChanged && !homeTeamChanged && !awayTeamChanged && prevProcessedTeamsRef.current.length > 0) {
      return prevProcessedTeamsRef.current;
    }
    
    // Update refs with current values for next comparison
    prevFilteredEntitiesRef.current = [...filteredRegistrationEntities];
    prevHomeTeamRef.current = homeTeam;
    prevAwayTeamRef.current = awayTeam;
    
    // Process teams only if something has changed
    const newProcessedTeams = [homeTeam, awayTeam]
      .map((team) => {
        if (!team) return null;

        const teamPlayers = filteredRegistrationEntities.filter(
          (entity) => entity.type === AutoIsoObjectType.Player && entity?.playerTeamId === team.id,
        );

        const categorizedPlayers = teamPlayers.reduce((acc, player) => {
          const category = player.playerCategory || 'Uncategorized';
          //@ts-expect-error
          player.team = {
            id: team.id,
            displayName : team.displayName,
            abbreviation: team.abbreviation,
            colors: team.teamColors,
          }

          return {
            ...acc,
            [category]: [...(acc[category] || []), player],
          };
        }, {} as CategoryPlayers);

        // Sort players within each category
        Object.keys(categorizedPlayers).forEach((category) => {
          categorizedPlayers[category].sort(sortPlayers);
        });

        return {
          ...team,
          players: categorizedPlayers,
        };
      })
      .filter(Boolean) as ProcessedTeam[];
    
    // Store new result for future comparisons
    prevProcessedTeamsRef.current = newProcessedTeams;
    
    return newProcessedTeams;
  }, [filteredRegistrationEntities, homeTeam, awayTeam]);

  // Debounced Search Handler
  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchValue(value), 300),
    [],
  );

  const handleSearchChange = (value: string) => {
    debouncedSearch(value);
  };

  return {
    processedTeams,
    includeInactivePlayers,
    setIncludeInactivePlayers,
    handleSearchChange,
  };
};

export default useAutoIsoTeamSelectorState;