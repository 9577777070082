import { AppPage } from '@/shared/components';
import useCurrentOrgState from '@/shared/hooks/useCurrentOrg';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { Typography, Divider, Button, Stack } from '@mui/material';
import { Container } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import DepartmentsTable from './DepartmentsTable';
import { Plus } from 'lucide-react';
import AddDepartmentDialog from '../dialogs/AddDepartmentDialog';
import { LoadingSpinner } from '@/shared/components/LoadingSpinner';

/**
 * Augments each department with a users property
 * @param {Array} departments - Array of department objects
 * @returns {Array} The departments array with each department having a users array
 */
function augmentDepartmentsWithUsers(departments) {
  // Check if departments array exists
  if (!departments || !Array.isArray(departments)) {
    return [];
  }

  // Map through each department and add the users property
  return departments.map((department) => {
    // Extract users from userdepartments
    const users = department.userdepartments
      ? department.userdepartments
          .filter((ud) => ud.user) // Make sure user exists
          .map((ud) => ud.user) // Extract just the user object
      : [];

    // Return the department with an added users property
    return {
      ...department,
      users,
    };
  });
}

const ManageDepartments = () => {
  const title = 'Departments Management';

  useDocumentTitle(title);

  const [openDepartmentDialog, setOpenDepartmentDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState({ name: '', id: null });

  const {
    departments,
    isLoading,
    refetch,
    actions: { addDepartmentToOrg, addUserToDepartment, removeUserFromDepartment, editDepartment },
  } = useCurrentOrgState();

  const [departmentData, setDepartmentsData] = useState(departments);

  useEffect(() => {
    setDepartmentsData(augmentDepartmentsWithUsers(departments));
  }, [departments]);

  const showSnackbar = (message: string, severity = 'success') => {
    //@ts-expect-error
    enqueueSnackbar(message, { variant: severity });
  };

  // Department dialog handlers
  const openAddDepartmentDialog = () => {
    setCurrentDepartment({ name: '', id: null });
    setOpenDepartmentDialog(true);
  };

  const openEditDepartmentDialog = (department) => {
    setCurrentDepartment({ ...department });
    setOpenDepartmentDialog(true);
  };

  const handleCloseDepartmentDialog = () => {
    setOpenDepartmentDialog(false);
  };

  const handleDepartmentChange = (e) => {
    const { name, value } = e.target;
    setCurrentDepartment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveDepartment = async () => {
    try {
      if (!currentDepartment.name.trim()) {
        showSnackbar('Department name is required', 'error');
        return;
      }
      if (currentDepartment.id) {
        await editDepartment(currentDepartment.id, currentDepartment.name);
        showSnackbar('Department updated successfully');
      } else {
        await addDepartmentToOrg(currentDepartment.name);
        showSnackbar('Department created successfully');
      }

      refetch(); // Refresh data
      handleCloseDepartmentDialog();
    } catch (error) {
      console.error('Error saving department:', error);
      showSnackbar('Error saving department', 'error');
    }
  };

  // Helper function to get user's department
  const getUserDepartment = (userId) => {
    const userDepartment = departments?.find((dept) => dept.users?.some((u) => u.id === userId));
    return userDepartment ? userDepartment.name : 'Not assigned';
  };

  // Helper function to get users in a department
  const getUsersInDepartment = (departmentId) => {
    const department = departments?.find((dept) => dept.id === departmentId);
    return department?.users || [];
  };


 

  if (isLoading) {
    return <LoadingSpinner text="Loading data..." />;
  }


  
  return (
    <AppPage title={title} requiredPermissions={['menu:manage:games']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">Manage Organization Departments</Typography>
        <Divider sx={{ marginTop: 3 }} />
        <Stack direction="row" justifyContent="right" alignItems="flex-end" spacing={2} p={3}>
          <Button startIcon={<Plus />} variant="contained" onClick={openAddDepartmentDialog}>
            Add Department
          </Button>
        </Stack>
        <Stack spacing={2}>
          <DepartmentsTable
            onEdit={openEditDepartmentDialog}
            departments={departmentData}
          ></DepartmentsTable>
        </Stack>
      </Container>

      <AddDepartmentDialog
        open={openDepartmentDialog}
        onClose={handleCloseDepartmentDialog}
        onChange={handleDepartmentChange}
        onSave={handleSaveDepartment}
        department={currentDepartment}
      />
    </AppPage>
  );
};

export default ManageDepartments;
