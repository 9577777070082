import React from 'react';
import { Stack, Typography } from '@mui/material';
import { User } from '@/types/user';
import UsersTable from '@/features/settings/components/UsersTable';

interface InvitedUsersSectionProps {
  invitedUsers: User[];
  onEditUser: (user: User) => void;
  onDeleteUser: (userId: number) => void;
  onDisableUser: (userId: number) => void;
  onInviteUser: (user:User) => void;
}

export const InvitedUsersSection: React.FC<InvitedUsersSectionProps> = ({
  invitedUsers,
  onEditUser,
  onDeleteUser,
  onDisableUser,
  onInviteUser,
}) => {
  if (!invitedUsers.length) return null;

  return (
    <>
      <Stack direction="column" justifyContent="center" spacing={2} p={3}>
        <Typography variant="subtitle1">Invites</Typography>
      </Stack>
      <UsersTable
        invitees={true}
        users={invitedUsers}
        handleEditUserGroup={onEditUser}
        handleDelete={onDeleteUser}
        handleDisable={onDisableUser}
        handleInvite={onInviteUser}
        pageSize={5}
        disabledColumns={['lastSeen', 'name']}
      />
    </>
  );
};
