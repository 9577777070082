import { useMutation } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

interface KillSessionResponse {
  success: boolean;
  message?: string;
}

interface KillSessionError {
  message: string;
  status?: number;
}

const useKillSessionMutation = () => 
  useMutation<KillSessionResponse, KillSessionError, string>({
    mutationFn: async (sessionId: string) => {
      try {
        const response = await post<KillSessionResponse>(
          '/v1.0/internals/mgmt/sessions/clear',
          { sessionId }
        );
        
        if (!response || !response.success) {
          throw new Error(response?.message || 'Failed to kill session');
        }
        
        return response;
      } catch (error) {
        // Enhance error object with type checking
        const err = error as Error;
        throw {
          message: err.message || 'Failed to kill session',
          status: (error as any)?.status || 500
        };
      }
    },
    gcTime: 0,
    retry: (failureCount, error) => {
      // Only retry if it's not a 400-level error
      return failureCount < 3 && !(error.status >= 400 && error.status < 500);
    }
  });

export default useKillSessionMutation;