import { useMemo, useState, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import {
  Box,
  Container,
  Typography,
  Divider,
  Dialog,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Button,
  Chip,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import CloseIcon from '@mui/icons-material/Close';

import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import GamesForm, {
  DEFAULT_VALUES,
  FormValues as GamesFormValues,
} from '@/features/games/components/GamesForm';
import GameEditionForm from '@/features/games/components/GameEditionForm';
import GameMatchup from './GameMatchup';

import { TransitionProps } from '@mui/material/transitions';
import { Slide } from '@mui/material';
import { forwardRef } from 'react';

import { GameModel } from '@/features/games/types';
import useUpdateGameDetails from '@/features/games/hooks/updateGameDetailsMutation';
import { PAGE_SIZE, useGamesQuery } from '@/features/games/hooks/useGameQuery';
import { useSnackbar } from 'notistack';
import useConfirmationDialogContext from '@/shared/hooks/useConfirmationDialogContext';
import { Edit, OndemandVideo } from '@mui/icons-material';
import GameToggle from './GameToggle';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

// Alternative approach using only Intl (without date-fns)
function formatWithIntl(dateTimeStr, timezone) {
  const date = new Date(dateTimeStr);
  const browserLocale = navigator.language || 'en-US';
  
  // Get browser's timezone if none provided
  const effectiveTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  return new Intl.DateTimeFormat(
    browserLocale, 
    {
      dateStyle: 'full',
      timeStyle: 'long',
      timeZone: effectiveTimezone
    }
  ).format(date);
}

// Transition component (same as before)
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Status color function (same as before)
const getStatusColor = (status: string) => {
  switch (status) {
    case 'scheduled':
      return '#7FA748';
    case 'live':
      return '#f05d5e';
    case 'recorded':
      return '#e3b505';
    default:
      return 'default';
  }
};

// Utility function to remove null/undefined values
function removeNullOrUndefined(
  obj: Record<string, GamesFormValues>,
): Record<string, GamesFormValues> {
  return Object.entries(obj)
    .filter(([key, value]) => value !== null && value !== undefined)
    .reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, GamesFormValues>,
    );
}

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  filename: 'games',
  useKeysAsHeaders: true,
});

const flattenData = (data: GameModel[]) => {
  return data.map((game) => {
    console.log(game.venue)
    return {
      id: game.id,
      name: `${game.awayTeam.name}(${game.awayTeam.abbreviation}) @ ${game.homeTeam.name} (${game.homeTeam.abbreviation})`,
      startTimestamp: formatWithIntl(game.startTimestamp, game.venue.timezone),
      venue: game.venue.name,
      status: game.status,
      available: game.available,
    };
  });
};

const ManageGamesPage = () => {
  const setConfirmationDialog = useConfirmationDialogContext();

  const [formValues, setFormValues] = useState<GamesFormValues>(DEFAULT_VALUES);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const gamesQuery = useGamesQuery(formValues, paginationModel.page, paginationModel.pageSize);
  const snackBar = useSnackbar();

  const isLoading = gamesQuery.isLoading || gamesQuery.isRefetching;

  const total = useMemo(() => gamesQuery.data?.total, [gamesQuery.data]);
  const allGames = useMemo(() => gamesQuery.data?.results, [gamesQuery.data]);

  const title = 'Game Management';

  useDocumentTitle(title);

  const handleFilterAndSortChange = (values: GamesFormValues) => {
    setFormValues(removeNullOrUndefined(values));
  };

  const handleExportData = useCallback(() => {
    if (!gamesQuery.data?.results) return;
    const flattenedData = flattenData(gamesQuery.data?.results);
    const csv = generateCsv(csvConfig)(flattenedData);
    download(csvConfig)(csv);
  }, [gamesQuery.data?.results]);

  // Define columns for Material React Table
  const columns = useMemo<MRT_ColumnDef<GameModel>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: 'Title',
        Cell: ({ row }) => <GameMatchup game={row.original} />,
      },
      {
        accessorKey: 'startTimestamp',
        header: 'Start Time',
        size: 200,
        Cell: ({ row }) => format(new Date(row.original.startTimestamp), 'PPpp'),
      },
      {
        accessorKey: 'venue.name',
        header: 'Location',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <Chip
            sx={{
              backgroundColor: getStatusColor(row.original.status),
              color: 'white',
            }}
            size="small"
            label={row.original.status}
          />
        ),
      },
    ],
    [],
  );

  const [open, setOpen] = useState(false);
  const [editingGame, setEditingGame] = useState<GameModel | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const updateGameDetails = useUpdateGameDetails();

  const [updatedDetails, setUpdatedDetails] = useState<GameModel | null>(null);

  const handleSaveGameDetails = useCallback(async () => {
    try {
      await updateGameDetails.mutateAsync(updatedDetails);
      await gamesQuery.refetch();
      snackBar.enqueueSnackbar('Game details saved successfully.', { variant: 'success' });
    } catch (e) {
      console.error(e);

      // Error handling (similar to previous implementation)
      const error = e as any;
      if (error.code === 'P2002') {
        const fieldName = error.fields && error.fields[0] ? error.fields[0] : 'field';
        snackBar.enqueueSnackbar(`A game with this ${fieldName} already exists.`, {
          variant: 'error',
        });
      } else if (error.code === 'P2025') {
        snackBar.enqueueSnackbar('Game not found. It may have been deleted.', { variant: 'error' });
      } else if (error.status === 409) {
        snackBar.enqueueSnackbar(error.error || 'Conflict with existing data.', {
          variant: 'error',
        });
      } else if (error.status === 400) {
        snackBar.enqueueSnackbar(error.error || 'Invalid data provided.', { variant: 'error' });
      } else {
        snackBar.enqueueSnackbar('An error occurred while saving game details.', {
          variant: 'error',
        });
      }
      throw e;
    }
  }, [updateGameDetails, updatedDetails, gamesQuery, snackBar]);

  const onSaveGame = useCallback(() => {
    setConfirmationDialog({
      confirmButtonStyle: 'error',
      title: `Save Game`,
      message: <Typography>Saving changes to this game will update the game details.</Typography>,
      confirmText: `Confirm`,
      cancelText: 'Cancel',
      onConfirm: async () => await handleSaveGameDetails(),
    });
  }, [setConfirmationDialog, handleSaveGameDetails]);

  const toggleGame = useCallback(
    async (available: boolean, game: GameModel) => {
      if (game) {
        try {
          await updateGameDetails.mutateAsync({
            ...game,
            available,
          });

          await gamesQuery.refetch();

          snackBar.enqueueSnackbar('Game availability updated successfully.', {
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          snackBar.enqueueSnackbar('An error occurred while updating game availability.', {
            variant: 'error',
          });
        }
      }
    },
    [updateGameDetails, gamesQuery, snackBar],
  );

  return (
    <AppPage title={title} requiredPermissions={['menu:manage:games']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Manage Games</Typography>
        <Typography variant="body1">Enable / Disable Games, edit games etc.</Typography>
        <Divider sx={{ marginTop: 3 }} />

        <GamesForm onChange={handleFilterAndSortChange} showAvailableToggle={true} />

        {total !== undefined ? (
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {total === 0 ? 'No games found.' : total > 1 ? `${total} Games` : `${total} Game`}
          </Typography>
        ) : null}

        {gamesQuery.isError ? (
          <Typography color="error" sx={{ textAlign: 'center' }}>
            An error occurred while fetching games.
          </Typography>
        ) : null}

        <Box sx={{ height: 600, marginTop: 4 }}>
          <MaterialReactTable
            columns={columns}
            data={allGames || []}
            enablePagination
            manualPagination
            rowCount={total || 0}
            state={{
              isLoading,
              pagination: {
                pageIndex: paginationModel.page,
                pageSize: paginationModel.pageSize,
              },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  padding: '8px',
                  flexWrap: 'wrap',
                }}
              >
                <Button
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  onClick={handleExportData}
                  startIcon={<FileDownloadIcon />}
                >
                  Export All Data
                </Button>
              </Box>
            )}
            // Reduce cell padding
            muiTableBodyCellProps={{
              sx: { py: '4px' }, // Default is usually 16px
            }}
            // Add this property to style disabled game rows with reddish background
            muiTableBodyRowProps={({ row }) => ({
              sx: {
                backgroundColor: !row.original.available ? 'rgba(156, 0, 21,0.04)' : undefined,
                '&:hover': {
                  backgroundColor: !row.original.available ? 'rgba(156, 0, 21,0.14)' : undefined,
                },
              },
            })}
            onPaginationChange={(updater) => {
              const newPagination =
                typeof updater === 'function'
                  ? updater({ pageIndex: paginationModel.page, pageSize: paginationModel.pageSize })
                  : updater;
              setPaginationModel({
                page: newPagination.pageIndex,
                pageSize: newPagination.pageSize,
              });
              // Additional server-side pagination logic can be added here
            }}
            positionActionsColumn="last"
            enableRowActions
            renderRowActions={({ row }) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <GameToggle game={row.original} onToggle={toggleGame} />
                <IconButton
                  onClick={() => {
                    setEditingGame(row.original as GameModel);
                    setOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(`/games/${row.original?.uniqueId}`, '_blank');
                  }}
                >
                  <OndemandVideo />
                </IconButton>
              </Box>
            )}
          />
        </Box>
      </Container>

      <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <MuiAppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Game
            </Typography>
            <Button
              variant="contained"
              autoFocus
              onClick={() => {
                window.open(`/games/${editingGame?.uniqueId}`, '_blank');
              }}
              sx={{ marginRight: 2 }}
            >
              View Game
            </Button>
            <Button variant="contained" autoFocus onClick={onSaveGame}>
              Save
            </Button>
          </Toolbar>
        </MuiAppBar>
        <GameEditionForm
          gameDetails={editingGame}
          handleGameDataChange={(gameDetails) => setUpdatedDetails(gameDetails)}
        />
      </Dialog>
    </AppPage>
  );
};

export default ManageGamesPage;
