import { User } from '@/types/user';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';


const getCurrentUserQuery = () =>
  useQuery({
    queryKey: ['User'],
    queryFn: async () => get<User>('/v1.0/user'),
    enabled: false,
    staleTime: 1_800_000, 
    gcTime: 2 * 1_800_000    // Data remains in cache for 5 minutes
  });

export default getCurrentUserQuery;
