import { Role } from "@/types/user";
import { useQuery } from "@tanstack/react-query";
import { get } from "@truefit/http-utils";


export const useRolesQuery = () => {
    return useQuery<Role[]>({
        queryKey: ['roles'],
        queryFn: async (): Promise<Role[]> => {
            const { data } = await get('/v1.0/roles');
            return data;
        }
    });
}