

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

export type InvitePayload = {
    groupId: number;
    email: string;
}


export const useInviteUserToOrganizationMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: InvitePayload): Promise<void> => {
            await post<void>(`/v1.0/users/invite`, model);
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['AllUsers'] })
    });
};

export default useInviteUserToOrganizationMutation;
