import { useState } from 'react';
import { Box, Stack, Divider, Typography, Chip, Button, Menu, MenuItem } from '@mui/material';
import { BookmarkPlus, Scissors, SortAsc, Clock, ChevronDown } from 'lucide-react';
import { LoadingButton } from '@mui/lab';
import { KeyboardShortcuts } from '@/shared/constants';
import { PANEL_PADDING } from '@/features/games/constants';
import { ClipType } from '../types';
import { TagsFilter } from './TagsFilter';
import { ordinalNoun } from '@/shared/services';

export interface Props {
  setTagsFilter: (tags: string[]) => void;
  isEditing: boolean;
  handleAddBookmark: () => void;
  handleAddClip: () => void;
  filter: ClipType | 'all';
  setFilter: (filter: ClipType | 'all') => void;
  filteredClips: any[];
  departments?: string[];
  userDepartments?: string[];
  setDepartmentFilter: (departments: string[]) => void;
  handleSortChange?: (sortBy: string) => void;
}

const ClipsFilterPanel = ({
  setTagsFilter,
  isEditing,
  handleAddBookmark,
  handleAddClip,
  filter,
  setFilter,
  filteredClips,
  userDepartments = [],
  setDepartmentFilter,
  handleSortChange,
}: Props) => {

  const [addClipButtonIsLoading, setAddClipButtonIsLoading] = useState(false);
  const [sortOption, setSortOption] = useState('startTimestamp');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const handleDepartmentChange = (department) => {
    const newSelection = selectedDepartments.includes(department)
      ? selectedDepartments.filter((dep) => dep !== department)
      : [...selectedDepartments, department];

    setSelectedDepartments(newSelection);
    setDepartmentFilter(newSelection);
  };

  const handleSortMenuOpen = (event: any) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
  };

  const sortHandler = (sortBy: string) => {
    setSortOption(sortBy);
    handleSortMenuClose();
    if (handleSortChange) handleSortChange(sortBy);
  };

  return (
    <Box sx={{ pt: 2, backgroundColor: 'grey.900' }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        ml={PANEL_PADDING}
        mr={PANEL_PADDING - 1}
      >
        <Stack direction="row" spacing={2}>
          <LoadingButton
            startIcon={<BookmarkPlus size="20" />}
            onClick={handleAddBookmark}
            disabled={isEditing}
            variant="contained"
            size="small"
          >
            Bookmark ({KeyboardShortcuts.AddBookmark.keys.join('+')})
          </LoadingButton>
          <LoadingButton
            loading={addClipButtonIsLoading}
            variant="contained"
            startIcon={<Scissors size="20" />}
            size="small"
            onClick={() => {
              setAddClipButtonIsLoading(true);
              handleAddClip();
              setTimeout(() => setAddClipButtonIsLoading(false), 2000);
            }}
            disabled={isEditing}
          >
            Clip ({KeyboardShortcuts.AddClip.keys.join('+')})
          </LoadingButton>
        </Stack>

        <Menu anchorEl={sortAnchorEl} open={Boolean(sortAnchorEl)} onClose={handleSortMenuClose}>
          <MenuItem
            onClick={() => sortHandler('startTimestamp')}
            selected={sortOption === 'startTimestamp'}
          >
            <Clock size="16" style={{ marginRight: 8 }} />
            Time
          </MenuItem>
          <MenuItem onClick={() => sortHandler('name')} selected={sortOption === 'name'}>
            <SortAsc size="16" style={{ marginRight: 8 }} />
            Name (A-Z)
          </MenuItem>
        </Menu>
      </Box>

      {/* Tags Filter - remains unchanged */}
      <TagsFilter setTagsFilter={setTagsFilter} isEditing={isEditing} />

      <Box sx={{ my: 2, mx: PANEL_PADDING }}>
        <Typography variant="body2" sx={{ mb: 1, fontWeight: 'medium' }}>
          Filter by media type:
        </Typography>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <Chip
              label="All"
              onClick={() => !isEditing && setFilter('all')}
              variant={filter === 'all' ? 'filled' : 'outlined'}
              disabled={isEditing}
              sx={{
                ...(filter === 'all' && {
                  bgcolor: 'white', // Custom primary blue
                  color: 'black',
                  '&:hover': {
                    bgcolor: '#aaa',
                  },
                }),
              }}
            />
            <Chip
              label="Bookmarks"
              onClick={() => !isEditing && setFilter(ClipType.Bookmark)}
              variant={filter === ClipType.Bookmark ? 'filled' : 'outlined'}
              disabled={isEditing}
              sx={{
                ...(filter === ClipType.Bookmark && {
                  bgcolor: 'white', // Custom purple
                  color: 'black',
                  '&:hover': {
                    bgcolor: '#aaa',
                  },
                }),
              }}
            />
            <Chip
              label="Clips"
              onClick={() => !isEditing && setFilter(ClipType.Clip)}
              variant={filter === ClipType.Clip ? 'filled' : 'outlined'}
              disabled={isEditing}
              sx={{
                ...(filter === ClipType.Clip && {
                  bgcolor: 'white', // Custom green (similar to success)
                  color: 'black',
                  '&:hover': {
                    bgcolor: '#aaa',
                  },
                }),
              }}
            />
          </Stack>
        </Stack>
      </Box>

      {userDepartments.length > 1 && (
        <Box sx={{ my: 2, mx: PANEL_PADDING }}>
          <Typography variant="body2" sx={{ mb: 1, fontWeight: 'medium' }}>
            Filter by department:
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {userDepartments.map((department) => (
              <Chip
                key={department.id}
                label={department.name}
                onClick={() => handleDepartmentChange(department.id)}
                variant={selectedDepartments.includes(department.id) ? 'filled' : 'outlined'}
                disabled={isEditing}
                sx={{
                  ...(selectedDepartments.includes(department.id) && {
                    mb: 1,
                    bgcolor: 'white', // Custom green (similar to success)
                    color: 'black',
                    '&:hover': {
                      bgcolor: '#aaa',
                    },
                  }),
                }}
              />
            ))}
          </Stack>
        </Box>
      )}

      <Box sx={{ my: 2, mx: PANEL_PADDING }}>
        <Button
          sx={{ mt: 2, borderColor: 'grey.700' }}
          endIcon={<ChevronDown size="16" />}
          onClick={handleSortMenuOpen}
          variant="outlined"
          size="small"
        >
          Sort by: {sortOption === 'startTimestamp' ? 'Time' : 'Name'}
        </Button>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        my={2}
        mx={PANEL_PADDING}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography variant="subtitle2" fontStyle="italic" textAlign="right" sx={{ opacity: 0.5 }}>
          Showing {ordinalNoun(filteredClips.length, 'Record', 'Records')}
        </Typography>
      </Stack>

      <Divider />
    </Box>
  );
};

export default ClipsFilterPanel;
