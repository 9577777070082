import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';
import { Org } from '@/types/user';

export type OrgSchedule = {
    orgId?: number;
    hourOfTheDay: number;
    availableInstances: number;
}

const useUpdateOrganizationInstanceSchedulingMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (model: OrgSchedule[]) => {
            return await put<Org>(`/v1.0/internals/mgmt/instance/planning`, model);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['org'] });
        },
    });
};

export default useUpdateOrganizationInstanceSchedulingMutation;
