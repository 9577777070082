import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container/Container';
import DateDisplay from './DateDisplay';
import LoadingIndicator from './LoadingIndicator';
import GameCard from './GameCard';
import { Game, GamesByDateGroup } from '../types/Games';
import Grid from '@mui/material/Grid';

interface GamesContentProps {
  gamesByDate: GamesByDateGroup[];
  dateRefs: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
  cardHeight: number;
  allGames: Game[];
  hasFetchedPrevious: boolean;
  isFetchingPreviousGames: boolean;
  hasMorePreviousGames: boolean;
  hasFetchedNext: boolean;
  isFetchingNextGames: boolean;
  hasMoreNextGames: boolean;
  previousLoadMoreRef: (node?: Element | null) => void;
  nextLoadMoreRef: (node?: Element | null) => void;
  activeStickyDate: string | null;
}

const GamesContent: React.FC<GamesContentProps> = ({
  gamesByDate,
  dateRefs,
  cardHeight,
  allGames,
  hasFetchedPrevious,
  isFetchingPreviousGames,
  hasMorePreviousGames,
  hasFetchedNext,
  isFetchingNextGames,
  hasMoreNextGames,
  previousLoadMoreRef,
  nextLoadMoreRef,
  activeStickyDate,
}) => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Previous games loading indicator or trigger element */}
      <Box
        ref={previousLoadMoreRef}
        sx={{
          textAlign: 'center',
          mb: 4,
          py: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {allGames.length > 0 && (
          <>
            {hasFetchedPrevious && isFetchingPreviousGames ? (
              <LoadingIndicator message="Loading previous games..." />
            ) : hasMorePreviousGames ? (
              <Typography variant="body2" color="text.secondary">
                Scroll up for more games
              </Typography>
            ) : hasFetchedPrevious ? (
              <Typography variant="body2" color="text.secondary">
                No more previous games
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Scroll up to load previous games
              </Typography>
            )}
          </>
        )}
      </Box>

      {/* Display games grouped by date */}
      {gamesByDate.map(({ date, formattedDate, games }) => (
        <Box key={date} sx={{ my: 4 }}> {/* Increased vertical margin for more spacing between date groups */}
          <Box
            ref={(el) => {
              dateRefs.current[date] = el;
            }}
            data-date={date}
            sx={{
              position: 'relative',
              top: 0,
              zIndex: date === activeStickyDate ? 10 : 1,
              mb: 2, 
              pt: 2, // Reduced top padding
              pb: 1, // Added bottom padding
              opacity: activeStickyDate === null || activeStickyDate === date ? 1 : 0,
              transition: 'opacity 0.2s ease-out',
              pointerEvents: activeStickyDate === date ? 'auto' : 'none',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 41,
                left: -100,
                right: 0,
                bottom: 0,
                zIndex: -1,
                backgroundColor: 'background.default', // Add background to prevent see-through
              }}
            >
              <DateDisplay date={date} />
            </Box>
          </Box>

          <Grid container spacing={3} sx={{ width: '100%' }}> {/* Increased spacing between cards and removed left margin */}
            {games.map((game) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={game.id || `${game.homeTeam.id}-${game.awayTeam.id}-${game.startTimestamp}`}
                sx={{ mb: 3 }}
              >
                <GameCard game={game} cardHeight={cardHeight} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}

      {/* Next games loading indicator or trigger element */}
      <Box
        ref={nextLoadMoreRef}
        sx={{
          textAlign: 'center',
          mt: 4,
          py: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {allGames.length > 0 && (
          <>
            {hasFetchedNext && isFetchingNextGames ? (
              <LoadingIndicator message="Loading next games..." />
            ) : hasMoreNextGames ? (
              <Typography variant="body2" color="text.secondary">
                Scroll down for more games
              </Typography>
            ) : hasFetchedNext ? (
              <Typography variant="body2" color="text.secondary">
                No more upcoming games
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Scroll down to load upcoming games
              </Typography>
            )}
          </>
        )}
      </Box>

      {/* Handle no games case */}
      {allGames.length === 0 && (
        <Box sx={{ textAlign: 'center', py: 6 }}>
          <Typography variant="h5" color="text.secondary">
            No games found for the selected filters
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default GamesContent;