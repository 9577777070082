// hooks/useUserManagement.ts
import { useState, useCallback, useEffect, useContext, use } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { Group, User } from '@/types/user';
import { flattenInvitedUsers, flattenUsers } from '@/features/settings/services/flattenUsers';
import useUpdateOrgMutation from '@/features/settings/hooks/useUpdateOrgMutation';
import { getAllUsersQueryKey, useAllUsersQuery, useDeleteUserMutation, useDisableUserMutation, useInviteUserToOrganizationMutation, useOrgGroupsQuery } from '@/features/settings/hooks';
import updateUserDepartmentsMutation from '@/features/settings/hooks/useUpdateUserDepartmentsMutation';
import useUpdateUserGroupMutation from '@/features/settings/hooks/useUpdateUserGroupMutation';

interface UserManagementState {
  users: User[];
  invitedUsers: User[];
  groups: Group[];
  inviteEmails: string[];
  selectedUser: User | null;
  groupId: number;
  inviteOpen: boolean;
  deleteDialogOpen: boolean;
  disableDialogOpen: boolean;
  editDialogOpen: boolean;
  confirmToggleDisabled: boolean;
  departments: any[];
}

const initialState: UserManagementState = {
  users: [],
  invitedUsers: [],
  groups: [],
  inviteEmails: [],
  selectedUser: null,
  groupId: -1,
  inviteOpen: false,
  deleteDialogOpen: false,
  disableDialogOpen: false,
  editDialogOpen: false,
  confirmToggleDisabled: false,
  departments: [],
};

export const useUserManagement = () => {
  const [state, setState] = useState<UserManagementState>(initialState);
  const { actions: { setCurrentOrg }, currentOrg } = useContext(UserStateContext);
  const queryClient = useQueryClient();

  // Queries
  const allUsersQuery = useAllUsersQuery(currentOrg?.id);
  const orgGroups = useOrgGroupsQuery(currentOrg?.id);

  // Mutations
  const mutations = {
    updateOrganizationMutation: useUpdateOrgMutation(),
    inviteUserToOrganizationMutation: useInviteUserToOrganizationMutation(),
    disableUserMutation: useDisableUserMutation(currentOrg?.id as number),
    deleteUserMutation: useDeleteUserMutation(currentOrg?.id as number),
    updateUserDepartmentsMutation: updateUserDepartmentsMutation(),
    useUpdateUserGroupMutation: useUpdateUserGroupMutation(),
  };

  // State update helpers
  const updateState = useCallback((updates: Partial<UserManagementState>) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  // Dialog handlers
  const handleOpenInviteDialog = useCallback(() => {
    updateState({ inviteOpen: true });
  }, [updateState]);

  const handleCloseInviteDialog = useCallback(() => {
    updateState({
      inviteOpen: false,
      inviteEmails: [],
      groupId: -1,
    });
  }, [updateState]);

  const handleOpenEditDialog = useCallback((user: User) => {
    const userGroup = state.groups.find(group => group.name === user.groups[0]);
    updateState({
      selectedUser: user,
      groupId: userGroup?.id || -1,
      editDialogOpen: true,
    });
  }, [state.groups, updateState]);

  const handleCloseEditDialog = useCallback(() => {
    updateState({
      editDialogOpen: false,
      selectedUser: null,
      groupId: -1,
    });
  }, [updateState]);

  // User management handlers
  const handleDelete = useCallback((userId: number) => {
    const user = [...state.invitedUsers, ...state.users].find(u => u.id === userId);
    if (user) {
      updateState({
        selectedUser: user,
        deleteDialogOpen: true,
      });
    }
  }, [state.invitedUsers, state.users, updateState]);

  const handleDisable = useCallback((userId: number) => {
    const user = [...state.invitedUsers, ...state.users].find(u => u.id === userId);
    if (user) {
      updateState({
        selectedUser: user,
        disableDialogOpen: true,
      });
    }
  }, [state.invitedUsers, state.users, updateState]);

  const handleEditUserGroup = useCallback((user: User) => {
    const userGroup = state.groups.find(group => group.name === user.groups[0]);
    updateState({
      selectedUser: user,
      groupId: userGroup?.id || -1,
      editDialogOpen: true,
    });
  }, [state.groups, updateState]);

  // Mutation handlers
  const confirmDelete = useCallback(async () => {
    if (state.selectedUser) {
      try {
        await mutations.deleteUserMutation.mutateAsync({ userId: state.selectedUser.id });

        updateState({
          users: state.users.filter(user => user.id !== state.selectedUser?.id),
          invitedUsers: state.invitedUsers.filter(user => user.id !== state.selectedUser?.id),
          deleteDialogOpen: false,
          selectedUser: null,
        });
      } catch (error) {
        console.error('Error deleting user:', error);
        // Handle error appropriately (e.g., show error message)
      }
    }
  }, [state.selectedUser, state.users, state.invitedUsers, mutations.deleteUserMutation, updateState]);

  const confirmAccessToggle = useCallback(async () => {
    if (state.selectedUser) {
      try {
        updateState({ confirmToggleDisabled: true });

        await mutations.disableUserMutation.mutateAsync({ userId: state.selectedUser.id });

        updateState({
          users: state.users.map(user =>
            user.id === state.selectedUser?.id ? { ...user, disabled: !user.disabled } : user
          ),
          confirmToggleDisabled: false,
          disableDialogOpen: false,
          selectedUser: null,
        });

      } catch (error) {
        console.error('Error toggling user access:', error);
        updateState({ confirmToggleDisabled: false });
        // Handle error appropriately
      }
    }
  }, [state.selectedUser, state.users, mutations.disableUserMutation, updateState]);

  const handleReInvite = useCallback(async (user: any) => {
    if (user) {
      const group = state.groups.find(g => g.name === user.groups[0]);
      if (!group) return;

      try {
        await mutations.inviteUserToOrganizationMutation.mutateAsync({
          groupId: group.id,
          email: user.email,
        });

      } catch (error) {
        console.error('Error re-inviting user:', error);
        // Handle error appropriately
      }
    }
  }, [state.groupId, mutations.inviteUserToOrganizationMutation, queryClient]);

  const handleUpdateUserDepartments = useCallback(async () => {
    try {
      const userId = state.selectedUser?.id;
      if (!userId) return;
      const departmentIds = state.departments.map(dept => dept.id);
      await mutations.updateUserDepartmentsMutation.mutateAsync({ userId, departmentIds });

      updateState({
        editDialogOpen: false,
        selectedUser: null,
      });

    } catch (error) {
      console.error('Error updating user departments:', error);
      throw error;
    }
  }
    , [mutations.updateUserDepartmentsMutation]);

  const handleInvite = useCallback(async (user?: any) => {

    if (user.email) {
      handleReInvite(user);
      return;
    }

    if (!Array.isArray(state.inviteEmails) || !state.inviteEmails.length || !state.groupId) {
      return;
    }

    try {
      const results = await Promise.allSettled(
        state.inviteEmails.map(email =>
          mutations.inviteUserToOrganizationMutation.mutateAsync({
            groupId: state.groupId,
            email,
          })
        )
      );

      const failures = results.filter(r => r.status === 'rejected');
      if (failures.length) {
        console.error('Some invitations failed:', failures);
        throw new Error('Some invitations failed');
      }

      updateState({
        inviteEmails: [],
        groupId: -1,
        inviteOpen: false,
      });

    } catch (error) {
      console.error('Error sending invitations:', error);
      // Handle error appropriately
    }
  }, [state.inviteEmails, state.groupId, mutations.inviteUserToOrganizationMutation, updateState]);

  const handleUpdateUserGroup = useCallback(async () => {

    if (!state.selectedUser || state.groupId === -1) return;

    try {
      await mutations.useUpdateUserGroupMutation.mutateAsync({
        groupId: state.groupId,
        userId: state.selectedUser.id,
      });

      updateState({
        editDialogOpen: false,
        selectedUser: null,
      });

    } catch (error) {
      console.error('Error updating user group:', error);
      // Handle error appropriately
    }
  }, [state.selectedUser, state.groupId, mutations.useUpdateUserGroupMutation, updateState]);

  const setDefaultRole = useCallback(async (groupId: number) => {
    try {
      const org = await mutations.updateOrganizationMutation.mutateAsync({ defaultGroupId: groupId });
      setCurrentOrg(org);
    } catch (error) {
      console.error('Error setting default role:', error);
      // Handle error appropriately
    }
  }, [mutations.updateOrganizationMutation, setCurrentOrg]);

  const handleUpdateUser = useCallback(async () => {
    await handleUpdateUserGroup();
    await handleUpdateUserDepartments();
  }, [handleUpdateUserGroup, handleUpdateUserDepartments]);

  // Effects
  useEffect(() => {
    if (allUsersQuery)
      allUsersQuery.refetch();
  }, [allUsersQuery]);

  useEffect(() => {
    if (allUsersQuery.status === 'success' && allUsersQuery.data) {
      updateState({
        invitedUsers: flattenInvitedUsers(allUsersQuery.data),
        users: flattenUsers(allUsersQuery.data),
      });
    }
  }, [allUsersQuery.status, allUsersQuery.data, updateState]);

  useEffect(() => {
    if (orgGroups.status === 'success' && orgGroups.data) {
      updateState({ groups: orgGroups.data });
    }
  }, [orgGroups.status, orgGroups.data, updateState]);

  // State setters
  const setters = {
    setInviteOpen: (open: boolean) => updateState({ inviteOpen: open }),
    setDeleteDialogOpen: (open: boolean) => updateState({ deleteDialogOpen: open }),
    setDisableDialogOpen: (open: boolean) => updateState({ disableDialogOpen: open }),
    setInviteEmails: (emails: string[]) => updateState({ inviteEmails: emails }),
    setGroup: (groupId: number) => updateState({ groupId }),
    setUserDepartments: (deptIds: number[]) => {
      if (state) {
        updateState({
          departments: deptIds.map(id => ({ id })),
        });
      }
    }
  };

  return {
    // State
    state,
    currentOrg,
    groups: state.groups,

    // Mutations
    mutations,

    // Handlers
    handlers: {
      handleOpenInviteDialog,
      handleCloseInviteDialog,
      handleOpenEditDialog,
      handleCloseEditDialog,
      handleDelete,
      handleDisable,
      handleEditUserGroup,
      handleInvite,
      handleUpdateUserGroup,
      handleUpdateUser,
      confirmDelete,
      confirmAccessToggle,
      setDefaultRole,
      handleCancelEdit: handleCloseEditDialog,
      ...setters,
    },

    // Loading states
    isLoading: allUsersQuery.isLoading || orgGroups.isLoading,
    isError: allUsersQuery.isError || orgGroups.isError,
  };
};