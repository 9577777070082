import { useEffect, useRef, useState, useMemo } from 'react';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { Box, Container, Divider, Typography, useTheme, useMediaQuery } from '@mui/material';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import GamesForm, { DEFAULT_VALUES, FormValues as GamesFormValues } from './GamesForm';
import 'react-perfect-scrollbar/dist/css/styles.css';
import GamesList from './GameList';
import { useAuth, LocalSessionState } from '@/features/scaffolding/components/AuthContext';

const GamesPage = () => {
  const [formValues, setFormValues] = useState<GamesFormValues>(DEFAULT_VALUES);
  const [containerHeight, setContainerHeight] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const boxRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const teamId = formValues.team ? parseInt(formValues.team, 10) : undefined;
  const seasonId = formValues.seasonId ? parseInt(formValues.seasonId, 10) : undefined;

  const handleFilterAndSortChange = (values: GamesFormValues) => {
    setFormValues(values);
  };

  const { sessionState } = useAuth();

  // Calculate container height more efficiently
  const calculateContainerHeight = useMemo(() => {
    return () => {
      if (!boxRef.current || !containerRef.current) return;

      const boxTop = boxRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Account for padding at the bottom
      const bottomPadding = 24; // 24px

      // Calculate the available height
      const availableHeight = windowHeight - boxTop - bottomPadding;

      // Set a minimum height
      const minHeight = 300;
      const newHeight = Math.max(availableHeight, minHeight);

      setContainerHeight(newHeight);
    };
  }, []);

  // Update height on mount and window resize
  useEffect(() => {
    calculateContainerHeight();

    const handleResize = () => {
      calculateContainerHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateContainerHeight]);

  // Update height when form values change
  useEffect(() => {
    // Small delay to allow form to render
    const timer = setTimeout(() => {
      calculateContainerHeight();
    }, 100);

    return () => clearTimeout(timer);
  }, [formValues, calculateContainerHeight]);

  const title = 'Games';
  useDocumentTitle(title);

  return (
    <AppPage title={title} requiredPermissions={['menu:games']}>
      {sessionState !== LocalSessionState.READY && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
            backdropFilter: 'blur(4px) grayscale(0.7)',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color="white">
            Please wait while your session loads
          </Typography>
        </Box>
      )}
      <Container ref={containerRef} maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Select a game</Typography>
        <Typography variant="body1">
          Choose between live and recorded games for a customized viewing experience.
        </Typography>
        <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

        <GamesForm onChange={handleFilterAndSortChange} />

        <Box
          ref={boxRef}
          sx={{
            height: isSmallScreen ? 'auto' : `${containerHeight}px`,
            maxHeight: isSmallScreen ? 'none' : 'calc(100vh - 300px)',
            overflow: 'auto',
            mt: 2,
            position: 'relative',
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: 'rgba(177,177,177,0)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(177, 177, 177, 0.72)',
              borderRadius: '4px',
            },
          }}
        >
          <GamesList
            key={`${teamId || 'all'}-${seasonId || 'all'}-${formValues.date?.toISOString() || 'no-date'}-${formValues.streamType.join('-')}`}
            teamId={teamId}
            seasonId={seasonId}
            date={formValues.date}
            streamTypes={formValues.streamType}
            containerRef={boxRef}
          />
        </Box>
      </Container>
    </AppPage>
  );
};

export default GamesPage;
