import { useQuery, useInfiniteQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { get } from '@truefit/http-utils'

// API base URL

// Types
interface Team {
  id: number;
  name: string;
  abbreviation: string;
  // Add other team properties as needed
}

interface Venue {
  id: number;
  name: string;
  // Add other venue properties as needed
}

interface Season {
  id: number;
  name: string;
  // Add other season properties as needed
}

interface Game {
  id: number;
  uniqueId: string;
  name: string;
  startTimestamp: string;
  endTimestamp: string | null;
  homeTeamScore: number | null;
  awayTeamScore: number | null;
  status: string | null;
  homeTeam: Team;
  awayTeam: Team;
  venue: Venue | null;
  season: Season | null;
  // Add other game properties as needed
}

interface PaginationInfo {
  hasMorePrevious: boolean;
  hasMoreNext: boolean;
  previousCursor: string | null;
  nextCursor: string | null;
}

interface GamesResponse {
  games: Game[];
  pagination: PaginationInfo;
}

// API functions
const api = {
  // Fetch initial games
  fetchGames: async (params: {
    teamId?: number;
    seasonId?: number;
    limit?: number;
    streamTypes?: string[];
    date?: Date;
    upcomingLimit?: number;
  }): Promise<GamesResponse> => {
    const urlSearchParams = new URLSearchParams();
    if (params.teamId) urlSearchParams.set('teamId', params.teamId.toString());
    if (params.seasonId) urlSearchParams.set('seasonId', params.seasonId.toString());
    if (params.limit) urlSearchParams.set('limit', params.limit.toString());
    if (params.upcomingLimit) urlSearchParams.set('upcomingLimit', params.upcomingLimit.toString());
    if (params.date) urlSearchParams.set('date', params.date.toISOString());
    if (Array.isArray(params.streamTypes) && params.streamTypes.length > 0) {
      params.streamTypes.forEach(status => {
        urlSearchParams.append('streamTypes', status);
      });
    }
    const response = await get(`/v1.1/games?${urlSearchParams.toString()}`);
    return response.data;
  },

  // Fetch previous games
  fetchPreviousGames: async (params: {
    cursor: string;
    teamId?: number;
    seasonId?: number;
    limit?: number;
    streamTypes?: string[];
    date?: Date;
  }): Promise<GamesResponse> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('cursor', params.cursor);
    if (params.teamId) urlSearchParams.set('teamId', params.teamId.toString());
    if (params.seasonId) urlSearchParams.set('seasonId', params.seasonId.toString());
    if (params.limit) urlSearchParams.set('limit', params.limit.toString());
    if (params.date) urlSearchParams.set('date', params.date.toISOString());
    if (Array.isArray(params.streamTypes) && params.streamTypes.length > 0) {
      params.streamTypes.forEach(status => {
        urlSearchParams.append('streamTypes', status);
      });
    }
    const response = await get(`/v1.1/games/previous?${urlSearchParams.toString()}`);
    return response.data;
  },

  // Fetch next games
  fetchNextGames: async (params: {
    cursor: string;
    teamId?: number;
    seasonId?: number;
    limit?: number;
    streamTypes?: string[];
    date?: Date;
  }): Promise<GamesResponse> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('cursor', params.cursor);
    if (params.teamId) urlSearchParams.set('teamId', params.teamId.toString());
    if (params.seasonId) urlSearchParams.set('seasonId', params.seasonId.toString());
    if (params.limit) urlSearchParams.set('limit', params.limit.toString());
    if (params.date) urlSearchParams.set('date', params.date.toISOString());

    if (Array.isArray(params.streamTypes) && params.streamTypes.length > 0) {
      params.streamTypes.forEach(status => {
        urlSearchParams.append('streamTypes', status);
      });
    }
    const response = await get(`/v1.1/games/next?${urlSearchParams.toString()}`);
    return response.data;
  }
};

// Hook for fetching initial games
export function useGames(params: {
  teamId?: number;
  seasonId?: number;
  limit?: number;
  streamTypes?: string[];
  date?: Date;
  upcomingLimit?: number;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: ['games', params],
    queryFn: () => api.fetchGames(params),
    enabled: params.enabled !== false,
  });
}

// Hook for infinite previous games pagination
export function usePreviousGamesInfinite(params: {
  teamId?: number;
  seasonId?: number;
  limit?: number;
  streamTypes?: string[];
  date?: Date;
  initialData?: GamesResponse;
  enabled?: boolean;
}) {
  return useInfiniteQuery({
    queryKey: ['previousGames', params],
    queryFn: ({ pageParam }) => {
      return api.fetchPreviousGames({
        cursor: pageParam as string,
        teamId: params.teamId,
        seasonId: params.seasonId,
        limit: params.limit,
        streamTypes: params.streamTypes,
        date: params.date,
      });
    },
    initialPageParam: params.initialData?.pagination?.previousCursor,
    getNextPageParam: (lastPage) =>
      lastPage.pagination.hasMorePrevious ? lastPage.pagination.previousCursor : undefined,
    enabled: !!params.initialData?.pagination?.previousCursor && params.enabled !== false,
  });
}

// Hook for infinite next games pagination
export function useNextGamesInfinite(params: {
  teamId?: number;
  seasonId?: number;
  limit?: number;
  streamTypes?: string[];
  date?: string;
  initialData?: GamesResponse;
  enabled?: boolean;
}) {
  return useInfiniteQuery({
    queryKey: ['nextGames', params],
    queryFn: ({ pageParam }) => {
      return api.fetchNextGames({
        cursor: pageParam as string,
        teamId: params.teamId,
        seasonId: params.seasonId,
        limit: params.limit,
        streamTypes: params.streamTypes,
        date: params.date,
      });
    },
    initialPageParam: params.initialData?.pagination?.nextCursor,
    getNextPageParam: (lastPage) =>
      lastPage.pagination.hasMoreNext ? lastPage.pagination.nextCursor : undefined,
    enabled: !!params.initialData?.pagination?.nextCursor && params.enabled !== false,
  });
}

// Set up QueryClient
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false,
    },
  },
});