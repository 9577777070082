import { useContext } from 'react';

import { ServerStateContext } from '../components/ServerStateProvider';
import { ServerStateAndFunctions } from './useServerState';

const useServerStateContext = (): ServerStateAndFunctions => {
    const context = useContext(ServerStateContext);
    if (context === undefined || context === null) {
        throw new Error('useServerStateContext must be used within an useServerStateContextProvider');
    }

    return context;
}

export default useServerStateContext;
