import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormLabel, Popover, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import { useTimestampState } from '@/shared/hooks/websocket/useTimestamp';
import { nameofFactory } from '@/shared/services';
import displayWallClock from '@/shared/services/displayWallClock';

import { HookFormTextField } from '../../forms/components';
import { ClipModel, ClipType, isClip } from '../types';
import GameTimePicker from '@/shared/components/GameTimePicker';
import useEnqueueClipForExportMutation from '../hooks/useEnqueueClipForExportMutation';
import TrackedObject from '@/features/teams/components/TrackedObject';
import DisplayTags from '@/features/tags/components/DisplayTags';
import { TeamModel } from '@/features/teams/types/TeamModel';
import TrackingEntityUpdateInline from '@/features/autoIso/components/TrackingEntityUpdateInline';
import { BorderBottom, Delete } from '@mui/icons-material';
import { GamePositionsRepresentation } from '@/features/autoIso/components';
import { PlayerCameraFeedSelectionButton } from '@/features/video/components';

type Props = {
  clip: ClipModel;
  teams: TeamModel[];
  onSubmit: (newClipModel: ClipModel) => Promise<void>;
  onCancel: (newClipModel: ClipModel) => void;
};

export type BookmarkModelFormValues = {
  note: string;
  description: string;
};

const nameOfForm = nameofFactory<BookmarkModelFormValues>();

const DESCRIPTION_MAX = 512;

const validationSchema = Yup.object().shape({
  [nameOfForm('note')]: Yup.string().required().max(128).min(1).label('Name'),
  [nameOfForm('description')]: Yup.string().optional().max(DESCRIPTION_MAX).label('Description'),
});

const ClipRecordForm = ({ clip, onSubmit, onCancel, teams }: Props) => {
  const {
    state: { editingModel },
    getCurrentClipState,
    handleSetBookmarkTime,
    handleSetEndTimeOnSlider,
    handleSetStartTimeOnSlider,
    handleDelete,
  } = useClipStateContext();

  const exportClipMutation = useEnqueueClipForExportMutation();

  // popover to change tracked entity
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const trackedEntityRef = useRef(null);

  const [, setTimestamp] = useTimestampState();

  const [timestamp] = useTimestampState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    handleSetBookmarkTime();
  }, [handleSetBookmarkTime, timestamp]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(trackedEntityRef.current);
    return event;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const form = useForm<ClipModel>({
    defaultValues: {
      note: editingModel.note,
      description: editingModel.description,
    },
    mode: 'onSubmit',
    //@ts-expect-error
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = form.handleSubmit(async (formValues) => {
    setDisabled(true);

    const updateClipModel: ClipModel = { ...getCurrentClipState().editingModel, ...formValues };
    await onSubmit(updateClipModel);
    await exportClipMutation.mutate(editingModel.id);

    setDisabled(false);
  });

  const handleCancel = () =>
    onCancel({ ...getCurrentClipState().editingModel, ...form.getValues() });

  if (!editingModel) return null;

  const descriptionLength = form.watch(nameOfForm('description')).length;

  const handleStartTimeChange = (date: Date | null) => {
    if (date) {
      handleSetStartTimeOnSlider(date);
      if (setTimestamp) setTimestamp(date);
    }
  };

  const handleEndTimeChange = (date: Date | null) => {
    if (date) {
      handleSetEndTimeOnSlider(date);
      if (setTimestamp) setTimestamp(date);
    }
  };

  const handleChangeTrackedEntity = (value: any) => {
    handleClose();
  };

  const newClip = useMemo(() => editingModel.status === 'UNPROCESSED', [editingModel.status]);

  return (
    <>
      {isClip(editingModel) && <GamePositionsRepresentation sx={{ m: 2 }} />}

      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <HookFormTextField
              name={nameOfForm('note')}
              label="Name"
              disabled={disabled}
              required
              size="small"
              margin="none"
              variant="standard"
            />
            {clip.type === ClipType.Bookmark && (
              <TextField
                label="Timestamp"
                required
                size="small"
                margin="none"
                disabled
                fullWidth
                variant="standard"
                value={displayWallClock(editingModel.startTimestamp, {
                  includeSubseconds: true,
                })}
              />
            )}
          </Stack>
          {isClip(editingModel) && (
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
              <GameTimePicker
                label="Start Time"
                value={editingModel.startTimestamp}
                onChange={handleStartTimeChange}
              />
              <GameTimePicker
                label="End Time"
                value={editingModel.endTimestamp}
                onChange={handleEndTimeChange}
              />
            </Stack>
          )}
          {isClip(editingModel) && (
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={1}
              mt={2}
              ref={trackedEntityRef}
            >
              <FormLabel>Tracked Object</FormLabel>
              <TrackedObject
                onClick={handleClick}
                readOnly={true}
                clip={editingModel}
                teams={teams}
                sx={{
                  padding: '0px 16px',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderBottom: '1px solid #e0e0e0',
                    transition: 'border-color 0.2s',
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: '50%',
                    right: '50%',
                    bottom: 0,
                    borderBottom: '2px solid #123be2', /* MUI primary color - change as needed */
                    transition: 'left 0.3s ease-out, right 0.3s ease-out',
                  },
                  '&:hover::before': {
                    borderColor: 'rgba(0, 0, 0, 0.87)', /* Darker on hover */
                  },
                  '&:hover::after, &:focus::after': {
                    left: 0,
                    right: 0,
                  },
                }}
              ></TrackedObject>
            </Stack>
          )}

          {isClip(editingModel) && (
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={1}
              mt={2}
              ref={trackedEntityRef}
              sx={{
                padding: '6px 0px',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderBottom: '1px solid #e0e0e0',
                  transition: 'border-color 0.2s',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '50%',
                  right: '50%',
                  bottom: 0,
                  borderBottom: '2px solid #123be2', /* MUI primary color - change as needed */
                  transition: 'left 0.3s ease-out, right 0.3s ease-out',
                },
                '&:hover::before': {
                  borderColor: 'rgba(0, 0, 0, 0.87)', /* Darker on hover */
                },
                '&:hover::after, &:focus::after': {
                  left: 0,
                  right: 0,
                },
              }}
            >
              <FormLabel>Camera </FormLabel>
              <PlayerCameraFeedSelectionButton />
            </Stack>
          )}

          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
            <HookFormTextField
              name={nameOfForm('description')}
              label="Description"
              margin="none"
              variant="standard"
              disabled={disabled}
              multiline
              helperText={`${descriptionLength.toLocaleString()}/${DESCRIPTION_MAX.toLocaleString()}`}
              FormHelperTextProps={{
                sx: (t) => ({
                  textAlign: 'right',
                  color:
                    descriptionLength > DESCRIPTION_MAX
                      ? t.palette.error.main
                      : t.palette.text.secondary,
                }),
              }}
            />
          </Stack>
          <Box>
            <FormLabel>Tags</FormLabel>
            <DisplayTags clip={editingModel} editMode={true} readonly={false} />
          </Box>

          <Stack direction="row" alignItems="center" justifyContent="flex-end" my={1}>
            <Button
              style={{ marginRight: 10 }}
              onClick={newClip ? () => handleDelete(editingModel) : handleCancel}
              disabled={disabled}
              color={newClip ? 'error' : 'primary'}
              variant={newClip ? 'contained' : 'outlined'}
              startIcon={<Delete />}
            >
              {newClip ? 'Cancel' : 'Cancel Changes'}
            </Button>

            <LoadingButton
              color="primary"
              onClick={handleSubmit}
              loading={disabled}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <TrackingEntityUpdateInline teams={teams} setTrackingEntity={handleChangeTrackedEntity} />
        </Box>
      </Popover>
    </>
  );
};

export default ClipRecordForm;
