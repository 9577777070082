import { styled } from '@mui/system';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

// Using the newer styled syntax with type safety
const ZenDataGrid = styled(DataGrid)<DataGridProps>(({ theme }) => ({
  // Global styles for the DataGrid
  border: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
  
  // Modern focus handling - removes default outlines and adds subtle indicators
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  
  // Improved hover states
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? theme.palette.action.hover 
      : theme.palette.grey[50],
  },
  
  // Better column headers
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? theme.palette.background.paper 
      : theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  
  // Remove cell borders for cleaner look
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  
  // Pagination styling
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  
  // Selection styling
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.mode === 'dark'
      ? theme.palette.action.selected
      : theme.palette.primary.lighter || theme.palette.primary.light + '30',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.action.selected + theme.palette.action.hoverOpacity
        : theme.palette.primary.lighter || theme.palette.primary.light + '40',
    },
  },
}));

// Optional default props
ZenDataGrid.defaultProps = {
  disableColumnMenu: true,
  disableColumnSelector: true,
  disableDensitySelector: true,
  autoHeight: true,
  density: 'standard',
  showCellVerticalBorder: false,
  showColumnVerticalBorder: false,
  pageSizeOptions: [10, 25, 50, 100],
};

export default ZenDataGrid;