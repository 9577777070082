import { getBusinessApiEndPoint, HttpConfiguration } from './configureHttp';


let SSEBaseConfig: HttpConfiguration = {};

export function GetITMSSEUrl(): string {
  const { identityToken } = SSEBaseConfig;

  const commonIoUrl = getBusinessApiEndPoint('/v1.0/io');
  const gatewayUrl = new URL(commonIoUrl);
  gatewayUrl.pathname = gatewayUrl.pathname + '/v1.0/io';
  gatewayUrl.searchParams.append('idToken', identityToken);
  return gatewayUrl.toString();
}

export function configureITMSSE(config: HttpConfiguration) {
  SSEBaseConfig = config;
}
