function getClipsFilter(filter, tagsFilter) {
  return function(r) {
    // Type filter condition
    const typeCondition = (filter === 'all' || filter === r.type);
    
    // Tags filter condition with proper structure check
    let tagsCondition = false;
    if (tagsFilter.length === 0) {
      tagsCondition = true;
    } else if (r.cliptags && Array.isArray(r.cliptags)) {
      tagsCondition = tagsFilter.every(function(t) {
        return r.cliptags.some(function(ct) {
          // Access the tag.id through the nested tag object in cliptags
          return ct.tag && ct.tag.id === t.id;
        });
      });
    }
    
    // Return the combined result
    return typeCondition && tagsCondition;
  };
}

export default getClipsFilter;