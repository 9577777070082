import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

interface DepartmentDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (department: { id: string; name: string }) => void;
  onChange?: (event: { target: { name: any; value: any } }) => void;
  department?: { id: string | undefined; name: string };
}

const AddDepartmentDialog = ({
  open,
  onClose,
  onSave,
  onChange,
  department = { id: '', name: '' },
}: DepartmentDialogProps) => {
  const [departmentData, setDepartmentData] = useState({ ...department });
  const isEditMode = Boolean(departmentData.id);

  // Reset form when dialog opens with a new department
  useEffect(() => {
    if (open) {
      setDepartmentData({ ...department });
    }
  }, [open, department]);

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setDepartmentData((prev) => ({
      ...prev,
      [name]: value,
    }));
    onChange?.(event);
  };

  const handleSave = () => {
    onSave(departmentData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditMode ? 'Edit Department' : 'Add Department'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Department Name"
          fullWidth
          variant="outlined"
          value={departmentData.name}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AddDepartmentDialog };
export default AddDepartmentDialog;
