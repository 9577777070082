import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Org } from '@/types/user';
import { post } from '@truefit/http-utils';


export type AddUserToDepartmentModel = {
    userId: number;
    departmentId: number;
}

const useAddUserToDepartmentMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: AddUserToDepartmentModel) => {
            return await post<Org>(`/v1.0/departments/${model.departmentId}/users`, model);
        },
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: ['org'] });
        },
    });
};
export {useAddUserToDepartmentMutation}
export default useAddUserToDepartmentMutation;
