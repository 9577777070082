import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import useTagsQuery from '@/features/tags/hooks/useTagsQuery';
import useGameTeamsQuery from '@/features/games/hooks/useGameTeamsQuery';

import { HookFormDatePicker, HookFormSelect } from '../../forms/components';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { alpha } from '@mui/material/styles';
import { ClipsFormValues } from '../hooks/useAllClipsQuery';

type Props = {
  onChange: (values: ClipsFormValues) => unknown;
};


export const DEFAULT_VALUES: ClipsFormValues = {
  date: null,
  team: null,
  status: null,
  clipType: 'all',
  tags: [],
};

type FieldsetProps = {
  legend: string;
  children: React.ReactNode;
};

const Fieldset = ({ legend, children }: FieldsetProps) => (
  <Box
    component="fieldset"
    sx={{
      border: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      alignItems: 'center',
      margin: 0,
      padding: 0,
    }}
  >
    <Typography 
      variant="overline" 
      sx={{ 
        fontWeight: 600, 
        color: 'primary.main',
        fontSize: '0.75rem',
        letterSpacing: '0.08em'
      }}
    >
      {legend}
    </Typography>
    {children}
  </Box>
);

const ClipsForm = ({ onChange }: Props) => {
  const { currentLeague } = React.useContext(UserStateContext);
  const methods = useForm<ClipsFormValues>({ defaultValues: DEFAULT_VALUES });
  const { watch, handleSubmit } = methods;
  const tagsQuery = useTagsQuery();
  const teamsQuery = useGameTeamsQuery(currentLeague?.id);

  // submits the form onChange
  useEffect(() => {
    const subscription = watch(handleSubmit(onChange));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <FormProvider {...methods}>
      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          borderRadius: 2,
          background: theme => `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${alpha(theme.palette.primary.light, 0.05)} 100%)`,
        }}
      >
        <Box component="form" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: { xs: '100%', md: 'auto' } }}>
            <Typography 
              variant="subtitle2" 
              gutterBottom
              sx={{ 
                color: 'text.secondary',
                fontWeight: 600,
                mb: 1
              }}
            >
              Clip Type
            </Typography>
            
            <Controller
              name="clipType"
              control={methods.control}
              render={({ field: { onChange: LocalOnChange, value } }) => {
                const handleClick = (newValue: string) => () => LocalOnChange(newValue);
                const variant = (testValue: string) => (value === testValue ? 'filled' : 'outlined');
                const color = (testValue: string) => (value === testValue ? 'primary' : 'default');

                return (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Chip
                      label="All"
                      variant={variant('all')}
                      onClick={handleClick('all')}
                      color={color('all')}
                      sx={{ 
                        fontWeight: 500,
                        px: 1,
                        '&.MuiChip-filled': {
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          transform: 'translateY(-1px)'
                        }
                      }}
                    />

                    <Chip
                      label="Bookmarks"
                      variant={variant('bookmark')}
                      onClick={handleClick('bookmark')}
                      color={color('bookmark')}
                      sx={{ 
                        fontWeight: 500,
                        px: 1,
                        '&.MuiChip-filled': {
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          transform: 'translateY(-1px)'
                        }
                      }}
                    />

                    <Chip
                      label="Clips"
                      variant={variant('clip')}
                      onClick={handleClick('clip')}
                      color={color('clip')}
                      sx={{ 
                        fontWeight: 500,
                        px: 1,
                        '&.MuiChip-filled': {
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          transform: 'translateY(-1px)'
                        }
                      }}
                    />
                  </Box>
                );
              }}
            />
          </Box>

          <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
          <Divider sx={{ width: '100%', display: { xs: 'block', md: 'none' }, my: 1 }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 3,
              flexGrow: 1,
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <Fieldset legend="Filter">
              <HookFormDatePicker
                name="date"
                TextFieldProps={{ 
                  size: 'small', 
                  label: 'Date', 
                  sx: { 
                    width: { xs: '100%', sm: '200px' },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 1.5,
                      transition: 'all 0.2s',
                      '&:hover': {
                        boxShadow: '0 0 0 1px rgba(0,0,0,0.05)'
                      },
                      '&.Mui-focused': {
                        boxShadow: theme => `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
                      }
                    }
                  } 
                }}
                DatePickerProps={{ label: 'Date', inputFormat: 'MM/dd/yyyy' }}
              />

              <Controller
                name="team"
                control={methods.control}
                render={({ field }) => (
                  <Autocomplete
                    onChange={(_e, v) => {
                      field.onChange(v?.abbreviation || '');
                    }}
                    isOptionEqualToValue={(option, value) => option.abbreviation === value}
                    value={field.value}
                    id="team-selector"
                    getOptionLabel={(option: any) =>
                      option?.abbreviation ? option.abbreviation : option
                    }
                    options={teamsQuery.data}
                    renderOption={(props, option: any) => (
                      <Box 
                        component="li" 
                        sx={{ 
                          '& > img': { mr: 2, flexShrink: 0 },
                          borderRadius: 1,
                          transition: 'background-color 0.2s',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                          }
                        }} 
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="35"
                          src={getTeamLogoPath(option)}
                          alt={option.abbreviation}
                        />
                        {option.abbreviation}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Team" 
                        size="medium" 
                        sx={{
                          width: { xs: '100%', sm: '200px' },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 1.5,
                            transition: 'all 0.2s',
                            '&:hover': {
                              boxShadow: '0 0 0 1px rgba(0,0,0,0.05)'
                            },
                            '&.Mui-focused': {
                              boxShadow: theme => `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
                            }
                          }
                        }}
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="tags"
                control={methods.control}
                render={({ field }) => (
                  <Autocomplete
                    size="small"
                    multiple
                    disablePortal
                    onChange={(_e, v) => {
                      field.onChange(v);
                    }}
                    value={field.value ?? ''}
                    id="tags-selector"
                    options={tagsQuery.data.map((i) => i.name)}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Tags" 
                        size="medium" 
                        sx={{
                          width: { xs: '100%', md: '450px' },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 1.5,
                            transition: 'all 0.2s',
                            '&:hover': {
                              boxShadow: '0 0 0 1px rgba(0,0,0,0.05)'
                            },
                            '&.Mui-focused': {
                              boxShadow: theme => `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
                            }
                          }
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option}
                          size="small"
                          sx={{ 
                            borderRadius: 1, 
                            fontWeight: 500,
                            backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
                            color: 'primary.main',
                            '& .MuiChip-deleteIcon': {
                              color: 'primary.main',
                              '&:hover': {
                                color: 'primary.dark'
                              }
                            }
                          }}
                        />
                      ))
                    }
                  />
                )}
              />

              <HookFormSelect 
                name="status" 
                label="Status" 
                size="medium" 
                margin="dense"
                fullWidth={false}
                sx={(theme)=>({
                  width: { xs: '100%', sm: '180px', md: '200px' },
                  minWidth: { xs: '100%', sm: '180px', md: '200px' },
                  maxWidth: { xs: '100%', sm: '180px', md: '200px' },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                    transition: 'all 0.2s',
                    '&:hover': {
                      boxShadow: '0 0 0 1px rgba(0,0,0,0.05)'
                    },
                    '&.Mui-focused': {
                      boxShadow: theme => `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
                    }
                  }
                })}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="READY">Ready</MenuItem>
                <MenuItem value="PENDING">Pending</MenuItem>
                <MenuItem value="ERROR">Error</MenuItem>
                <MenuItem value="CANCELED">Canceled</MenuItem>
                <MenuItem value="UNPROCESSED">Unprocessed</MenuItem>
              </HookFormSelect>
            </Fieldset>
          </Box>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default ClipsForm;