import React, { useContext, useMemo } from 'react';
import { Chip, Stack } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import ActionMenu from './ActionMenu';
import { colorTag } from '@/shared/services/colorTag';
import { User } from '@/types/user';
import { UserStateContext } from '@/shared/components/UserStateProvider';

interface UsersTableProps {
  users: User[];
  setSelectedUser?: (user: User) => void;
  setGroupOpen?: (open: boolean) => void;
  handleDelete: (id: number) => void;
  handleDisable: (id: number) => void;
  handleEditUserGroup: (user: User) => void;
  handleInvite?: (user: User) => void;
  disabledColumns?: string[];
  invitees: boolean;
  pageSize?: number;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  handleDelete,
  handleEditUserGroup,
  handleDisable,
  handleInvite,
  disabledColumns = [], // Default to empty array if not provided
  invitees,
  pageSize = 10,
}) => {
  const { userProfile } = useContext(UserStateContext);

  if (!userProfile) {
    return null;
  }

  // Define columns for Material React Table
  const columns = useMemo<MRT_ColumnDef<User>[]>(() => {
    const allColumns = [
      {
        accessorFn: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
        id: 'name',
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 250,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {row.original.email}
            {row.original.disabled && <Chip label="Disabled" size="small" color="error" />}
            {row.original.id === userProfile.id && (
              <Chip label="You" size="small" color="primary" />
            )}
          </div>
        ),
      },
      {
        accessorKey: 'lastSeen',
        id: 'lastSeen',
        header: 'Last Seen',
        size: 150,
        Cell: ({ row }) => {
          if (!row.original.lastSeen) {
            return '-';
          }
          return `${formatDistanceToNow(new Date(row.original.lastSeen))} ago`;
        },
      },
      {
        accessorKey: 'userdepartments',
        header: 'Departments',
        size: 200,
        enableSorting: false,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {row.original.userdepartments.map((ud) => (
              <Chip key={ud.department.id} label={ud.department.name} size="small" />
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'groups',
        header: 'Security Group',
        size: 200,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {row.original.groups.map((group) => (
              <Chip
                key={group}
                label={group}
                size="small"
                style={{ color: colorTag(group).c, backgroundColor: colorTag(group).b }}
              />
            ))}
          </div>
        ),
      },
    ];

    // Filter out disabled columns
    return allColumns.filter((column) => !disabledColumns.includes(column.id));
  }, [disabledColumns, userProfile]);

  return (
      <MaterialReactTable
        columns={columns}
        data={users || []}
        enableRowActions
        positionActionsColumn="last"
        enablePagination
        muiTableContainerProps={{ sx: { height: 'auto' } }}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            backgroundColor: row.original.disabled ? 'rgba(156, 0, 21,0.04)' : undefined,
            '&:hover': {
              backgroundColor: row.original.disabled ? 'rgba(156, 0, 21,0.14)' : undefined,
            },
          }
        })}
   
        initialState={{
          pagination: {
            pageIndex: 0,
            pageSize,
          }
        }}
        enableStickyHeader // Keep the header visible when scrolling
        renderRowActions={({ row }) => {
          if (row.original.id === userProfile.id) return null;
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              style={{ marginRight: 8 }}
            >
              <ActionMenu
                invitees={invitees}
                currentUser={userProfile}
                user={row.original}
                onEdit={() => handleEditUserGroup(row.original)}
                onDelete={() => handleDelete(row.original.id)}
                onDisable={() => handleDisable(row.original.id)}
                onInvite={() => handleInvite && handleInvite(row.original)}
              />
            </Stack>
          );
        }}
      />
  );
};

export default UsersTable;