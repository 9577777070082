import { useRef } from 'react';

import {  SetTrackingTargetMessage } from '@/types/control';

import useWebSocket from './useWebsocket2';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';

const SET_TRACKING_TARGET_TYPE = 'SetTrackingTarget';

export const useSetTrackingTargetRef: WebSocketRefHook<number> = (options = {}) => {
    const ref = useRef(0);

    const socket = useWebSocket<SetTrackingTargetMessage>('/control/0.1.0', {
        onMessage: (message) => {
            return;
        },
    });

    const setValue = (value: string) => {
        socket.send({
            type: SET_TRACKING_TARGET_TYPE,
            id: Number.parseInt(value),
        });
    };

    return [ref, setValue];
};

export const useSelectCamsState = withStateFromRef(useSetTrackingTargetRef);
