import { Org } from '@/types/user';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';


const useCurrentOrgQuery = () =>
  useQuery({
    queryKey: ['org'],
    queryFn: async () => get<Org>('/v1.0/org'),
    enabled: true,
    staleTime: 30 * 60 * 1000, // 30 minutes
    gcTime: 60 * 60 * 1000, // 1 hour
  });

export default useCurrentOrgQuery;
