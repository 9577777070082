/* eslint no-case-declarations: "off" */
import { useEffect, useMemo, useState } from 'react';

import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  createFilterOptions,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { TeamModel } from '@/features/teams/types/TeamModel';

type Player = {
  id: number;
  displayName: string;
  playerNumber: string;
  playerPosition: string;
  category: string;
  isActive: boolean;
  headshot: string;
  team: {
    id?: number;
    displayName: string;
    color?: string;
    colors?: Array<{ hex_color: string }>;
  };
};

import { useAutoIsoTeamSelector } from '@/features/teams/context/AutoIsoTeamSelectorContext';
import { useCurrentGame } from '@/features/games/context/CurrentGameContext';
import { ga } from 'react-ga';

const filter = createFilterOptions();

type Props = {
  teams: TeamModel[];
  setTrackingEntity: (id: number) => void;
};

export default function TrackingEntityUpdateInline({ setTrackingEntity }: Props) {
  const { trackEntity, singleCurrentlyTrackedObjectId: selectedId } = useServerStateContext();

  const { processedTeams, handleSearchChange } = useAutoIsoTeamSelector();

  const { homeTeamPrimaryColor, awayTeamPrimaryColor, game } = useCurrentGame();

  const teamColors = useMemo(() => {
    return {
      [game.homeTeam.id]: homeTeamPrimaryColor,
      [game.awayTeam.id]: awayTeamPrimaryColor,
    };
  }, [homeTeamPrimaryColor, awayTeamPrimaryColor, game]);

  const [trackedEntity, setTrackedEntity] = useState<number | null>(null);

  const [open, setOpen] = useState(false);

  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const [trackedEntitiesOptions, setTrackedEntitiesOptions] = useState<Player[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    // First collect all player entities
    const trackedEntities = processedTeams.reduce((acc, team) => {
      if (team.players) {
        Object.keys(team.players).forEach((category) => {
          //@ts-expect-error
          acc.push(...team.players[category]);
        });
      }
      return acc;
    }, [] as Player[]);

    // Add the special "Puck" player
    const puckPlayer = {
      id: 1,
      displayName: 'Puck',
      playerNumber: '',
      playerPosition: '',
      category: 'Object of play',
      isActive: true,
      headshot: '', // You can add a default headshot URL if needed
      team: { id: -1, displayName: 'Object of play', color: 'black' },
    };

    // Make sure we don't duplicate the puck if it's already there
    if (!trackedEntities.some((player) => player.id === 1)) {
      trackedEntities.unshift(puckPlayer);
    }

    setTrackedEntitiesOptions(trackedEntities);

    setInitialized(true);
  }, [processedTeams, selectedId]);

  useEffect(() => {
    if (typeof trackedEntity === 'number') {
      setTrackingEntity(trackedEntity);
      trackEntity({ id: trackedEntity }, 'AutoIsoTeamSelector');
    }
  }, [trackedEntity]);

  // Function to get team color with proper fallbacks
  const getTeamColor = (option) => {
    if (!option || !option.team) return 'black';

    // Try to get color from teamColors using team id
    const teamColorFromMap = option.team.id ? teamColors[option.team.id] : null;

    // If not found, try team.colors array
    const teamColorFromColorsArray = option.team.colors?.[0]?.hex_color;

    // If not found, try team.color direct property
    const teamColorDirect = option.team.color;

    // Return the first available color or default to white
    return teamColorFromMap || teamColorFromColorsArray || teamColorDirect || 'black';
  };

  return (
    <Stack spacing={2}>
      <Autocomplete
        disabled={!initialized}
        id="combo-box-demo"
        open={open}
        onOpen={openPopper}
        options={trackedEntitiesOptions}
        openOnFocus
        disableCloseOnSelect
        getOptionLabel={(option) => option.displayName}
        groupBy={(option) => option.team?.displayName.toUpperCase()}
        style={{ width: 350 }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => {
          const { key, tabIndex, ...restProps } = props;
          return (
            <React.Fragment key={key}>
              <Box
                {...restProps}
                sx={{
                  padding: 0,
                  margin: 0,
                  borderLeft: `3px solid ${getTeamColor(option)}`,
                  background: `linear-gradient(to right, ${getTeamColor(option)} 0%, transparent 90%)`,
                }}
                selected={Number(selectedId) === option.id}
                onClick={() => setTrackedEntity(option.id)}
              >
                {option.id !== 1 && (
                  <ListItemAvatar sx={{ padding: 0 }}>
                    <Avatar alt={option.displayName} src={option.headshot} />
                  </ListItemAvatar>
                )}

                <ListItemText
                  sx={{
                    padding: 0,
                    margin: 0, // Ensure no margin is applied to ListItemText
                    opacity: option.isActive ? 1 : 0.5,
                  }}
                  primary={
                    option.category === 'Object of play'
                      ? option.displayName
                      : `#${option.playerNumber} - ${option.displayName}`
                  }
                  secondary={option.playerPosition}
                />
              </Box>
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              {...params}
              id="input-with-sx"
              label="Search Player"
              placeholder="Search Player ..."
              variant="standard"
              size="small"
            />
          </Box>
        )}
        onInputChange={(event, value) => {
          handleSearchChange(value);
        }}
        onChange={(event, value, reason) => {
          switch (reason) {
            default:
              break;
          }
        }}
        onClose={closePopper}
        selectOnFocus
        handleHomeEndKeys
        filterOptions={(options: any[], params) => {
          const filtered = filter(options, params);

          return filtered;
        }}
      />
    </Stack>
  );
}
