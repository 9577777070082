import { GameModel } from "@/features/games/types";
import { TeamModel } from "../types/TeamModel";

export function getTeamColor(
    team: TeamModel,
    game: GameModel,
    fallbackColor: string = '#000000'
): string {
    // Early return if game is not defined
    if (!game) {
        return fallbackColor;
    }
    
    const { homeTeamColor, awayTeamColor, homeTeam, awayTeam } = game;

    // Check if homeTeam exists and team abbreviation matches homeTeam abbreviation
    if (homeTeam && team.abbreviation === homeTeam.abbreviation) {
        // If homeTeamColor is valid, return it
        if (homeTeamColor) {
            return homeTeamColor;
        }
        // Otherwise, fall through to team.teamColors check
    }

    // Check if awayTeam exists and team abbreviation matches awayTeam abbreviation
    if (awayTeam && team.abbreviation === awayTeam.abbreviation) {
        // If awayTeamColor is valid, return it
        if (awayTeamColor) {
            return awayTeamColor;
        }
        // Otherwise, fall through to team.teamColors check
    }

    // If we get here, either:
    // 1. Team didn't match home or away team
    // 2. Team matched but corresponding color was not defined
    
    // Check if team has teamColors
    if (!team.teamColors || team.teamColors.length === 0) {
        return fallbackColor;
    }

    // Find primary color or use fallback
    return team.teamColors.find((c) => c.type === 'primary')?.hex_color || fallbackColor;
}