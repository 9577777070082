
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { del } from '@truefit/http-utils';

const useCancelClipExportMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (clipId: number) => {
      await del(`/v1.0/clips/${clipId}/export`);
    },
    gcTime: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clips'] });
    }
  });
}

export default useCancelClipExportMutation;
