

import { Org } from '@/types/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';


export type UpdateUserGroupsModel = {
    userId?: number;
    groupIds?: number[];
    groupId?: number;
}

export const useUpdateUserGroupMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: UpdateUserGroupsModel): Promise<Org> => {
            if(!model.userId) {
                throw new Error('userId is required');
            }
            
            if(!model.groupIds && !model.groupId) {
                throw new Error('group(s) is required');
            }

            const response = await put<Org>(`/v1.0/users/${model.userId}/groups`, model);
            return response.data;
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['AllUsers'] })
    });
};

export default useUpdateUserGroupMutation;
