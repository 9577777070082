import React, { createContext, useContext, useMemo, useCallback, ReactNode } from 'react';
import { isUUID } from 'validator';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { parseISO } from 'date-fns';

import { GameModel } from '../types';
import toGameModel from '../services/toGameModel';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import { defaultGcTime, defaultStaleTime } from '@/shared/constants';
import { LocalSessionState, useAuth } from '@/features/scaffolding/components/AuthContext';
import useAutoIsoTeamSelectorState from '@/features/teams/context/useAutoIsoTeamSelectorState';

// Default game model when no data is available
const DEFAULT_GAME_MODEL: GameModel = {
  id: 0,
  name: '',
  status: '',
  leagueid: 0,
  isLive: false,
  teams: [],
  cameras: [],
  startTimestamp: null,
  endTimestamp: null
};

interface ApiResponse {
  data: GameModel;
}

// Define the context type
interface CurrentGameContextType {
  game: GameModel;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<any>;
  isFetching: boolean;
  gameId: string;
  gameLoaded: boolean;
  homeTeamPrimaryColor: string;
  awayTeamPrimaryColor: string;
}

// Create the context with default values
const CurrentGameContext = createContext<CurrentGameContextType>({
  game: DEFAULT_GAME_MODEL,
  isLoading: false,
  isError: false,
  error: null,
  refetch: async () => ({}),
  isFetching: false,
  gameId: '',
  gameLoaded: false,
  homeTeamPrimaryColor: '#000',
  awayTeamPrimaryColor: '#000'
});

// Query key generator for games
export const queryKey = (gameId: string) => ['Games', gameId] as const;

interface CurrentGameProviderProps {
  children: ReactNode;
}

export const CurrentGameProvider: React.FC<CurrentGameProviderProps> = ({ children }) => {
  const auth = useAuth();
  const gameId = useCurrentGameId();
  const queryClient = useQueryClient();

  
  // Game data query
  const {
    data: game = DEFAULT_GAME_MODEL,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
    status: gameQueryStatus
  } = useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => {
      if (!gameId || !isUUID(gameId)) {
        throw new Error('Invalid game ID');
      }
      
      const { data } = await get<ApiResponse>(`/v1.0/games/${gameId}`);
      return toGameModel(data);
    },
    retry: (failureCount, error) => {
      // Only retry for network errors, not for 404s or other client errors
      if (error instanceof AxiosError && error.response?.status >= 500) {
        return failureCount < 3;
      }
      return false;
    },
    staleTime: defaultStaleTime,
    gcTime: defaultGcTime,
    refetchOnWindowFocus: Boolean(gameId) && isUUID(gameId),
    enabled: Boolean(gameId) && isUUID(gameId) && auth.sessionState === LocalSessionState.READY,
    select: (data) => data ?? DEFAULT_GAME_MODEL
  });

    // Calculate the refetch interval based on the game's start time
  const calculateRefetchInterval = useCallback((startDate: Date): number => {
    const minutesUntilGame = (startDate.getTime() - Date.now()) / (1000 * 60);
    
    // Increasingly frequent polling as game time approaches
    if (minutesUntilGame > 1440) return 1000 * 60 * 60; // 1 hour if more than 24 hours away
    if (minutesUntilGame > 60) return 1000 * 60 * 10;   // 10 minutes if 1-24 hours away
    if (minutesUntilGame > 10) return 1000 * 60;        // 1 minute if 10-60 minutes away
    return 1000 * 3;                                    // 3 seconds if less than 10 minutes away
  }, []);

  // Game status polling for upcoming games
  const updateGameStatus = useCallback(async () => {
    if (!gameId || !isUUID(gameId) || Number.isNaN(game?.id)) return {};
    
    const { data } = await get<ApiResponse>(`/v1.0/games/${gameId}?polling=true`);
    return toGameModel(data);
  }, [gameId, game?.id]);

  // Determine if the game is scheduled and has a valid start time
  const startDate = useMemo(() => {
    if (!game?.startTimestamp) return null;
    try {
      return parseISO(game.startTimestamp);
    } catch (e) {
      return null;
    }
  }, [game?.startTimestamp]);

  // Game status polling query
  useQuery({
    queryKey: ['GamesStatus', gameId],
    queryFn: updateGameStatus,
    refetchOnWindowFocus: true,
    enabled: Boolean(gameId) && 
             isUUID(gameId) && 
             game?.status === 'scheduled' && 
             Boolean(startDate) &&
             auth.sessionState === LocalSessionState.READY,
    refetchInterval: startDate ? calculateRefetchInterval(startDate) : undefined,
    onSuccess: (data) => {
      // Update the main game data in the cache if status has changed
      if (data?.status !== game?.status) {
        queryClient.setQueryData(queryKey(gameId), data);
      }
    }
  });

  // Game switch query 
  const { status: switchGameStatus } = useQuery({
    queryKey: ['GameSwitch', gameId],
    queryFn: async () => {
      if (!gameId || !isUUID(gameId)) {
        throw new Error('Invalid game ID');
      }
      
      // Using the proper endpoint from your original implementation
      return await get<any>(`/v1.0/games/${gameId}/switch`);
    },
    retry: 2, // Retry twice (3 attempts total: initial + 2 retries)
    enabled: Boolean(gameId) && isUUID(gameId) && auth.sessionState === LocalSessionState.READY,
    refetchOnWindowFocus: false
  });

  // Calculate team colors
  const homeTeamPrimaryColor = useMemo(() => 
    game?.homeTeamColor
      ? game.homeTeamColor
      : game?.homeTeam?.teamColors
        ? game.homeTeam.teamColors.find((tc) => tc.type === 'primary')?.hex_color || '#000'
        : '#000',
    [game?.homeTeamColor, game?.homeTeam?.teamColors]
  );
  
  const awayTeamPrimaryColor = useMemo(() =>
    game?.awayTeamColor
      ? game.awayTeamColor
      : game?.awayTeam?.teamColors
        ? game.awayTeam.teamColors.find((tc) => tc.type === 'primary')?.hex_color || '#000'
        : '#000',
    [game?.awayTeamColor, game?.awayTeam?.teamColors]
  );

  // Determine if the game is fully loaded
  const gameLoaded = useMemo(
    () => gameQueryStatus === 'success' && switchGameStatus === 'success' && Boolean(game),
    [gameQueryStatus, switchGameStatus, game]
  );

  // Create the context value
  const contextValue = useMemo(() => ({
    game,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
    gameId,
    gameLoaded,
    homeTeamPrimaryColor,
    awayTeamPrimaryColor
  }), [
    game, 
    isLoading, 
    isError, 
    error, 
    refetch, 
    isFetching, 
    gameId, 
    gameLoaded, 
    homeTeamPrimaryColor, 
    awayTeamPrimaryColor
  ]);

  return (
    <CurrentGameContext.Provider value={contextValue}>
      {children}
    </CurrentGameContext.Provider>
  );
};

// Create a hook to use the current game context
export const useCurrentGame = () => {
  const context = useContext(CurrentGameContext);
  if (context === undefined) {
    throw new Error('useCurrentGame must be used within a CurrentGameProvider');
  }
  return context;
};

export default CurrentGameContext;