import { useMutation } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

const useDownloadConfigMutation = () =>
  useMutation({
    mutationFn: async (clipId: number) => {
      // Make the request
      const response = await get(`/v1.0/internals/queues/clip-export/${clipId}/cfg`);
      
      // Set a default filename
      const filename = `clip-${clipId}-config.json`;
      
      // Check if response.data is already a string, if not stringify it
      const jsonData = typeof response.data === 'string' 
        ? response.data 
        : JSON.stringify(response.data, null, 2); // Pretty print with indentation
      
      // Create a blob with the proper format
      const blob = new Blob([jsonData], { type: 'application/json' });
      
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.style.display = 'none'; // Hide the element
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
      
      return { success: true };
    },
    gcTime: 0,
  });

export default useDownloadConfigMutation;