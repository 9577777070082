import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { toClipModel } from '../services';
import {  ClipModelFromServer } from '../types';
import { getQueryKey } from './useCurrentGameClipsQuery';

const useAddClipMutation = () => {
  const queryClient = useQueryClient();
  const gameId = useCurrentGameId();

  return useMutation({
    mutationFn: async (model: any) => {
      const response = await post<ClipModelFromServer>(`/v1.0/games/${gameId}/clips`, model);
      return toClipModel(response.data);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getQueryKey(gameId) }),
  });
};

export default useAddClipMutation;
