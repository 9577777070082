import React from 'react';
import { Stack, FormGroup, TextField, FormControlLabel, Checkbox, Tooltip, Box } from '@mui/material';

import TeamAccordion from './TeamAccordion';
import { useAutoIsoTeamSelector } from '../context/AutoIsoTeamSelectorContext';

export const AutoISOTeamSelector: React.FC = () => {

  const {
    processedTeams,
    handleSearchChange,
    includeInactivePlayers,
    setIncludeInactivePlayers,
  } = useAutoIsoTeamSelector();


  return (
    <Stack spacing={3}>
      <Box sx={{ m: 3, p: 3 }}>
        <FormGroup>
          <TextField
            label="Search Player"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              handleSearchChange(e.target.value);
              e.stopPropagation();
            }}
            placeholder="Search by name or jersey number"
            sx={{ fontSize: '0.8rem' }}
          />
          <Tooltip title="Show inactive players outside the rink">
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeInactivePlayers}
                  onChange={() => setIncludeInactivePlayers((prev) => !prev)}
                />
              }
              label="Include inactive players"
            />
          </Tooltip>
        </FormGroup>
      </Box>
      <TeamAccordion teams={processedTeams} />
    </Stack>
  );
};

export default AutoISOTeamSelector;