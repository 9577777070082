import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FormValues as GamesFormValues } from '../components/GamesForm';
import { fetchGames } from '../services/fetchGames';
import { isInvalid } from '@/shared/services/isInvalid';

export const PAGE_SIZE = 10;

export function getQueryKey(formValues: GamesFormValues, page: number, pageSize: number) {
  return ['Games', { formValues, page, pageSize }];
}

export const useGamesQuery = (formValues: GamesFormValues, page: number = 0, pageSize: number) => {
  const queryClient = useQueryClient();

  const isQueryEnabled =
    !isInvalid(Number(formValues.leagueId), 'number') &&
    !isInvalid(Number(formValues.orgId), 'number');

  // Effect to remove invalid queries from the cache
  useEffect(() => {
    if (!isQueryEnabled) {
      queryClient.removeQueries({ queryKey: getQueryKey(formValues, page, pageSize) });
    }
  }, [formValues, page, isQueryEnabled, queryClient]);

  return useQuery({
    queryKey: getQueryKey(formValues, page, pageSize),
    queryFn: async () => {
      const { data } = await fetchGames({
        offset: page * pageSize,
        limit: pageSize,
        ...formValues
      });
      return data;
    },
    refetchOnWindowFocus: true,
    enabled: isQueryEnabled,
  });
};