import { defaultGcTime, defaultStaleTime } from '@/shared/constants';
import { isInvalid } from '@/shared/services/isInvalid';
import { UsersByGroups } from '@/types/user';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

export function getQueryKey(orgId: number) {
  return ['AllUsers', orgId];
}

export const useAllUsersQuery = (orgId?: number, opt?: any) =>
  useQuery({
    queryKey: getQueryKey(orgId as number),
    queryFn: async () => {
      const { data } = await get<UsersByGroups[]>(`/v1.0/users/list`);
      return data;
    },

    enabled: !isInvalid(orgId, 'number'),
    staleTime: defaultStaleTime, // Data considered fresh for 30 seconds
    gcTime: defaultGcTime,  // Data will be garbage collected after 5 minutes
    refetchOnWindowFocus: !isInvalid(orgId, 'number'), // refetch ONLY if enabled and orgId is valid
    ...opt
  });

export default useAllUsersQuery;
