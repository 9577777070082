import { AxiosInstance, configureHttp as configure } from '@truefit/http-utils';

import { apiGatewayUrl } from '../shared/constants';
import { defaultErrorHandler, handleApiDatabaseErrors, handleAuthErrors } from './errorHandlers';


function startsWithArrayElement(url, arr) {
  if (url === undefined) return false;
  return arr.some((element) => url.startsWith(element));
}

function endsWithArrayElement(url, arr) {
  if (url === undefined) return false;
  return arr.some((element) => url.endsWith(element));
}

const useApiGateway = (url: string, method: string) =>
  [
    { url: 'stream', method: 'get' },
    { url: 'tracking/entity', method: 'put' },
    { url: 'tracking/point', method: 'put' },
    { url: 'cameras/view', method: 'put' },
  ].some((endpoint) => url.startsWith(endpoint.url) && endpoint.method === method);

const getAutoIsoAndMessageBusEndpoint = (path?: string): string => {
  const gatewayUrl = new URL(apiGatewayUrl);
  gatewayUrl.pathname = path ?? '/';
  return gatewayUrl.toString();
};



// we can add a matching regex here to exclude certain endpoints from the interceptor
export const getBusinessApiEndPoint = (url?: string, version: string = '1.0'): string => {
  const gatewayUrl = new URL(apiGatewayUrl);
  gatewayUrl.pathname = `/business-api`;

  if (
    startsWithArrayElement(url, ['config', 'antiforgery-token', 'surface', 'documentation']) ||
    endsWithArrayElement(url, ['logo', 'io', 'common-io'])
  ) {
    gatewayUrl.pathname = `/unrestricted`
  }
  return gatewayUrl.toString();
};

export type HttpConfiguration = {
  path?: string;
  jwt?: string;
  identityToken?: string;
  handler?: string;
};

export function configureHttp(httpConfig: HttpConfiguration) {
  const { jwt, path, identityToken } = httpConfig;

  const baseHeaders = {};
  if (jwt) {
    // @ts-ignore
    baseHeaders.Authorization = `Bearer ${jwt}`;
  }
  if (identityToken) {
    // @ts-ignore
    baseHeaders.CognitoIdentityToken = identityToken;
  }

  configure({
    baseConfig: {
      baseURL: getBusinessApiEndPoint(),
    },
    baseHeaders,
    configureInstance: (instance: AxiosInstance) => {
      // Response interceptor with chain of responsibility pattern
      instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          // Try each error handler in sequence
          const handlers = [
            handleAuthErrors,
            handleApiDatabaseErrors,
            defaultErrorHandler
          ];

          for (const handler of handlers) {
            const result = handler(error);
            if (result !== null) {
              return result;
            }
          }

          // If somehow no handler returned a value (shouldn't happen with defaultErrorHandler)
          return Promise.reject(error);
        }
      );

      instance.interceptors.request.use((config) => {
        const gpuEndpoint = getAutoIsoAndMessageBusEndpoint(`v1.0/${path}`);
        const bapiEndpoint = getBusinessApiEndPoint(`v1.0/${config.url}`);

        if (!bapiEndpoint.includes('unrestricted')) {
          // Check if required header is missing
          if (!config.headers?.Authorization) {
            return Promise.reject(new Error('Missing Authorization header, cancelling request'));
          }
        }
        return {
          ...config,
          baseURL: useApiGateway(config.url, config.method)
            ? gpuEndpoint
            : bapiEndpoint,
        };
      });
    },
  });
}
