import useAutoIsoEntitiesQuery from '@/features/autoIso/hooks/useAutoIsoEntitiesQuery';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { Avatar, Box, Chip, ListItemAvatar, ListItemText, Stack } from '@mui/material';
import { useMemo } from 'react';
import notFoundLogo from '@/assets/not-found.jpg';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { ClipModel } from '@/features/clips/types';

const chipStyle = {
  height: '40px', // Increase height (default is 32px)
  fontSize: '1rem', // Increase font size
  padding: '24px 16px',
  '& .MUIChip-avatar': {
    // Make the avatar bigger
    width: 32,
    height: 32,
    marginLeft: '5px',
  },
  '& .MUIChip-label': {
    // Add more padding to the label
    padding: '0 16px',
  },
  borderRadius: '4px', // Adjust border radius if needed
};

const TrackedObject = ({
  clip,
  teams,
  sx,
  readOnly,
  onClick,
}: {
  clip: ClipModel;
  teams?: any[];
  sx?: any;
  readOnly: boolean;
  onClick?: () => void;
}) => {
  const autoIsoQuery = useAutoIsoEntitiesQuery();
  const gameTypeMetadata = useGameTypeMetadataQuery();

  const autoIsoEntities = autoIsoQuery.data?.list;

  const trackedObject = autoIsoEntities?.find((entity) => entity?.id === clip.objectTrackingId);

  const getTeamLogo = useMemo(() => {
    if (teams) {
      if (trackedObject?.type === 'Player' && trackedObject.playerTeamId) {
        const team = teams.find((t) => t.id === trackedObject.playerTeamId);
        if (team) return getTeamLogoPath(team);
      }
    }
    return notFoundLogo;
  }, [trackedObject, teams]);

  const trackedEntity = useMemo(() => {
    if (!clip?.objectTrackingId) return '-';

    if (clip.objectTrackingId === 1) {
      return gameTypeMetadata.data?.objectOfPlay;
    }

    if (trackedObject?.type === 'Player') {
      return `${trackedObject.playerNumber} - ${trackedObject.displayName} (${trackedObject.playerPosition})`;
    }

    return `${trackedObject?.displayName}`;
  }, [clip.objectTrackingId, trackedObject, gameTypeMetadata.data?.objectOfPlay]);

  return (
    <>
      {readOnly && !!clip.objectTrackingId && trackedObject && (
        <Stack
          sx={{ ...sx, padding: 0, margin: 0 }}
          onClick={onClick}
          direction={'row'}
        >
          {trackedObject.id !== 1 && (
            <ListItemAvatar sx={{ padding: 0 }}>
              <Avatar alt={trackedObject.displayName} src={trackedObject.headshot} />
            </ListItemAvatar>
          )}

          <ListItemText
            sx={{
              padding: 0,
              margin: 0, // Ensure no margin is applied to ListItemText
            }}
            primary={
              trackedObject.id === 1
                ? trackedObject.displayName
                : `#${trackedObject.playerNumber} - ${trackedObject.displayName}`
            }
            secondary={trackedObject.playerPosition}
          />
        </Stack>
      )}
    </>
  );
};

export { TrackedObject };
export default TrackedObject;
