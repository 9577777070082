import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';
import { GameModel } from '../types';

// Define the type for game update
interface UpdateGameDetails {
    status?: 'recorded' | 'live' | 'scheduled';
    startTimestamp?: string;
    endTimestamp?: string;
    enableTracking?: boolean;
    trackingId?: string | null;
    trackingOffset?: string;
    enableAudio?: boolean;
    audioOffset?: string;
    camspecs?: string;
    streamId?: string;
    uniqueId: string;
}


const useUpdateGameDetails = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: UpdateGameDetails) => {
            const modelToUpdate = { ...model };
            if (typeof model.camspecs === 'object') {
                modelToUpdate.camspecs = JSON.stringify(model.camspecs);
            }

            // First get the response
            return await put<GameModel>(`/v1.0/games/${model.uniqueId}`, modelToUpdate);

        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Games'] }),
        onError: (error: any) => {
            return error;
        },
    });
};

export default useUpdateGameDetails;