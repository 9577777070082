import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const GameToggle = ({ game, onToggle }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirm = async () => {
    setConfirmDialogOpen(false);
    setLoading(true);
    await onToggle(!game.available, game);
    setLoading(false);
  };

  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };

  const actionText = game.available ? 'Disable' : 'Enable';
  const tooltipText = `${actionText} ${game.name || 'this game'}`;

  return (
    <>
      <Tooltip title={tooltipText} arrow>
        <IconButton
          onClick={handleButtonClick}
          color={game.available ? 'success' : 'error'}
          loading={loading}
        >
          {game.available ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
        </IconButton>
      </Tooltip>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancel}
        slotProps={{
          backdrop: {
            sx: { backdropFilter: 'blur(4px)' },
          },
        }}
      >
        <DialogTitle>Confirm Game Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {game.available ? 'disable' : 'enable'}{' '}
            {game.name || 'this game'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color={game.available ? 'error' : 'primary'}
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameToggle;
