import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patch } from '@truefit/http-utils';
import { Org } from '@/types/user';

export type OrgSchedule = {
    orgId?: number;
    hourOfTheDay: number;
    availableInstances: number;
}

const useApplyOrganizationInstanceSchedulingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            return await patch<Org>(`internals/mgmt/instance/planning`, {});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['org'] });
        },
    });
};

export default useApplyOrganizationInstanceSchedulingMutation;
