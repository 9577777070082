import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { AutoIsoId, AutoIsoModel, AutoIsoObjectType, TrackingRegistrationModel } from '../types';

export const getQueryKey = (gameId: string) => ['Games', gameId, 'AutoIsoLookup'];
export const DEFAULT_DATA: { list: AutoIsoModel[]; lookup: Record<AutoIsoId, AutoIsoModel> } & Pick<
  TrackingRegistrationModel,
  'homeTeam' | 'awayTeam'
> = {
  list: [{ type: AutoIsoObjectType.BallOrPuck, displayName: 'BallOrPuck', id: 'ballPuck', pos: { x: 0, y: 0, z: 0 } }],
  lookup: {
    ballPuck: {
      displayName: '',
      id: 'ballPuck',
      type: AutoIsoObjectType.BallOrPuck,
      pos: { x: 0, y: 0, z: 0 },
    },
  },
  homeTeam: { id: 'home', displayName: '', abbreviation: '' },
  awayTeam: { id: 'away', displayName: '', abbreviation: '' },
};

const useAutoIsoEntitiesQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: getQueryKey(gameId),
    queryFn: async () => {
      const response = await get<TrackingRegistrationModel>(`/v1.0/games/${gameId}/registration`);
      if (!response.data) return DEFAULT_DATA;
      return {
        awayTeam: response.data.awayTeam,
        homeTeam: response.data.homeTeam,
        list: response.data.autoIsoEntities,
        lookup: response.data.autoIsoEntities.reduce<Record<AutoIsoId, AutoIsoModel>>(
          (lookup, entity) => ({
            ...lookup,
            [entity.id]: entity,
          }),
          {},
        ),
      };
    },
    enabled: !!gameId,
    staleTime:  60 * 1000,
    gcTime  : 60 * 1000,
    placeholderData: DEFAULT_DATA,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: 60 * 1000,
    retryOnMount: true,
  });
};

export default useAutoIsoEntitiesQuery;
