import { differenceInSeconds, format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { indigo, green } from '@mui/material/colors';

import {
  Box,
  CollapseProps,
  Divider,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import useCognito from '../../authentication/hooks/useCognito';
import { PANEL_PADDING } from '../../games/constants';
import { ClipModel, isClip } from '../types';
import ClipRecordContextMenu from './ClipRecordContextMenu';
import ClipRecordForm from './ClipRecordForm';

import DisplayTags from '@/features/tags/components/DisplayTags';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import displayWallClock from '@/shared/services/displayWallClock';
import ExpandToggleButton from '@/shared/components/ExpandToggleButton';

import ClipStatus from '@/shared/components/ClipStatus';
import { TeamModel } from '@/features/teams/types/TeamModel';
import EnhancedCollapse from './EhancedCollapse';

type Props = {
  clip: ClipModel;
  isFirst: boolean;
  teams?: TeamModel[];
  collapseProps?: CollapseProps;
  downloadCliphandler: (clip: ClipModel) => Promise<void>; // Make it async
} & {
  // Add additional prop validations
  'aria-label'?: string;
  'data-testid'?: string;
};

const isValidTimestamp = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

const getDuration = (start: Date, end: Date): string => {
  if (!isValidTimestamp(start) || !isValidTimestamp(end)) {
    return '';
  }

  try {
    const diffInSeconds = differenceInSeconds(end, start);
    if (diffInSeconds < 0) {
      console.warn('End date is before start date');
      return '';
    }

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.floor(diffInSeconds % 60);

    return [hours, minutes, seconds].map((unit) => unit.toString().padStart(2, '0')).join(':');
  } catch (error) {
    console.error('Error calculating duration:', error);
    return '';
  }
};

const listItemTextStyles = {
  flex: 1,
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
  },
  '& .MuiListItemText-secondary': {
    color: 'text.disabled',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  '&::first-letter': {
    textTransform: 'uppercase',
  },
};

const ClipRecord = ({ clip, isFirst, teams = [], downloadCliphandler }: Props) => {
  const { state, handleSubmit, handleCancelEditing, handleSetClipRecordRef } =
    useClipStateContext();
  const serverState = useServerStateContext();
  const cognito = useCognito();

  const clipRecordRef = useRef<HTMLElement>(null);


  const editing = state.editingModel?.id === clip.id;
  const isEditingSomeOtherClip = state.isEditing && !editing;
  const isOwnClip = clip.clouduser?.email === cognito.userAttributes?.email;
  const cameras = Object.values(serverState?.availableCameras || {});

  const [expanded, setExpanded] = useState(false);
  const [displayDescription, setDisplayDescription] = useState(!!clip.description && !editing);
  const [clipDescription, setClipDescription] = useState(clip.description);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    handleSetClipRecordRef(clip.id, clipRecordRef);
  }, [clip.id, handleSetClipRecordRef]);


  const handleError = (error: Error) => {
    console.error('Error in ClipRecord:', error);
    // Implement error reporting logic
  };

  // Secondary content with timer icon and appropriate time display
  const secondaryContent = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        color: isClip(clip) ? green[400] : indigo[300],
      }}
    >
      {isClip(clip)? <AccessAlarmsIcon fontSize="small" />:<BookmarkBorderOutlinedIcon fontSize="small" />} 
      {isClip(clip)
        ? ` ${displayWallClock(clip.startTimestamp)} - ${displayWallClock(clip.endTimestamp)}`
        : ` ${displayWallClock(clip.startTimestamp)} `}
    </Box>
  );

  // ListItemText component used in both cases
  const itemText = (
    <ListItemText sx={listItemTextStyles} primary={clip.note} secondary={secondaryContent} />
  );

  const handleEditSubmit = async (values: ClipModel) => {
    try {
      await handleSubmit(values);
      setClipDescription(values.description);
      setDisplayDescription(values.description.length > 0);
    } catch (error) {
      handleError(error as Error);
    }
  };

  const getCameraNameByCameraId = React.useCallback(
    (cameraId: number) => {
      const camera = cameras.find((c) => c.idx === cameraId);
      if (camera) return camera.name;
      return 'Multi Camera';
    },
    [cameras],
  );

  const clipDuration = useMemo(() => {
    if (editing) {
      return getDuration(
        state.editingModel?.startTimestamp as Date,
        state.editingModel?.endTimestamp as Date,
      );
    }
    return getDuration(clip.startTimestamp, clip.endTimestamp as Date);
  }, [
    clip.startTimestamp,
    clip.endTimestamp,
    state.editingModel?.startTimestamp,
    state.editingModel?.endTimestamp,
  ]);

  return (
    <Stack>
      {!isEditingSomeOtherClip && (
        <Stack
          ref={clipRecordRef as React.RefObject<HTMLDivElement>}
          sx={(theme) => ({
            cursor: 'pointer',
            backgroundColor: editing ? theme.palette.background.default : undefined,
            opacity: isEditingSomeOtherClip ? 0.5 : 0.8,
            '&:hover': {
              opacity: 1,
            },
          })}
          onMouseEnter={() => setMenuVisible(true)}
          onMouseLeave={() => setMenuVisible(false)}
        >
          {!isFirst && !state.isEditing && <Divider />}

          <Stack ml={PANEL_PADDING - 1} mr={1.5}>
            <Stack direction="column" mt={1}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ flex: 1, marginBottom: 1, padding: 0.2 }}
              >
                {!state.isEditing && (
                  <ExpandToggleButton
                    expanded={expanded}
                    onClickHandler={() => setExpanded(!expanded)}
                    disabled={isEditingSomeOtherClip}
                  />
                )}
                {isClip(clip) ? (
                  <Tooltip title={clipDuration} placement="top-start">
                    {itemText}
                  </Tooltip>
                ) : (
                  itemText
                )}
                {isClip(clip) && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', paddingLeft: 1, paddingTop: 0.7 }}
                  >
                    <ClipStatus status={clip.status} />
                  </Box>
                )}
                {!state.isEditing && (
                  <ClipRecordContextMenu
                    visible={menuVisible}
                    clip={clip}
                    exportClickHandler={() => setExpanded(true)}
                    downloadCliphandler={() => downloadCliphandler(clip)}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <EnhancedCollapse
            clip={clip}
            expanded={expanded}
            isEditingSomeOtherClip={isEditingSomeOtherClip}
            clipDuration={clipDuration}
            clipDescription={clipDescription}
            displayDescription={displayDescription}
            teams={teams}
            getCameraNameByCameraId={(id) => getCameraNameByCameraId(id)}
          >
            {' '}
          </EnhancedCollapse>

          <Stack>
            {editing && (
              <Box p={2}>
                <ClipRecordForm
                  teams={teams}
                  clip={clip}
                  onCancel={handleCancelEditing}
                  onSubmit={handleEditSubmit}
                />
              </Box>
            )}
            {!editing && (
              <Box px={1.6}>
                <DisplayTags clip={clip} readonly={!isOwnClip || isEditingSomeOtherClip} />
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ClipRecord;
