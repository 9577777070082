import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { isUUID } from 'validator';

export const queryKey = ['GameSwitch'] as const;

const useGameSwitchQuery = () => {

  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: [...queryKey, gameId],
    queryFn: async () => {
      if (!gameId || !isUUID(gameId)) {
        throw new Error('Invalid game ID');
      }

      return await get<any>(`/v1.0/games/${gameId}/switch`);
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });
};

export default useGameSwitchQuery;