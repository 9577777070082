import { Group } from "@/types/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { put } from "@truefit/http-utils";

interface ApiError {
    message: string;
    status: number;
}

export const useUpdateGroupMutation = (orgId: number) => {
    const queryClient = useQueryClient();

    return useMutation<Group, ApiError, Group>({
        mutationFn: (data: Group) =>
            put<Group>(`/v1.0/org/${orgId}/groups/${data.id}`, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['groups', orgId] });
        },
        onError: (error) => {
            // Handle error appropriately
            console.error('Failed to create group:', error);
        }
    });
};

export default useUpdateGroupMutation;