import React from 'react';
import {
  Button,
  Stack,
  Typography,
  Card,
  CardContent,
  CardActions,
  Chip,
  Container,
  Divider,
  Grid,
  CardHeader,
} from '@mui/material';

import {Plus, ShieldCheck,Trash2, Pencil } from 'lucide-react'


import { AddGroupDialog } from './dialogs/AddGroupDialog';
import { EditGroupDialog } from './dialogs/EditGroupDialog';
import { DeleteGroupDialog } from './dialogs/DeleteGroupDialog';
import { AssignRolesDialog } from './dialogs/AssignRolesDialog';
import { useGroupManagement } from './hooks/useGroupManagement';
import { LoadingSpinner } from '@/shared/components/LoadingSpinner';
import { Group } from '@/types/user';
import { AppPage } from '@/shared/components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

// Helper function to extract roles from grouproles
const getRolesFromGroup = (group: Group) => {
  return group.grouproles.map((gr) => gr.role).sort((a, b) => a.name.localeCompare(b.name));
};

// Helper function to extract role IDs from grouproles
const getRoleIdsFromGroup = (group: Group) => {
  return group.grouproles.map((gr) => gr.role.id);
};

export const GroupManagement: React.FC = () => {
  const { state, groups, roles, isLoading, handlers } = useGroupManagement();
  const title = 'Groups Management';

  useDocumentTitle(title);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AppPage title={title} requiredPermissions={['menu:manage:groups']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Manage Groups</Typography>
        <Typography variant="body1">Group/Roles Management</Typography>
        <Divider sx={{ marginTop: 3 }} />
        <Stack direction="row" justifyContent="right" alignItems="flex-end" spacing={2} p={3}>
          <Button
            startIcon={<Plus />}
            variant="contained"
            onClick={() => handlers.setAddDialogOpen(true)}
          >
            Add Group
          </Button>
        </Stack>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            {groups.map((group) => (
              <Grid item xs={12} md={6} key={group.id}>
                <Card>
                  <CardHeader title={group.name} subheader={group.description} />

                  <CardContent sx={{ minHeight: 200 }}>
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      sx={{
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      {getRolesFromGroup(group).map((role) => (
                        <Chip
                          key={role.id}
                          label={role.name}
                          size="small"
                          icon={<ShieldCheck />}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <Button
                      startIcon={<Pencil />}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        const groupWithRoleIds = {
                          ...group,
                          roleIds: getRoleIdsFromGroup(group),
                        };
                        handlers.setSelectedGroup(groupWithRoleIds);
                        handlers.setEditDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<ShieldCheck />}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        const groupWithRoleIds = {
                          ...group,
                          roleIds: getRoleIdsFromGroup(group),
                        };
                        handlers.setSelectedGroup(groupWithRoleIds);
                        handlers.setAssignRolesDialogOpen(true);
                      }}
                    >
                      Manage Roles
                    </Button>
                    <Button
                      startIcon={<Trash2 />}
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handlers.setSelectedGroup(group);
                        handlers.setDeleteDialogOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <AddGroupDialog
          open={state.addDialogOpen}
          onClose={() => handlers.setAddDialogOpen(false)}
          onSubmit={handlers.handleCreateGroup}
          availableRoles={roles}
        />

        <EditGroupDialog
          open={state.editDialogOpen}
          group={state.selectedGroup}
          onClose={() => handlers.setEditDialogOpen(false)}
          onSubmit={handlers.handleUpdateGroup}
          availableRoles={roles}
        />

        <DeleteGroupDialog
          open={state.deleteDialogOpen}
          group={state.selectedGroup}
          onClose={() => handlers.setDeleteDialogOpen(false)}
          onConfirm={handlers.handleDeleteGroup}
        />

        <AssignRolesDialog
          open={state.assignRolesDialogOpen}
          group={state.selectedGroup}
          roles={roles}
          onClose={() => handlers.setAssignRolesDialogOpen(false)}
          onSubmit={handlers.handleAssignRoles}
          selectedRoleIds={state.selectedGroup ? getRoleIdsFromGroup(state.selectedGroup) : []}
        />
      </Container>
    </AppPage>
  );
};
