import { parseISO } from 'date-fns';
import { useState, useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
// Framer Motion removed as requested

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { GameModel } from '../types';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { formatStartTime } from '@/shared/services/formatStart';
import { getDuration } from '@/shared/services/getDuration';
import { LiveIndicator } from './LiveIndicator';
import { UpcomingIndicator } from './upcomingIndicator';
import './styles/halftone.css';
import { TeamModel } from '@/features/teams/types/TeamModel';
import { generateGradient } from '../services/generateGradient';
import { LocalSessionState, useAuth } from '@/features/scaffolding/components/AuthContext';

type Props = {
  game: GameModel;
  cardWidth: number;
  cardHeight: number;
};

const SCALE = 1;

const calculateRefetchInterval = (startDate: Date): number => {
  const minutesUntilGame = (new Date(startDate).getTime() - Date.now()) / (1000 * 60);
  if (minutesUntilGame > 1440) return 1000 * 60 * 60; // 1 hour
  if (minutesUntilGame > 60) return 1000 * 60 * 10; // 10 minutes
  if (minutesUntilGame > 10) return 1000 * 60; // 1 minute
  return 1000 * 3; // 3 seconds
};

// Animation variants removed

const GameCard = ({ game, cardWidth, cardHeight }: Props) => {
  const auth = useAuth();
  const { userProfile } = useContext(UserStateContext);

  const start = parseISO(game.startTimestamp);
  const end = game.endTimestamp ? parseISO(game.endTimestamp) : undefined;

  const [isHovered, setIsHovered] = useState(false);

  const gameStatus = useMemo(() => game.status || 'recorded', [game.status]);
  const isClickable = useMemo(() => ['live', 'recorded'].includes(gameStatus) && auth.sessionState === LocalSessionState.READY, [gameStatus,auth.sessionState]);
  const formattedStartDate = useMemo(
    () => formatStartTime(start, userProfile?.tz || 'America/New_York'),
    [start, userProfile?.tz],
  );

  const homeTeamPrimaryColor = useMemo(
    () =>
      game.homeTeamColor
        ? game.homeTeamColor
        : game.homeTeam?.teamColors
          ? game.homeTeam.teamColors.find((tc) => tc.type === 'primary')?.hex_color
          : '#000',
    [game.homeTeam.teamColors],
  );
  const awayTeamPrimaryColor = useMemo(
    () =>
      game.awayTeamColor
        ? game.awayTeamColor
        : game.awayTeam?.teamColors
          ? game.awayTeam.teamColors.find((tc) => tc.type === 'primary')?.hex_color
          : '#000',
    [game.awayTeam.teamColors],
  );

  const updateGameStatus = useCallback(async () => {
    if (Number.isNaN(game.id)) return {};
    const { data } = await get<GameModel>(`/v1.0/games/${game.uniqueId}?polling=true`);
    return data;
  }, [game.id, game.uniqueId]);

  // Check if game starts within the next hour
  const isWithinNextHour = useMemo(() => {
    const now = new Date();
    const oneHourFromNow = new Date(now.getTime() + 1 * 60 * 60 * 1000);
    return start <= oneHourFromNow && start >= now;
  }, [start]);

  useQuery({
    queryKey: ['GamesStatus', game.id],
    queryFn: updateGameStatus,
    refetchOnWindowFocus: true,
    enabled: gameStatus === 'scheduled' && isWithinNextHour,
    refetchInterval: calculateRefetchInterval(start),
  });

  const renderTeamInfo = useCallback(
    (team: TeamModel, idx: number) => (
      <Box key={team.abbreviation} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          ml: idx === 0 ? 2 : 0,
          mr: idx === 1 ? 2 : 0
        }}>
          {/* Logo and score row */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: idx === 0 ? 'row' : 'row-reverse',
              width: '100%'
            }}
          >
            <Box
              component="img"
              src={getTeamLogoPath(team)}
              alt={team.abbreviation}
              sx={{ width: 100 / SCALE, height: 100 / SCALE }}
            />
            
            {gameStatus !== 'scheduled' && (
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                  ml: idx === 0 ? 2 : 0,
                  mr: idx === 1 ? 2 : 0,
                  color:
                    idx === 0
                      ? game.awayTeamScore >= game.homeTeamScore
                        ? 'white'
                        : `rgba(255, 255, 255, 0.6)`
                      : game.homeTeamScore >= game.awayTeamScore
                        ? 'white'
                        : `rgba(255, 255, 255, 0.6)`,
                }}
              >
                {idx === 0 ? game.awayTeamScore : game.homeTeamScore}
              </Typography>
            )}
          </Box>
          
          {/* Team abbreviation */}
          <Typography sx={{ fontWeight: 'bold', textAlign: 'center', mt: 1 }}>
            {team.abbreviation}
          </Typography>
        </Box>
      </Box>
    ),
    [game.awayTeamScore, game.homeTeamScore, gameStatus, isHovered],
  );

  const gradientBackground = useMemo(() => {
    const gradient = generateGradient(homeTeamPrimaryColor, awayTeamPrimaryColor, 0.8, 7);
    return gradient;
  }, [homeTeamPrimaryColor, awayTeamPrimaryColor]);

  return (
    <Link to={isClickable ? `/games/${game.uniqueId}` : `#`} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          position: 'absolute',
          width: `${cardWidth / SCALE}px`,
          height: `${cardHeight / SCALE}px`,
          background: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'grayscale(0.5) blur(5px)',
          zIndex: 1,
          display: auth.sessionState === LocalSessionState.READY ? 'none' : 'block',
        }}
      />
      <Box>
        <Card
          sx={{
            width: `${cardWidth / SCALE}px`,
            height: `${cardHeight / SCALE}px`,
            display: 'flex',
            flexDirection: 'column',
            background: gradientBackground,
            position: 'relative',
            opacity: gameStatus === 'scheduled' ? 0.6 : 0.9,
            filter: gameStatus === 'scheduled' ? 'grayscale(0.8)' : 'none',
            cursor: isClickable ? 'pointer' : 'default',
            pointerEvents: isClickable ? 'auto' : 'none',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box
            sx={{
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.3s'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(0, 0, 0, 0.3)',
                padding: 1,
                position: 'absolute',
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(5px)',
                zIndex: 2
              }}
            >
              <Box>
                <Typography sx={{ color: 'white', fontWeight: 'bold', textTransform: 'uppercase' }}>
                  {gameStatus === 'live' ? 'join game' : 'watch game'}
                </Typography>
              </Box>
            </Box>
          </Box>
          <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{formattedStartDate}</Typography>
              {gameStatus === 'live' && <LiveIndicator color="white" />}
              {gameStatus === 'recorded' && getDuration(start, end)}
              {gameStatus === 'scheduled' && <UpcomingIndicator color={''} />}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              {[game.awayTeam, game.homeTeam].map((team, idx) => renderTeamInfo(team, idx))}
            </Box>
          </CardContent>
        </Card>
                    </Box>
    </Link>
  );
};

export default GameCard;