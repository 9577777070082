import * as React from 'react';

import FitScreenRoundedIcon from '@mui/icons-material/FitScreenRounded';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ViewType } from '@/features/camera/types/VideoSource';
import useServerStateContext from '@/shared/hooks/useServerStateContext';

const PlayerCameraFeedSelectionButton = () => {
  const { availableCameras, selectCamera, selectedCameraId, viewType } = useServerStateContext();
  const singleCameraSelected = viewType === ViewType.SINGLE_CAMERA;

  const selectedCamera = availableCameras.find((camera) => camera.idx === selectedCameraId);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  
  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <IconButton
          size="small"
          aria-controls={open ? 'camera-feed-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{ 
            color: singleCameraSelected ? 'text.primary' : 'text.secondary',
            mr: 1
          }}
        >
          {singleCameraSelected && (
            <VideocamTwoToneIcon
              fontSize="small"
              color={selectedCameraId === selectedCamera?.idx ? 'error' : undefined}
              style={{
                transform: `rotate(${-selectedCamera?.angle}deg)`,
              }}
            />
          )}

          {!singleCameraSelected && <FitScreenRoundedIcon fontSize="small" />}
        </IconButton>
        
        {singleCameraSelected && selectedCamera && (
          <Typography 
            variant="body2"
            sx={{ 
              color: singleCameraSelected ? 'text.primary' : 'text.secondary',
              flexGrow: 1
            }}
          >
            {selectedCamera.name}
          </Typography>
        )}
        
        {!singleCameraSelected && (
          <Typography 
            variant="body2"
            sx={{ color: 'text.secondary', flexGrow: 1 }}
          >
            Quad View
          </Typography>
        )}
      </Box>
      
      <Menu
        disablePortal
        anchorEl={anchorEl}
        id="camera-feed-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 24,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={(theme) => ({
          // Make sure the menu shows above the tooltip for the button
          zIndex: theme.zIndex.tooltip + 1,
        })}
      >
        {Array.isArray(availableCameras) &&
          availableCameras.map((camera) => (
            <MenuItem
              key={camera.id}
              onClick={() => selectCamera(camera.idx, 'Video Player Toolbar')}
              disabled={!camera.enabled}
            >
              <ListItemIcon>
                <VideocamTwoToneIcon
                  fontSize="small"
                  color={selectedCameraId === camera.idx ? 'error' : undefined}
                  style={{
                    transform: `rotate(${-camera.angle}deg)`,
                  }}
                />
              </ListItemIcon>
              {camera.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default PlayerCameraFeedSelectionButton;