import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import TimeMachineStatusProvider from '@/features/timeMachine/components/TimeMachineStatusProvider';
import { ExtendSessionDialog } from '@/shared/components';
import { LocalSessionState, useAuth } from './AuthContext';
import { AuthProvider } from './AuthContext';
import UserStateProvider from '@/shared/components/UserStateProvider';

type Props = {
  children: ReactNode;
};

const AuthenticateRoute = ({ children }: Props) => {
  const { sessionState, isExpiringSession, setIsExpiringSession } = useAuth();

  if (sessionState === LocalSessionState.NO_LOCAL_SESSION) {
    return <Navigate to={{ pathname: '/sign-in' }} />;
  }

  return (
    <AuthProvider>
      <UserStateProvider>
        <TimeMachineStatusProvider>
          <ExtendSessionDialog
            open={isExpiringSession}
            onClose={() => setIsExpiringSession(false)}
          />
          {children}
        </TimeMachineStatusProvider>
      </UserStateProvider>
    </AuthProvider>
  );
};

export default AuthenticateRoute;
