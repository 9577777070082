import useCurrentOrgQuery from "./useCurrentOrgQuery";
import { useAddUserToDepartmentMutation } from "@/features/department/hooks/useAddUserToDepartmentMutation";
import { useRemoveUserFromDepartmentMutation } from "@/features/department/hooks/useRemoveUserFromDepartmentMutation";
import { useAddDepartmentToOrgMutation } from "@/features/department/hooks/useAddDepartmentToOrgMutation";
import { useRemoveDepartmentFromOrgMutation } from "@/features/department/hooks/useRemoveDepartmentFromOrgMutation";
import useEditDepartmentMutation from "@/features/department/hooks/useEditDepartmentMutation";
import useUpdateOrganizationInstanceSchedulingMutation from "@/features/org/hooks/useUpdateOrganizationInstanceSchedulingMutation";
import useApplyOrganizationInstanceSchedulingMutation from "@/features/org/hooks/useApplyOrganizationInstanceSchedulingMutation";

const useCurrentOrgState = () => {
    const { status, data: currentOrgQueryData, refetch , isLoading} = useCurrentOrgQuery();

   

    const removeUserFromDepartmentMutation = useRemoveUserFromDepartmentMutation();
    const addUserToDepartmentMutation = useAddUserToDepartmentMutation();
    const removeDepartmentFromOrgMutation = useRemoveDepartmentFromOrgMutation();
    const addDepartmentToOrgMutation = useAddDepartmentToOrgMutation();
    const editDepartmentMutation = useEditDepartmentMutation();
    const updateSchedulingMutation = useUpdateOrganizationInstanceSchedulingMutation();
    const applyScheduleMutation = useApplyOrganizationInstanceSchedulingMutation();

    const addUserToDepartment = async (departmentId: number, userId: number) => {
        addUserToDepartmentMutation.mutate({ departmentId, userId });
    }

    const removeUserFromDepartment = async (departmentId: number, userId: number) => {
        removeUserFromDepartmentMutation.mutate({ departmentId, userId });
    }

    const addDepartmentToOrg = async (name: string) => {
         addDepartmentToOrgMutation.mutate({ name, orgId: currentOrgQueryData?.data?.id as number });
    }

    const removeDepartmentFromOrg = async (departmentId: number) => {
         removeDepartmentFromOrgMutation.mutate({ departmentId, orgId: currentOrgQueryData?.data?.id  as number});
    }

    const editDepartment = async (departmentId: number, name: string) => {
        editDepartmentMutation.mutate({ departmentId, name, orgId: currentOrgQueryData?.data?.id as number });
    }

    const updateScheduling = async (scheduling: any) => {
        scheduling.forEach((s: any) => {
            s.orgId = currentOrgQueryData?.data?.id;
        });

        updateSchedulingMutation.mutate(scheduling);
    }

    const applyScheduling = async () => {
        applyScheduleMutation.mutate();
    }

    

    return {
        isLoading,
        refetch,
        org: currentOrgQueryData?.data,
        departments: currentOrgQueryData?.data?.departments,
        groups: currentOrgQueryData?.groups,
        status,
        scheduling: currentOrgQueryData?.data?.instanceplannings || [],
        actions: {
            addUserToDepartment,
            removeUserFromDepartment,
            addDepartmentToOrg,
            removeDepartmentFromOrg,
            editDepartment,
            updateScheduling,
            applyScheduling
        }
    }


}
export { useCurrentOrgState };
export default useCurrentOrgState;
