import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { useQueryClient } from '@tanstack/react-query';
import { CognitoContext } from '@/features/authentication/components/CognitoProvider';
import { getQueryKey as getCurrentGameClipsQueryKey, useCurrentGameClipsQuery } from '@/features/clips/hooks/useCurrentGameClipsQuery';
import { GetITMSSEUrl } from '@/util';
import { ClipModel } from '@/features/clips/types';

/**
 * TimemachineState type definition
 */
export type TimemachineState = {
  clip?: ClipModel;
  status?: string;
  clipStatusUpdate?: {
    clip: any;
    status: 'PROCESSING' | 'READY' | 'ERROR' | 'CANCELED';
  };
};

const INITIAL_STATE: TimemachineState = {};

/**
 * Custom hook to manage timemachine state using server-sent events
 */
const useTimemachineState = () => {
  const { cognitoContext } = useContext(CognitoContext);
  const queryClient = useQueryClient();
  const { invalidateAndRefetch } = useCurrentGameClipsQuery();
  // State and refs
  const [state, setState] = useState<TimemachineState>(INITIAL_STATE);
  const sseRef = useRef<ReconnectingEventSource | null>(null);



  /**
   * Handle SSE events from the server
   */
  const handleSSEEvents = useCallback(
    async (data: any) => {
      const { event, payload } = data;

      switch (event) {
        case 'clip-status-update':
          // Update state with clip status information
          setState(prev => ({
            ...prev,
            clipStatusUpdate: {
              clip: payload.clip,
              status: payload.status
            }
          }));

          // Refresh clips data
          await invalidateAndRefetch()


          break;

        case 'game-switch-update':
            console.log('game-switch-update', payload);
          break;
      }
    },
    [queryClient]
  );

  /**
   * Set up and tear down SSE connection when authentication changes
   */
  useEffect(() => {
    // Clean up function to close connections
    const cleanup = () => {
      if (sseRef.current) {
        sseRef.current.close();
        sseRef.current = null;
      }
    };

    // If not authenticated, clean up and return
    if (!cognitoContext.authenticated) {
      cleanup();
      return;
    }

    // Set up SSE connection
    sseRef.current = new ReconnectingEventSource(GetITMSSEUrl());
    sseRef.current.onmessage = (e) => handleSSEEvents(JSON.parse(e.data));

    // Clean up on unmount or when dependencies change
    return cleanup;
  }, [cognitoContext.authenticated, handleSSEEvents]);

  return { timemachineState: state };
};

export type TimemachineStateType = ReturnType<typeof useTimemachineState>;
export default useTimemachineState;