import { TeamModel } from '@/features/teams/types/TeamModel';
import { defaultGcTime, defaultStaleTime } from '@/shared/constants';
import { isInvalid } from '@/shared/services/isInvalid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { useEffect } from 'react';

export function getQueryKey(leagueId?: number, seasonId?: number) {
  return isInvalid(seasonId, 'string') ? ['GameTeams', leagueId] : ['GameTeams', leagueId, seasonId]
}

const useGameTeamsQuery = (leagueId?: number, seasonId?: number, opt?: any) => {
  const queryClient = useQueryClient();
  const isValidLeague = !isInvalid(leagueId, 'number');
  
  // Effect to remove invalid queries
  useEffect(() => {
    if (!isValidLeague) {
      queryClient.removeQueries({ queryKey: getQueryKey(leagueId, seasonId) });
    }
  }, [leagueId, seasonId, isValidLeague, queryClient]);
  
  return useQuery({
    queryKey: getQueryKey(leagueId, seasonId),
    queryFn: async (): Promise<TeamModel[]> => {
      const url = seasonId ? `/v1.0/teams?seasonId=${seasonId}` : '/v1.0/teams';
      const { data } = await get(url);
      return data;
    },
    staleTime: defaultStaleTime,
    gcTime: defaultGcTime,
    enabled: false,
    placeholderData: [],
    ...opt,
  });
};

export default useGameTeamsQuery;