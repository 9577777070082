import { JumpFrameMessage } from '@/types/control';

import useWebSocket from './useWebsocket2';

const useJumpFrame = () => {
  const socket = useWebSocket<JumpFrameMessage>('/control/0.1.0');

  return {
    jumpFrame: (delta: number) => {
      socket.send({ type: 'JumpFrame', delta });
    },
  };
};

export default useJumpFrame;
