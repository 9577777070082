import { motion, useAnimation } from 'framer-motion';
import React, { ReactNode, useEffect } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';

import CosmLogo from '@/assets/cosm_logo.png';
import CXVideoHub from '@/assets/CX_VideoHub.svg';
import { Paper } from '@mui/material';

type Props = {
  children: ReactNode;
};

export default ({ children }: Props) => {
  const autoIsoLogoControls = useAnimation();
  const cxVideoHubLogoControls = useAnimation();
  const cosmLogoControls = useAnimation();
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return; // Only run the animation after the component is mounted

    const sequence = async () => {
      // Add a small delay before starting the animation
      await new Promise((resolve) => setTimeout(resolve, 250)); // 250ms delay
      try {
        await cosmLogoControls.start({
          opacity: 1,
          transition: { duration: 1 },
        });
        await cxVideoHubLogoControls.start({
          opacity: 1,
          transition: { duration: 1 },
        });
        await autoIsoLogoControls.start({
          opacity: 1,
          transition: { duration: 1 },
        });
      } catch (e) {
        return;
      }
    };

    // Start the animation sequence after the delay
    sequence();
  }, [cosmLogoControls, cxVideoHubLogoControls, autoIsoLogoControls, mounted]);

  return (
    <Container maxWidth="sm" fixed>
      <Box
        sx={{
          height: '100vh',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            width: '100%',
            borderRadius: 2,
            px: 3,
            pt: 2,
            pb: 1,
            backgroundColor: 'transparent',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              flexDirection: 'column',
            }}
          >
            <motion.div initial={{ opacity: 0 }} animate={cxVideoHubLogoControls}>
              <img src={CosmLogo} alt="Cosm" style={{ height: 45, marginTop: 45 }} />
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={cosmLogoControls}>
              <img src={CXVideoHub} alt="CXVideo Hub" style={{ height: 10, marginTop: 15 }} />
            </motion.div>

            <motion.div initial={{ opacity: 0 }} animate={autoIsoLogoControls}>
              <Typography variant="subtitle2" sx={{ mb: 3, mt: 1 }}>
                with AUTO-ISO&trade;
              </Typography>
            </motion.div>

            {children}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
