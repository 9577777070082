import React from 'react';
import { format, parseISO } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface DateDisplayProps {
  date: string;
}

const DateDisplay: React.FC<DateDisplayProps> = ({ date }) => {
  const dayOfWeek = format(parseISO(date), 'EEE');
  const month = format(parseISO(date), 'MMM');
  const day = format(parseISO(date), 'dd');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'right',
        width: '100px'
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '1.5rem',
          lineHeight: 1,
          mb: 0.5,
        }}
      >
        {dayOfWeek}
      </Typography>
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: '2rem',
          lineHeight: 1,
          mb: 0.5,
        }}
      >
        {month}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '3.1rem',
          lineHeight: 1,
          mb: 0.5
        }}
      >
        {day}
      </Typography>
    </Box>
  );
};

export default DateDisplay;
