import React from 'react';
import { Stack, Typography } from '@mui/material';
import { User } from '@/types/user';
import UsersTable from '@/features/settings/components/UsersTable';

interface OrganizationMembersSectionProps {
  users: User[];
  onEditUser: (user: User) => void;
  onDeleteUser: (userId: number) => void;
  onDisableUser: (userId: number) => void;
}

export const OrganizationMembersSection: React.FC<OrganizationMembersSectionProps> = ({
  users,
  onEditUser,
  onDeleteUser,
  onDisableUser,
}) => {
  if (!users.length) return null;

  return (
    <>
      <Stack direction="column" justifyContent="center" spacing={2} p={3}>
        <Typography variant="subtitle1">Organization Members</Typography>
      </Stack>
      <UsersTable
        invitees={false}
        users={users}
        handleEditUserGroup={onEditUser}
        handleDelete={onDeleteUser}
        handleDisable={onDisableUser}
      />
    </>
  );
};
