import React, { ReactNode, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export type Props = {
  title?: string;
  message?: string | ReactNode;
  onConfirm: () => Promise<void>;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  confirmButtonStyle?: 'primary' | 'error' | 'warning' | 'info';
};

const ConfirmationDialog = ({
  title = 'Are you sure?',
  message = '',
  onConfirm = () => Promise.resolve(),
  onCancel = () => {},
  confirmText = 'Yes',
  cancelText = 'No',
  confirmButtonStyle = 'primary',
}: Props) => {
  
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setButtonLoading(false);
    setOpen(true);
  }, []);

  const confirmHandler = async () => {
    try {
      await onConfirm();
      setOpen(false);
    } catch (e) {
      setButtonLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
          return;
        }
        onCancel();
      }}
      maxWidth="xs"
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(4px)', // Default blur effect
          },
        },
      }}
    >
      <DialogTitle>{title || 'Are you sure?'}</DialogTitle>
      {!!message && (
        <DialogContent dividers>
          {typeof message === 'string' ? <Typography>{message}</Typography> : message}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} autoFocus disabled={buttonLoading} variant="outlined">
          {cancelText}
        </Button>
        <LoadingButton
          loading={buttonLoading}
          onClick={() => {
            setButtonLoading(true);
            confirmHandler();
          }}
          color={confirmButtonStyle}
          variant="contained"
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
