import {
  Box,
  CircularProgress,
  Stack,
} from '@mui/material';
import 'simplebar-react/dist/simplebar.min.css'; // Make sure to import the CSS

import useClipStateContext from '@/shared/hooks/useClipStateContext';

import useGameTeamsQuery from '@/features/games/hooks/useGameTeamsQuery';
import DownloadClipDialog from '@/shared/components/DownloadClipDialog';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import useCurrentGameClipsQuery from '../hooks/useCurrentGameClipsQuery';
import getClipsFilter from '../services/getClipsFilter';
import { ClipModel, ClipType } from '../types';
import ClipRecord from './ClipRecord';
import { ViewingToolsMenuExpandablePanel } from '@/features/games/components';
import ClipsFilterPanel from './FilterAndSortPanel';

function filterByDepartments(entries, departmentIds = []) {
  // If no department IDs provided, return all entries
  if (!departmentIds.length) {
    return entries;
  }

  // Convert departmentIds to a Set for faster lookups
  const departmentIdSet = new Set(departmentIds);

  // Filter entries where the user belongs to at least one of the specified departments
  return entries.filter((entry) => {
    // Get user departments
    const userDepartments = entry.clouduser?.userdepartments || [];

    // Check if the user belongs to any of the specified departments
    return userDepartments.some((userDept) => departmentIdSet.has(userDept.departmentId));
  });
}

const ClipsAndBookmarksPanel = () => {
  const {
    state: { isEditing, filter, tagsFilter, editingModel },
    setFilter,
    setTagsFilter,
    handleAddClip,
    handleAddBookmark,
  } = useClipStateContext();

  const { currentLeague, userDepartments } = useContext(UserStateContext);

  const teams = useGameTeamsQuery(currentLeague?.id);
  const clipsQuery = useCurrentGameClipsQuery();

  const clipsScrollableAreaRef = useRef(null);
  const [clip, setClip] = useState<ClipModel | undefined>(undefined);
  const [downloadClipDialogOpen, setDownloadClipDialogOpen] = useState(false);
  const [filteredClips, setFilteredClips] = useState<ClipModel[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [simpleBarHeight, setSimpleBarHeight] = useState(0);
  const [sortCriteria, setSortCriteria] = useState('startTimestamp');
  const [departementFilter, setDepartmentFilter] = useState<number[]>([]);
  const maxHeight = useMemo(() => `calc(100vh - ${simpleBarHeight}px)`, [simpleBarHeight]);

  // 1. Add additional dependencies to useEffect for recalculation
  useEffect(() => {
    const clipsArea = clipsScrollableAreaRef.current;
    if (!clipsArea) return;

    const updateHeight = () => {
      requestAnimationFrame(() => {
        setSimpleBarHeight(clipsScrollableAreaRef.current.getBoundingClientRect().top);
      });
    };

    // Call immediately
    updateHeight();

    // Add a window resize listener to handle window size changes
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [
    isCollapsed,
    clipsQuery.dataUpdatedAt,
    filteredClips.length,
    filter,
    tagsFilter,
    clipsScrollableAreaRef.current,
  ]);

  useEffect(() => {
    if (clipsQuery.data) {
      let clips: ClipModel[] = clipsQuery.data.filter(getClipsFilter(filter, tagsFilter));

      clips = clips.sort((a: ClipModel, b: ClipModel) => {
        if (sortCriteria === 'startTimestamp') {
          return a.startTimestamp.getTime() - b.startTimestamp.getTime();
        }
        if (sortCriteria === 'name') {
          return a.note.localeCompare(b.note); // Assuming you want to sort by name, not note
        }
        return 0;
      });
      clips = filterByDepartments(clips, departementFilter);

      //@ts-ignore
      setFilteredClips(clips);
    }
  }, [clipsQuery.dataUpdatedAt, filter, tagsFilter, sortCriteria,departementFilter]);

  useEffect(() => {
    if (clipsQuery.data) {
      clipsQuery.refetch();
    }
  }, [tagsFilter, filter, sortCriteria]);

  // do not put any react hook under this line ...
  if (!clipsQuery.data) return <CircularProgress m={2} />;

  const handleDownloadClip = (clip: ClipModel) => {
    setClip(clip);
    setDownloadClipDialogOpen(true);
  };

  return (
    <Stack sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <DownloadClipDialog
        open={downloadClipDialogOpen}
        clip={clip as ClipModel}
        onClose={() => setDownloadClipDialogOpen(false)}
      />

      {!isEditing && (
        <ViewingToolsMenuExpandablePanel
          showIcon={!isEditing}
          title={isEditing ? `Edit mode` : 'Clips & Bookmarks'}
          onCollapse={() => setIsCollapsed(!isCollapsed)}
        >
          <ClipsFilterPanel
            setTagsFilter={setTagsFilter}
            isEditing={isEditing}
            handleAddBookmark={handleAddBookmark}
            handleAddClip={handleAddClip}
            departments={[]}
            filter={filter}
            setFilter={(t) => setFilter(t as ClipType | 'all')}
            filteredClips={filteredClips}
            userDepartments={userDepartments}
            setDepartmentFilter={function (departments: any[]): void {
              setDepartmentFilter(departments);
            }}
            handleSortChange={function (sortBy: string): void {
              setSortCriteria(sortBy);
            }}
          ></ClipsFilterPanel>
        </ViewingToolsMenuExpandablePanel>
      )}

      {/* This flex container ensures the SimpleBar takes remaining space */}

      <Box
        ref={clipsScrollableAreaRef}
        sx={{
          height: '100%',
          maxHeight,
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'rgba(0,0,0,0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '4px',
          },
        }}
      >
        {teams.status === 'success' &&
          filteredClips.map((row: ClipModel, i) => (
            <ClipRecord
              isEditing={isEditing}
              key={row.id}
              clip={row}
              isFirst={i === 0}
              //@ts-ignore
              teams={teams.data}
              //@ts-ignore
              downloadCliphandler={handleDownloadClip}
            />
          ))}
      </Box>
    </Stack>
  );
};

export default ClipsAndBookmarksPanel;
