import { useEffect } from 'react';
import { isUUID } from 'validator';
import { useNavigate } from 'react-router';

import {
  ClipStateProvider,
  KeyboardShortcutsDialogProvider,
  ServerStateProvider,
} from '@/shared/components';

import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { LocalSessionState, useAuth } from '@/features/scaffolding/components/AuthContext';

import AppPage from '../../../shared/components/PageComponents/AppPage';
import ScoreboardData from './ScoreboardData';
import ViewingToolsMenu from './ViewingToolsMenu';
import GamePageContents from './GamePageContents';
import LoadingGame from './LoadingGame';
import { DRAWER_WIDTH } from '../constants';

import { CurrentGameProvider, useCurrentGame } from '../context/CurrentGameContext';
import { AutoIsoTeamSelectorProvider } from '@/features/teams/context/AutoIsoTeamSelectorContext';

// Component that consumes the CurrentGameContext
const GamePageContent = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const gameId = useCurrentGameId();
  const { game, refetch, gameLoaded } = useCurrentGame();

  useDocumentTitle(`${game?.name || 'Game'}`);

  useEffect(() => {
    if (auth.sessionState === LocalSessionState.READY) {
      if (gameId && isUUID(gameId)) {
        refetch();
      } else {
        navigate('/games');
      }
    }
  }, [gameId, auth.sessionState, refetch, navigate]);

  if (!gameLoaded) {
    return <LoadingGame />;
  }

  return (
    <ServerStateProvider>
      <ClipStateProvider>
        <AutoIsoTeamSelectorProvider>
          <AppPage
            title={game?.name || 'Loading...'}
            renderPageMenu={(open, setOpen) => (
              <ViewingToolsMenu open={open} setOpen={setOpen} width={DRAWER_WIDTH} />
            )}
            renderSummaryData={() => <ScoreboardData data={game} />}
          >
            <KeyboardShortcutsDialogProvider>
              <GamePageContents game={game} />
            </KeyboardShortcutsDialogProvider>
          </AppPage>
        </AutoIsoTeamSelectorProvider>
      </ClipStateProvider>
    </ServerStateProvider>
  );
};

// Main GamePage component that sets up the context provider
const GamePage = () => {
  return (
    <CurrentGameProvider>
      <GamePageContent />
    </CurrentGameProvider>
  );
};

export default GamePage;
