import { format, parseISO } from 'date-fns';
import { Game, GamesByDateGroup } from '../types/games';

/**
 * Group games by date
 * @param allGames Array of games to group
 * @param referenceDate Reference date in yyyy-MM-dd format
 * @param hasFetchedPrevious Whether previous games have been fetched
 * @param hasFetchedNext Whether next games have been fetched
 * @returns Array of games grouped by date
 */
export const groupGamesByDate = (
  allGames: Game[],
  referenceDate: string,
  hasFetchedPrevious: boolean,
  hasFetchedNext: boolean
): GamesByDateGroup[] => {
  if (!allGames.length) return [];

  // Group games by date
  const groupedGames = allGames.reduce(
    (groups, game) => {
      const date = format(parseISO(game.startTimestamp), 'yyyy-MM-dd');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    },
    {} as Record<string, Game[]>
  );

  // Convert to array of { date, games } objects and sort by date
  return Object.entries(groupedGames)
    .map(([date, games]) => ({
      date,
      formattedDate: format(parseISO(date), 'EEEE, MMMM d, yyyy'),
      games,
      isPrevious: hasFetchedPrevious && format(parseISO(date), 'yyyy-MM-dd') < referenceDate,
      isNext: hasFetchedNext && format(parseISO(date), 'yyyy-MM-dd') > referenceDate,
    }))
    .sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Find the date closest to the reference date in the list of game date groups
 * @param gamesByDate Array of games grouped by date
 * @param referenceDate Reference date in yyyy-MM-dd format
 * @returns The closest date, or null if no dates are available
 */
export const findClosestDateToToday = (
  gamesByDate: GamesByDateGroup[],
  referenceDate: string
): string | null => {
  if (!gamesByDate.length) return null;

  // First check if today exists in our dates
  const todaySection = gamesByDate.find((group) => group.date === referenceDate);
  if (todaySection) return referenceDate;

  // If today isn't in the list, find the closest future date
  const futureDates = gamesByDate
    .filter((group) => group.date > referenceDate)
    .sort((a, b) => a.date.localeCompare(b.date));

  if (futureDates.length > 0) return futureDates[0].date;

  // If no future dates, get the most recent past date
  const sortedDates = [...gamesByDate].sort((a, b) => b.date.localeCompare(a.date));
  return sortedDates[0]?.date || null;
};

/**
 * Calculate responsive card dimensions based on window width
 * @returns The width of the card in pixels
 */
export const getCardWidth = (): number => {
  // Use window width to determine card size
  const width = window.innerWidth;
  if (width >= 1200) return 360; // lg
  if (width >= 900) return 320; // md
  return 280; // sm and xs
};