import { useMutation } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

const useDownloadLogsMutation = () =>
  useMutation({
    mutationFn: async (clipId: number) => {
      // Make the request
      const response = await get(`/v1.0/internals/queues/clip-export/${clipId}/logs`);
      
      // Don't check for response.ok - use your HTTP utility's error handling
      
      // Set a default filename
      const filename = `clip-export-${clipId}-logs.txt`;
      
      // Create a blob with the proper format - note the array and content type
      const blob = new Blob([response.data], { type: 'text/plain' });
      
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.style.display = 'none'; // Hide the element
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
      
      return { success: true };
    },
    gcTime: 0,
  });

export default useDownloadLogsMutation;