import { useCallback, useEffect, useState } from 'react';
import { allTimezones } from 'react-timezone-select';
import useCognito from '@/features/authentication/hooks/useCognito';
import useCurrentCloudUser from './getCurrentUser';
import useUpdateUser from './useUpdateUser';
import { getLeagueLogo } from '@/shared/services/getLeagueLogo';
import { League, Org, User } from '@/types/user';
import { useUpdateSession } from './useUpdateSession';
import { useNavigate } from 'react-router';
import { hasAccess } from '@/shared/services/hasAccess';

export type RoleCheckFunction = (roleNames: string | string[]) => boolean;

export interface UserState {
  userProfile: User | null;
  isLoading: boolean;
  error: Error | null;
  currentOrg: Org | null;
  currentLeague: League | null;
  availableOrgs: Org[];
  currentLeagueLogo: string | null;
  hasRole: RoleCheckFunction;
  actions: {
    updateTimezone: (tz: string) => Promise<void>;
    updateName: (firstName: string, lastName: string) => Promise<void>;
    updateDefaultOrg: (org: Org) => Promise<void>;
    updateDefaultLeague: (league: League) => Promise<void>;
    setCurrentOrg: (org: Org) => void;
  };
}

const useUserState = (): UserState => {
  const navigate = useNavigate();
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Core mutations and queries
  const updateUserMutation = useUpdateUser();
  const updateSessionMutation = useUpdateSession();
  const { status, data: currentCloudUserQuery, refetch } = useCurrentCloudUser();


  const { authenticated } = useCognito();

  // State management
  const [error, setError] = useState<Error | null>(null);
  const [userProfile, setUserProfile] = useState<User | null>(null);
  const [currentOrg, setCurrentOrg] = useState<Org | null>(null);
  const [currentLeague, setCurrentLeague] = useState<League | null>(null);
  const [availableOrgs, setAvailableOrgs] = useState<Org[]>([]);
  const [currentLeagueLogo, setCurrentLeagueLogo] = useState<string | null>(null);
  const [userDepartments, setUserDepartments] = useState<Array<Department>>([]);

  // Image preloading utility
  const preloadImage = useCallback((url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = url;
    });
  }, []);

  // Initialize user data when cloud data is available
  useEffect(() => {
    if (status === 'success' && currentCloudUserQuery?.data) {
      const { org, league, user: cloudUser } = currentCloudUserQuery.data;

      if (!cloudUser || !org || !league) {
        setError(new Error('Incomplete user data received'));
        return;
      }

      setCurrentOrg(org);
      setCurrentLeague(league);
      setAvailableOrgs(cloudUser.orgs);
      setUserProfile({
        tz: allTimezones[cloudUser.tz] ? cloudUser.tz : browserTz,
        ...cloudUser,
      });

      setUserDepartments(cloudUser.departments);
    }
  }, [status, currentCloudUserQuery, browserTz]);

  // Handle league logo updates
  useEffect(() => {
    const loadLeagueLogos = async () => {
      try {
        if (currentOrg?.access) {
          await Promise.all(
            currentOrg.access
              .filter(access => access.league.id)
              .map(access => preloadImage(getLeagueLogo(access.league.id)))
          );
        }

        if (currentLeague?.id) {
          await preloadImage(getLeagueLogo(currentLeague.id));
          setCurrentLeagueLogo(getLeagueLogo(currentLeague.id));
        }
      } catch (error) {
        console.error('Failed to load league logos:', error);
      }
    };

    loadLeagueLogos();
  }, [currentOrg, currentLeague, preloadImage]);

  // Refresh user data when authentication changes
  useEffect(() => {
    if (authenticated) {
      refetch();
    }
  }, [authenticated, refetch]);

  // Action handlers
  const updateTimezone = async (tz: string) => {
    try {
      await updateUserMutation.mutateAsync({ tz });
      setUserProfile(prev => prev ? { ...prev, tz } : null);
    } catch (error) {
      setError(error as Error);
      throw error;
    }
  };

  const updateName = async (firstName: string, lastName: string) => {
    try {
      await updateUserMutation.mutateAsync({ firstName, lastName });
      setUserProfile(prev => prev ? { ...prev, firstName, lastName } : null);
    } catch (error) {
      setError(error as Error);
      throw error;
    }
  };

  const updateDefaultOrg = async (org: Org) => {
    try {
      await updateSessionMutation.mutateAsync({
        org: org.id,
        league: org.leagues[0].id
      });
      navigate('/games');
    } catch (error) {
      setError(error as Error);
      throw error;
    }
  };

  const updateDefaultLeague = useCallback(async (league: League) => {
    if (!currentOrg) {
      throw new Error('No organization selected');
    }

    try {
      await updateSessionMutation.mutateAsync({
        org: currentOrg.id,
        league: league.id
      });
      navigate('/games');
    } catch (error) {
      setError(error as Error);
      throw error;
    }
  }, [currentOrg, updateSessionMutation, navigate]);

  // Role checking utility
  const hasRole = useCallback((roleNames: string | string[]): boolean => {

    return hasAccess(userProfile?.roles || [], Array.isArray(roleNames) ? roleNames : [roleNames]);
  }, [userProfile?.roles]);

  return {
    userProfile,
    isLoading: status === 'pending',
    error,
    currentOrg,
    currentLeague,
    availableOrgs,
    currentLeagueLogo,
    userDepartments,
    hasRole,
    actions: {
      updateTimezone,
      updateName,
      updateDefaultOrg,
      updateDefaultLeague,
      setCurrentOrg,
    },
  };
};

export default useUserState;