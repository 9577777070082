import { EventEmitter } from 'eventemitter3';
import { WebSocketClient } from './WebSocketClient';
import {
    AutomaticTrackingEntity,
    Dims,
    Entity,
    GameState,
    ManualTrackingEntity,
    MosaicView,
    Pos,
    SingleView,
    TargetConstants,
    Tracking,
    Vcam,
    ViewConstants
} from '@/shared/types/GameStateModel';

import { throttle } from 'lodash';

interface GameStateEvents {
    // Connection events
    'connected': () => void;
    'disconnected': (code: number, reason: string) => void;
    'error': (error: Error) => void;

    // Game state events
    'stateUpdate': (state: GameState) => void;
    'indexChange': (idx: number) => void;
    'headChange': (head: number) => void;
    'liveChange': (live: number) => void;
    'speedChange': (speed: number) => void;

    // Game timing events
    'periodChange': (period: number) => void;
    'clockChange': (clock: string) => void;
    'scoreChange': (home: number, away: number) => void;

    // Camera events
    'vcamChange': (vcam: Vcam) => void;
    'vcamPanChange': (pan: number) => void;
    'vcamTiltChange': (tilt: number) => void;
    'vcamZoomChange': (zoom: number) => void;

    // Tracking events
    'trackingChange': (tracking: Tracking) => void;
    'trackingTargetChange': (target: ManualTrackingEntity | AutomaticTrackingEntity) => void;
    'trackingEntitiesChange': (entities: Entity[]) => void;
    'trackingEntitiyChange': (entityId: number, pos: Pos) => void;

    // View events
    'viewChange': (view: MosaicView | SingleView) => void;
    'viewTypeChange': (type: ViewConstants) => void;
    'mosaicDimensionsChange': (dims: Dims) => void;
    'mosaicIndexesChange': (idxs: number[]) => void;
}

interface GameStateEventEmitter {
    emit<K extends keyof GameStateEvents>(event: K, ...args: Parameters<GameStateEvents[K]>): boolean;
    on<K extends keyof GameStateEvents>(event: K, listener: GameStateEvents[K]): this;
    off<K extends keyof GameStateEvents>(event: K, listener: GameStateEvents[K]): this;
}


const FilterPlayersOutsideRink = (e) => e.pos.y < 42 && e.pos.y > -42 && e.pos.z > 0;

export class GameStateManager extends EventEmitter implements GameStateEventEmitter {
    private wsClient: WebSocketClient | null = null;
    private _lastGameState: GameState | null = null;
    throttledHeadChange: any;
    throttledLiveChange: any;
    throtteledTrackingEntitiesChange: any;
    throtteledClockChange: any;

    // Add getter for lastGameState
    get lastGameState(): GameState | null {
        return this._lastGameState;
    }

    constructor(url: string, fps: number = 20) {
        super();
        
        this.wsClient = new WebSocketClient(url, {
            reconnectAttempts: 1200,
            reconnectDelay: 500,
            autoReconnect: true
        });

        this._setupEventListeners();

        // Create throttled functions in constructor with more frequent updates
        this.throttledHeadChange = throttle(
            (head: any) => {
                this.emit('headChange', head);
            },
            250,  // Further reduced throttle interval to 250ms for more responsiveness
            {
                leading: true,
                trailing: true  // Keep both leading and trailing to ensure no updates are missed
            }
        );

        this.throttledLiveChange = throttle(
            (live: any) => {
                this.emit('liveChange', live);
            },
            250,  // Further reduced throttle interval to 250ms for more responsiveness
            {
                leading: true,
                trailing: true  // Keep both leading and trailing to ensure no updates are missed
            }
        );

        this.throtteledTrackingEntitiesChange = throttle(
            (entities: any[]) => this.emit('trackingEntitiesChange', entities),
            1000 / fps,
            {
                leading: true,
                trailing: true  // Changed to true
            });

        this.throtteledClockChange = throttle(
            (clock: any) => this.emit('clockChange', clock), 
            100,
            {
                leading: true,
                trailing: true  // Changed to true
            });
    }

    connect(): void {
        if (this.wsClient?.getState() === 'OPEN') {
            console.warn('WebSocket is already connected');
            return;  // Return instead of throwing an error
        }
        this.wsClient?.connect();
    }

    disconnect(): void {
        console.log('Disconnecting WebSocket.... this is called by ...somwhere? ');
        if (this.wsClient?.getState() !== 'OPEN') {
            console.warn('WebSocket is not connected');
        }
        try {
            this.wsClient?.disconnect();
        } catch (e) {
            console.error(e);
        }

        // Don't clean up state here, just mark as disconnected
        // We'll keep the last state in case of reconnection
        console.log('WebSocket disconnected, keeping last state');
    }

    // Add method to get current state
    getCurrentState(): GameState | null {
        return this._lastGameState;
    }

    private _setupEventListeners(): void {
        if (!this.wsClient) return;

        this.wsClient.on('connect', () => {
            this.emit('connected');
            
            // If we have a last state, ensure we re-emit it after reconnection
            if (this._lastGameState) {
                // Force emit a state update after reconnection to ensure clients are in sync
                setTimeout(() => {
                    if (this._lastGameState) {
                        this.emit('stateUpdate', this._lastGameState);
                        // Re-emit key state properties to ensure UI is updated
                        this.emit('headChange', this._lastGameState.head);
                        this.emit('liveChange', this._lastGameState.live);
                        this.emit('speedChange', this._lastGameState.speed);
                    }
                }, 100);
            }
        });

        this.wsClient.on('disconnect', (event) => {
            console.log('WebSocket disconnected, emitting disconnect event', event.code, event.reason);
            // Don't clean up state on disconnect
            // this._cleanup();
            this.emit('disconnected', event.code, event.reason);
        });

        this.wsClient.on('error', (error) => {
            this.emit('error', error);
        });

        this.wsClient.on('message', (data) => {
            try {
                const gameState: GameState = typeof data === 'string' ? JSON.parse(data) : data;
                this._handleGameStateUpdate(gameState);
            } catch (error) {
                this.emit('error', new Error(`Failed to parse message: ${error.message}`));
            }
        });
    }

    private _handleGameStateUpdate(newState: GameState): void {
        // Emit full state update
        this.emit('stateUpdate', newState);

        if (!this._lastGameState) {
            console.log('Initial state update');
            this._emitAllChanges(newState);
        } else {
            this._checkAndEmitChanges(this._lastGameState, newState);
        }

        // Update stored state
        this._lastGameState = newState;
    }

    private _emitAllChanges(state: GameState): void {
        // Base state changes
        this.emit('indexChange', state.idx);
        this.throttledHeadChange(state.head);  // Use throttled version
        this.throttledLiveChange(state.live);  // Use throttled version
        this.emit('speedChange', state.speed);

        // Game timing changes
        this.emit('periodChange', state.game.period);
        this.throtteledClockChange(state.game.clock);  // Use throttled version
        this.emit('scoreChange', state.game.score.home, state.game.score.away);

        // Vcam changes
        this.emit('vcamChange', state.vcam);
        this.emit('vcamPanChange', state.vcam.pan);
        this.emit('vcamTiltChange', state.vcam.tilt);
        this.emit('vcamZoomChange', state.vcam.zoom);

        // Tracking changes
        this.emit('trackingChange', state.tracking);
        this.emit('trackingTargetChange', state.tracking.target);
        this.throtteledTrackingEntitiesChange(state.tracking.entities.filter(FilterPlayersOutsideRink));

        // Emit individual entity position changes
        state.tracking.entities.forEach(entity => {
            this.emit('trackingEntitiyChange', entity.id, entity.pos);
        });

        // View changes
        this.emit('viewChange', state.view);
        this.emit('viewTypeChange', state.view.type);

        if (state.view.type !== ViewConstants.SINGLE_VIEW) {
            const mosaicView = state.view as MosaicView;
            this.emit('mosaicDimensionsChange', mosaicView.dims);
            this.emit('mosaicIndexesChange', mosaicView.idxs);
        }
    }

    private _checkAndEmitChanges(oldState: GameState, newState: GameState): void {
        // Check base state changes
        if (oldState.idx !== newState.idx) this.emit('indexChange', newState.idx);
        if (oldState.head !== newState.head) this.throttledHeadChange(newState.head);
        if (oldState.live !== newState.live) this.throttledLiveChange(newState.live);
        if (oldState.speed !== newState.speed) this.emit('speedChange', newState.speed);

        // Check game timing changes
        if (oldState.game.period !== newState.game.period) {
            this.emit('periodChange', newState.game.period);
        }
        if (oldState.game.clock !== newState.game.clock) {
            this.throtteledClockChange(newState.game.clock);
        }
        if (oldState.game.score.home !== newState.game.score.home ||
            oldState.game.score.away !== newState.game.score.away) {
            this.emit('scoreChange', newState.game.score.home, newState.game.score.away);
        }

        // Check vcam changes
        if (this._isVcamChanged(oldState.vcam, newState.vcam)) {
            this.emit('vcamChange', newState.vcam);
            if (oldState.vcam.pan !== newState.vcam.pan) {
                this.emit('vcamPanChange', newState.vcam.pan);
            }
            if (oldState.vcam.tilt !== newState.vcam.tilt) {
                this.emit('vcamTiltChange', newState.vcam.tilt);
            }
            if (oldState.vcam.zoom !== newState.vcam.zoom) {
                this.emit('vcamZoomChange', newState.vcam.zoom);
            }
        }

        // Check tracking changes
        if (this._isTrackingChanged(oldState.tracking, newState.tracking)) {
            this.emit('trackingChange', newState.tracking);
            if ((newState.tracking.target.type === TargetConstants.AUTOMATIC && oldState.tracking.target.id !== newState.tracking.target.id) || (oldState.tracking.target.type !== newState.tracking.target.type))
                this.emit('trackingTargetChange', newState.tracking.target);
            if (this._isArrayChanged(oldState.tracking.entities, newState.tracking.entities))
                this.throtteledTrackingEntitiesChange(newState.tracking.entities.filter(FilterPlayersOutsideRink))

            // Check individual entity position changes
            newState.tracking.entities.forEach(newEntity => {
                const oldEntity = oldState.tracking.entities.find(e => e.id === newEntity.id);
                if (!oldEntity || this._isPosChanged(oldEntity.pos, newEntity.pos)) {
                    this.emit('trackingEntitiyChange', newEntity.id, newEntity.pos);
                }
            });
        }

        // Check view changes
        if (this._isViewChanged(oldState.view, newState.view)) {
            this.emit('viewChange', newState.view);
            if (oldState.view.type !== newState.view.type) {
                this.emit('viewTypeChange', newState.view.type);
            }

            if (newState.view.type !== ViewConstants.SINGLE_VIEW) {
                const oldMosaicView = oldState.view as MosaicView;
                const newMosaicView = newState.view as MosaicView;

                if (this._isDimsChanged(oldMosaicView.dims, newMosaicView.dims)) {
                    this.emit('mosaicDimensionsChange', newMosaicView.dims);
                }

                if (this._isArrayChanged(oldMosaicView.idxs, newMosaicView.idxs)) {
                    this.emit('mosaicIndexesChange', newMosaicView.idxs);
                }
            }
        }
    }

    private _isVcamChanged(old: Vcam, new_: Vcam): boolean {
        return old.pan !== new_.pan ||
            old.tilt !== new_.tilt ||
            old.zoom !== new_.zoom;
    }

    private _isTrackingChanged(old: Tracking, new_: Tracking): boolean {
        return JSON.stringify(old) !== JSON.stringify(new_);
    }

    private _isPosChanged(old: Pos, new_: Pos): boolean {
        return old.x !== new_.x ||
            old.y !== new_.y ||
            old.z !== new_.z;
    }

    private _isViewChanged(old: MosaicView | SingleView, new_: MosaicView | SingleView): boolean {
        return JSON.stringify(old) !== JSON.stringify(new_);
    }

    private _isDimsChanged(old: Dims, new_: Dims): boolean {
        return old.width !== new_.width ||
            old.height !== new_.height;
    }

    private _isArrayChanged(old: any[], new_: any[]): boolean {
        return JSON.stringify(old) !== JSON.stringify(new_);
    }

    private _cleanup(): void {
        this._lastGameState = null;
    }
}