import { LoaderCircle, CircleCheck, XCircle, AlertCircle, Clock, Squircle } from 'lucide-react';
import { green, grey, purple, red, orange, amber } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import { ClipStatus } from '@/features/clips/types';

// Status component that shows different icons and colors based on status
const StatusIndicator = ({
  status,
  onClickHandler,
}: {
  status: ClipStatus;
  onClickHandler?: () => void;
}) => {
  // Convert status to uppercase for consistent comparison
  const statusUpper = status ? status.toUpperCase() : ('UNKNOWN' as ClipStatus);

  // Get appropriate colors based on status
  const getChipStatusColor = (status: ClipStatus) => {
    switch (status) {
      case 'READY':
        return green.A700;
      case 'ERROR':
        return red[700];
      case 'CANCELED':
        return amber[500];
      case 'PENDING':
        return orange[700];
      case 'UNPROCESSED':
        return grey[700];
      case 'PROCESSING':
        return orange[200];
      default:
        return purple[700];
    }
  };

  // Get the formatted title for tooltip
  const title =
    status === 'UNPROCESSED'
      ? 'New'
      : status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  // Check if status is processing for animation
  const isProcessing = statusUpper === ClipStatus.PROCESSING;

  // Icon selection based on status
  const getIcon = (status: ClipStatus, color: string) => {
    const iconProps = {
      color,
      style: isProcessing
        ? {
            animation: 'spin 2s linear infinite',
          }
        : {},
    };

    switch (status) {
      case 'READY':
        return <CircleCheck {...iconProps} />;
      case 'ERROR':
        return <XCircle {...iconProps} />;
      case 'CANCELED':
        return <AlertCircle {...iconProps} />;
      case 'PENDING':
        return <Clock {...iconProps} />;
      case 'UNPROCESSED':
        return <Squircle {...iconProps} />;
      case 'PROCESSING':
        return <LoaderCircle {...iconProps} />;
      default:
        return <Squircle {...iconProps} />;
    }
  };

  const color = getChipStatusColor(statusUpper as ClipStatus);

  return (
    <div className="inline-block">
      {/* Add the keyframes for spinning animation */}
      {isProcessing && (
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      )}

      <div className="relative inline-block cursor-pointer" onClick={onClickHandler}>
        <Tooltip title={title} arrow>
          <span style={{ display: 'inline-block' }}>
            {getIcon(statusUpper as ClipStatus, color)}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default StatusIndicator;
