import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface Props {
  trackingTab: React.ReactNode;
  bookmarksTab: React.ReactNode;
  sx?: any;
  isEditing?: boolean;
}

const TrackingAndBookmarksTabs: React.FC<Props> = ({
  trackingTab,
  bookmarksTab,
  isEditing,
  sx,
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {isEditing && <>{bookmarksTab}</>}

      {!isEditing && (
        <Box sx={{ bgcolor: 'background.paper', width: '100%', ...sx }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Tracking" {...a11yProps(0)} />
              <Tab label="Clips & Bookmarks" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} dir={theme.direction}>
            {trackingTab}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {bookmarksTab}
          </TabPanel>
        </Box>
      )}
    </>
  );
};

export default TrackingAndBookmarksTabs;
