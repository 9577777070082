import { styled } from '@mui/material';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { ReactNode } from 'react';
import ClipReadySnackBar from './ClipReadySnackbar';

type Props = {
  children: ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#12b76a',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#f04438',
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#fdb022',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#2196f3',
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: '#37373f',
  },
}));

const StyledSnackbarProvider = ({ children }: Props) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
        //@ts-expect-error
        clipReady: ClipReadySnackBar
      }}
      autoHideDuration={5000}
    >
      <>{children}</>
    </SnackbarProvider>
  );
};

export default StyledSnackbarProvider;
