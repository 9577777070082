export type TagColor = {
  b: string;
  c: string;
  name?: string;
};
export function colorTag(str: string): TagColor {
  if (!str) {
    return { b: 'black', c: 'white' };
  }


  if(str.toLowerCase()==='admins'){
    return { b: '#e76f51', c: 'white', name: 'Burnt Sienna' };
  }

  const materialColors: Array<TagColor> = [
    { b: '#e76f51', c: 'white', name: 'Burnt Sienna' },
  { b: '#f4a261', c: 'black', name: 'Sandy Brown' },
  { b: '#e9c46a', c: 'black', name: 'Maize Crayola' },
  { b: '#2a9d8f', c: 'white', name: 'Persian Green' },
  { b: '#264653', c: 'white', name: 'Charcoal' },
  

  
  // Additional complementary colors
  { b: '#83c5be', c: 'black', name: 'Opal' },
  { b: '#ffddd2', c: 'black', name: 'Unbleached Silk' },
  { b: '#006d77', c: 'white', name: 'Deep Teal' },
  { b: '#edf6f9', c: 'black', name: 'Azure White' },
  { b: '#e29578', c: 'black', name: 'Tumbleweed' },
  
  // Neutral tones
  { b: '#ddd8c4', c: 'black', name: 'Bone' },
  { b: '#a3a380', c: 'black', name: 'Sage' },
  { b: '#778da9', c: 'white', name: 'Shadow Blue' },
  { b: '#415a77', c: 'white', name: 'Indigo Dye' },
  { b: '#1b263b', c: 'white', name: 'Oxford Blue' }
  ];

  // create the hex for the random string
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return materialColors[Math.abs(hash) % materialColors.length];
}
