import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { useContext } from 'react';

import { CognitoContext } from '../components/CognitoProvider';
import { CognitoState } from '../types';

import authenticateUser from '../functions/authenticateUser';
import completeNewPasswordChallenge from '../functions/completeNewPasswordChallenge';
import confirmPassword from '../functions/confirmPassword';
import forgotPassword from '../functions/forgotPassword';
import getSession from '../functions/getSession';
import signOut from '../functions/signOut';
import setHttpAuth from '../functions/setHttpAuth';
import changePassword from '../functions/changePassword';
import { cognitoClientId, cognitoPoolId } from '@/shared/constants';

const useCognito = () => {
  const { cognitoContext, setCognitoContext } = useContext(CognitoContext);


  const userPool = new CognitoUserPool({
    UserPoolId: cognitoPoolId,
    ClientId: cognitoClientId,
  });

  const updateCognitoContext = (updates: Partial<CognitoState>) => {
    setCognitoContext({ ...cognitoContext, ...updates });
  };

  return {
    ...cognitoContext,
    updateCognitoContext,
    authenticateUser: authenticateUser(updateCognitoContext, userPool),
    completeNewPasswordChallenge: completeNewPasswordChallenge(
      cognitoContext.user as CognitoUser,
      updateCognitoContext,
    ),
    setHttpAuth,
    changePassword: changePassword(cognitoContext.user as CognitoUser),
    confirmPassword: confirmPassword(cognitoContext.user as CognitoUser),
    forgotPassword: forgotPassword(updateCognitoContext, userPool),
    getSession: getSession(updateCognitoContext, userPool),
    signOut: signOut(cognitoContext.user as CognitoUser, updateCognitoContext),
  };
};

export default useCognito;
