import { getBusinessApiEndPoint, HttpConfiguration } from './configureHttp';


let SSEBaseConfig: HttpConfiguration = {};

export function GetGlobalSSEUrl(idToken?: string): string {
  const { identityToken } = SSEBaseConfig;

  const commonIoUrl = getBusinessApiEndPoint('/v1.0/common-io');
  const gatewayUrl = new URL(commonIoUrl);
  gatewayUrl.pathname = gatewayUrl.pathname + '/v1.0/common-io';
  gatewayUrl.searchParams.append('idToken', idToken ? idToken : identityToken);
  return gatewayUrl.toString();
}

export function configureGlobalSSE(config: HttpConfiguration) {
  SSEBaseConfig = config;
}
