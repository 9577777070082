import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId'; // Using your improved hook
import toClipModel from '../services/toClipModel';
import { ClipModelFromServer } from '../types';
import { isUUID } from 'validator';
import { useCallback, useEffect } from 'react';

// Default gameId to use when the actual one is invalid
const DEFAULT_GAME_ID = '';

export const getQueryKey = (gameId: string) => {
  // Always return a valid queryKey, using DEFAULT_GAME_ID as fallback
  const validGameId = isUUID(gameId) ? gameId : DEFAULT_GAME_ID;
  return ['Games', validGameId, 'Clips'];
};

export const useCurrentGameClipsQuery = () => {
  const gameId = useCurrentGameId(); // This now directly reads from URL
  const queryClient = useQueryClient();

  
  // Calculate these values directly without useMemo
  const isValidGame = !!gameId && isUUID(gameId);
  const queryKey = getQueryKey(gameId);

  // Effect to remove invalid queries
  useEffect(() => {
    if (!isValidGame && gameId) {
      queryClient.removeQueries({ queryKey });
    }
  }, [queryClient, isValidGame, queryKey, gameId]);

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (!isValidGame) {
        return [];
      }
      const { data } = await get<ClipModelFromServer[]>(`/v1.0/games/${gameId}/clips`);
      return data.map((c) => toClipModel(c));
    },
 //   refetchInterval: 2_000,
    enabled: isValidGame,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0, // Treat data as immediately stale so it will refetch
  });

  // Add a method to invalidate and immediately refetch
  const invalidateAndRefetch = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey:['Games'] });
    return queryClient.refetchQueries({ queryKey:['Games'] });
  }, [queryClient, queryKey, isValidGame]);

  return {
    ...query,
    invalidateAndRefetch,
    currentGameId: gameId, // Include for debugging
  };
};

export default useCurrentGameClipsQuery;