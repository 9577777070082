import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useGames, usePreviousGamesInfinite, useNextGamesInfinite } from '../hooks/gameQueries';
import { useInView } from 'react-intersection-observer';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { Fade } from '@mui/material';
import LoadingIndicator from './LoadingIndicator';
import { groupGamesByDate, findClosestDateToToday, getCardWidth } from './utils/gameUtils';
import { GamesListProps } from '../types/Games';
import GamesContent from './GameContent';

const GamesList = ({
  teamId,
  seasonId,
  streamTypes,
  date,
  limit = 5,
  containerRef,
}: GamesListProps) => {
  // Core state
  const [isInitialPreparing, setIsInitialPreparing] = useState(true);
  const [isContentReady, setIsContentReady] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasFetchedPrevious, setHasFetchedPrevious] = useState(false);
  const [hasFetchedNext, setHasFetchedNext] = useState(false);
  const [activeStickyDate, setActiveStickyDate] = useState(null);
  // New state to track user interaction
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  // Track if initial positioning is complete
  const [initialPositioningComplete, setInitialPositioningComplete] = useState(false);

  // Refs
  const hiddenContainerRef = useRef(null);
  const dateRefs = useRef({});
  const initialScrollTimeoutRef = useRef(null);

  // Memo values
  const referenceDate = useMemo(() => format(date || new Date(), 'yyyy-MM-dd'), [date]);

  // Calculate responsive card dimensions
  const cardWidth = getCardWidth();
  const cardHeight = Math.round(cardWidth / 1.66); // 16:9 ratio

  // Initial data fetch with enough buffer to avoid loading states
  const {
    data: initialData,
    isLoading: isInitialLoading,
    error: initialError,
  } = useGames({
    teamId,
    seasonId,
    streamTypes,
    date,
    limit: 10,
    upcomingLimit: 10,
  });

  // Infinite scroll observers
  const { ref: previousLoadMoreRef, inView: isPreviousLoadMoreVisible } = useInView({
    threshold: 0.1,
    root: containerRef?.current || null,
    rootMargin: '200px 0px 0px 0px',
  });

  const { ref: nextLoadMoreRef, inView: isNextLoadMoreVisible } = useInView({
    threshold: 0.1,
    root: containerRef?.current || null,
    rootMargin: '0px 0px 200px 0px',
  });

  // Infinite queries for previous and next games - MODIFIED TO INCLUDE USER INTERACTION
  const {
    data: previousGamesData,
    fetchNextPage: fetchMorePreviousGames,
    hasNextPage: hasMorePreviousGames,
    isFetchingNextPage: isFetchingPreviousGames,
  } = usePreviousGamesInfinite({
    teamId,
    seasonId,
    limit,
    streamTypes,
    date,
    initialData,
    // Only enable when user has interacted AND hasFetchedPrevious is true
    enabled: userHasInteracted && hasFetchedPrevious && initialPositioningComplete,
  });

  const {
    data: nextGamesData,
    fetchNextPage: fetchMoreNextGames,
    hasNextPage: hasMoreNextGames,
    isFetchingNextPage: isFetchingNextGames,
  } = useNextGamesInfinite({
    teamId,
    seasonId,
    limit,
    initialData,
    // Only enable when user has interacted AND hasFetchedNext is true
    enabled: userHasInteracted && hasFetchedNext && initialPositioningComplete,
  });

  // Handlers for loading more games
  const handleLoadMorePreviousGames = useCallback(() => {
    if (userHasInteracted && !hasFetchedPrevious) {
      setHasFetchedPrevious(true);
      return;
    }

    if (userHasInteracted && hasMorePreviousGames && !isFetchingPreviousGames) {
      fetchMorePreviousGames();
    }
  }, [userHasInteracted, hasFetchedPrevious, hasMorePreviousGames, isFetchingPreviousGames, fetchMorePreviousGames]);

  const handleLoadMoreNextGames = useCallback(() => {
    if (userHasInteracted && !hasFetchedNext) {
      setHasFetchedNext(true);
      return;
    }

    if (userHasInteracted && hasMoreNextGames && !isFetchingNextGames) {
      fetchMoreNextGames();
    }
  }, [userHasInteracted, hasFetchedNext, hasMoreNextGames, isFetchingNextGames, fetchMoreNextGames]);

  // Combine all games for rendering
  const allGames = useMemo(() => {
    if (!initialData) return [];

    const prevGames = previousGamesData?.pages.flatMap((page) => page.games) || [];
    const initialGames = initialData.games;
    const nextGames = nextGamesData?.pages.flatMap((page) => page.games) || [];

    return [...prevGames, ...initialGames, ...nextGames];
  }, [initialData, previousGamesData, nextGamesData]);

  // Group games by date
  const gamesByDate = useMemo(
    () => groupGamesByDate(allGames, referenceDate, hasFetchedPrevious, hasFetchedNext),
    [allGames, referenceDate, hasFetchedPrevious, hasFetchedNext],
  );

  // Auto-fetch more games when scroll reaches boundaries - MODIFIED
  useEffect(() => {
    if (isPreviousLoadMoreVisible && hasScrolled && userHasInteracted && initialPositioningComplete) {
      handleLoadMorePreviousGames();
    }
  }, [isPreviousLoadMoreVisible, handleLoadMorePreviousGames, hasScrolled, userHasInteracted, initialPositioningComplete]);

  useEffect(() => {
    if (isNextLoadMoreVisible && hasScrolled && userHasInteracted && initialPositioningComplete) {
      handleLoadMoreNextGames();
    }
  }, [isNextLoadMoreVisible, handleLoadMoreNextGames, hasScrolled, userHasInteracted, initialPositioningComplete]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (initialScrollTimeoutRef.current) {
        clearTimeout(initialScrollTimeoutRef.current);
      }
    };
  }, []);


  // Setup scroll detection - COMPLETELY REWRITTEN
  useEffect(() => {
    const scrollContainer = containerRef?.current;
    if (!scrollContainer || isInitialPreparing || !initialPositioningComplete) return;

    // Minimum scroll amount to consider as user interaction (in pixels)
    const MIN_SCROLL_AMOUNT = 5;
    let lastScrollPosition = scrollContainer.scrollTop;
    
    const handleScroll = () => {
      const currentScrollPosition = scrollContainer.scrollTop;
      const scrollDifference = Math.abs(currentScrollPosition - lastScrollPosition);
      
      // Only consider it a user interaction if they've scrolled enough
      if (scrollDifference > MIN_SCROLL_AMOUNT) {
        setUserHasInteracted(true);
        setHasScrolled(true);
      
        // Determine scroll direction and enable relevant fetching
        const scrollDirection = currentScrollPosition > lastScrollPosition ? 'down' : 'up';
        
        if (scrollDirection === 'up' && !hasFetchedPrevious) {
          setHasFetchedPrevious(true);
        } else if (scrollDirection === 'down' && !hasFetchedNext) {
          setHasFetchedNext(true);
        }
      }
      
      lastScrollPosition = currentScrollPosition;
    };

    // Add scroll listener
    scrollContainer.addEventListener('scroll', handleScroll);
    
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [containerRef, hasFetchedPrevious, hasFetchedNext, isInitialPreparing, initialPositioningComplete]);

  // Set up sticky date header observer
  useEffect(() => {
    if (isInitialPreparing || !containerRef?.current) return;

    const observerOptions = {
      root: containerRef.current,
      rootMargin: '-1px 0px 0px 0px',
      threshold: [0, 1],
    };

    const visibleDates = [];

    const updateActiveStickyDate = () => {
      if (visibleDates.length === 0) {
        setActiveStickyDate(null);
        return;
      }

      // Sort by top position and find top-most sticky date
      const sortedDates = [...visibleDates].sort((a, b) => a.top - b.top);
      const activeDate = sortedDates[0].top < 0 ? sortedDates[0].date : null;
      setActiveStickyDate(activeDate);
    };

    const observer = new IntersectionObserver((entries) => {
      let needsUpdate = false;

      entries.forEach((entry) => {
        const dateElement = entry.target;
        const date = dateElement.dataset.date;
        if (!date) return;

        const rect = entry.boundingClientRect;
        const index = visibleDates.findIndex((item) => item.date === date);

        if (entry.isIntersecting) {
          if (index !== -1) {
            visibleDates[index].top = rect.top;
          } else {
            visibleDates.push({ date, top: rect.top });
          }
          needsUpdate = true;
        } else if (index !== -1) {
          visibleDates.splice(index, 1);
          needsUpdate = true;
        }
      });

      if (needsUpdate) {
        updateActiveStickyDate();
      }
    }, observerOptions);

    // Update positions while scrolling
    const handleScroll = () => {
      let needsUpdate = false;
      visibleDates.forEach((item) => {
        const element = dateRefs.current[item.date];
        if (element) {
          const rect = element.getBoundingClientRect();
          if (item.top !== rect.top) {
            item.top = rect.top;
            needsUpdate = true;
          }
        }
      });

      if (needsUpdate) {
        updateActiveStickyDate();
      }
    };

    // Observe all date headers
    Object.entries(dateRefs.current).forEach(([date, element]) => {
      if (element) {
        element.dataset.date = date;
        observer.observe(element);
      }
    });

    containerRef.current.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      observer.disconnect();
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [gamesByDate, containerRef, isInitialPreparing]);

  // Initial setup and positioning - MODIFIED
  useEffect(() => {
    if (isInitialLoading || !initialData || !gamesByDate.length) return;
    if (isContentReady) return;

    // Clear any previous timeout
    if (initialScrollTimeoutRef.current) {
      clearTimeout(initialScrollTimeoutRef.current);
    }

    initialScrollTimeoutRef.current = setTimeout(() => {
      const targetDate = findClosestDateToToday(gamesByDate, referenceDate);
      const targetElement = targetDate ? dateRefs.current[targetDate] : null;

      if (targetElement && hiddenContainerRef.current) {
        const containerHeight = hiddenContainerRef.current.clientHeight;
        const elementHeight = targetElement.offsetHeight;
        const elementOffsetTop = targetElement.offsetTop;
        const scrollTarget = elementOffsetTop - (containerHeight - elementHeight) / 2;
        
        // Set scroll position
        if (containerRef?.current) {
          containerRef.current.scrollTop = scrollTarget;
        }
        hiddenContainerRef.current.scrollTop = scrollTarget;

        setIsInitialPreparing(false);
        
        // Set initialPositioningComplete with a delay to ensure scroll events from positioning are done
        setTimeout(() => {
          setInitialPositioningComplete(true);
          setTimeout(() => setIsContentReady(true), 100);
        }, 300);
      } else {
        setIsInitialPreparing(false);
        setInitialPositioningComplete(true);
        setIsContentReady(true);
      }
    }, 100);
    
  }, [isInitialLoading, initialData, gamesByDate, referenceDate, containerRef]);

  // Handle error states
  if (initialError) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        Error loading games: {initialError.message}
      </Alert>
    );
  }

  if (!initialData && !isInitialLoading) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No games found
      </Alert>
    );
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Loading overlay */}
      {(isInitialPreparing || !isContentReady) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'background.default',
            zIndex: 10,
          }}
        >
          <LoadingIndicator message="Loading games..." />
        </Box>
      )}

      <Box
        ref={hiddenContainerRef}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          visibility: 'hidden',
          zIndex: -1,
        }}
      >
        <GamesContent
          gamesByDate={gamesByDate}
          dateRefs={dateRefs}
          cardHeight={cardHeight}
          allGames={allGames}
          hasFetchedPrevious={hasFetchedPrevious}
          isFetchingPreviousGames={isFetchingPreviousGames}
          hasMorePreviousGames={hasMorePreviousGames}
          hasFetchedNext={hasFetchedNext}
          isFetchingNextGames={isFetchingNextGames}
          hasMoreNextGames={hasMoreNextGames}
          previousLoadMoreRef={previousLoadMoreRef}
          nextLoadMoreRef={nextLoadMoreRef}
          activeStickyDate={activeStickyDate}
        />
      </Box>

      {/* Visible container */}
      <Fade in={isContentReady} timeout={900}>
        <Box
          sx={{
            visibility: isContentReady ? 'visible' : 'hidden',
            opacity: isContentReady ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          <GamesContent
            gamesByDate={gamesByDate}
            dateRefs={dateRefs}
            cardHeight={cardHeight}
            allGames={allGames}
            hasFetchedPrevious={hasFetchedPrevious}
            isFetchingPreviousGames={isFetchingPreviousGames}
            hasMorePreviousGames={hasMorePreviousGames}
            hasFetchedNext={hasFetchedNext}
            isFetchingNextGames={isFetchingNextGames}
            hasMoreNextGames={hasMoreNextGames}
            previousLoadMoreRef={previousLoadMoreRef}
            nextLoadMoreRef={nextLoadMoreRef}
            activeStickyDate={activeStickyDate}
          />
        </Box>
      </Fade>
    </Box>
  );
};

export default GamesList;