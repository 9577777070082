import { AppPage } from '@/shared/components';
import useCurrentOrgState from '@/shared/hooks/useCurrentOrg';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { Typography, Divider, Stack } from '@mui/material';
import { Container } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { LoadingSpinner } from '@/shared/components/LoadingSpinner';
import HourlyScheduler, { Planning } from './HourlyScheduler';
import { useEffect, useState } from 'react';
import { OrgSchedule } from '../hooks/useUpdateOrganizationInstanceSchedulingMutation';

const ManageInstancePlanning = () => {
  const title = 'CXVH Instance Planning';

  useDocumentTitle(title);

  const {
    isLoading,
    scheduling,
    actions: { updateScheduling, applyScheduling },
  } = useCurrentOrgState();

  const [planning, setPlanning] = useState<Planning[]>([]);

  const onPlanningChange = async (schedule: Planning[]) => {
    const newSchedule = schedule.map((s) => ({
      hourOfTheDay: s.hour,
      availableInstances: s.instances,
    }));
    await updateScheduling(newSchedule);
    showSnackbar('Schedule applied successfully');
  };

  const showSnackbar = (message: string, severity = 'success') => {
    //@ts-expect-error
    enqueueSnackbar(message, { variant: severity });
  };

  useEffect(() => {
    if (!scheduling || scheduling.length === 0) return;
    const p = [];
    for (let i = 0; i < 24; i++) {
      if (!scheduling.some((s: OrgSchedule) => s.hourOfTheDay === i)) {
        p.push({ instances: 0, hour: i });
      } else {
        const entry = scheduling.find((s: OrgSchedule) => s.hourOfTheDay === i);
        p.push({
          instances: entry.availableInstances,
          hour: entry.hourOfTheDay,
        });
      }
    }

    setPlanning(p);
  }, [scheduling]); // Only scheduling as dependency

  if (isLoading) {
    return <LoadingSpinner text="Loading data..." />;
  }

  return (
    <AppPage title={title} requiredPermissions={['menu:manage:games']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">Manage Organization Departments</Typography>
        <Divider sx={{ marginTop: 3 }} />

        <Stack spacing={2}>
          <HourlyScheduler planning={planning} onChange={onPlanningChange} onApply={applyScheduling}></HourlyScheduler>
        </Stack>
      </Container>
    </AppPage>
  );
};

export default ManageInstancePlanning;
