// UserManagement/index.tsx
import React from 'react';
import { Stack, Button, Typography, Box } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { InviteUserDialog } from './dialogs/InviteUserDialog';
import { InvitedUsersSection } from './components/InvitedUsersSection';
import { OrganizationMembersSection } from './components/OrganizationMembersSection';
import { DefaultRoleSection } from './components/DefaultRoleSection';
import { EditUserDialog } from './dialogs/EditUserDialog';
import { DisableUserDialog } from './dialogs/DisableUserDialog';
import { DeleteUserDialog } from './dialogs/DeleteUserDialog';
import { useUserManagement } from './hooks/useUserManagement';
import useCurrentOrgState from '@/shared/hooks/useCurrentOrg';
import { LoadingSpinner } from '@/shared/components/LoadingSpinner';

const UserManagement: React.FC = () => {
  const {
    state,
    handlers,
    mutations,
    groups,
    currentOrg,
    isLoading: userStateIsLoading,
  } = useUserManagement();

  const { departments, isLoading: orgStateIsLoading } = useCurrentOrgState();

  if (userStateIsLoading || orgStateIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} p={3}>
        <Box />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlers.setInviteOpen(true)}
          startIcon={<PersonAddIcon />}
        >
          Invite User
        </Button>
      </Stack>

      <InvitedUsersSection
        invitedUsers={state.invitedUsers}
        onEditUser={handlers.handleEditUserGroup}
        onDeleteUser={handlers.handleDelete}
        onDisableUser={handlers.handleDisable}
        onInviteUser={handlers.handleInvite}
      />

      <OrganizationMembersSection
        users={state.users}
        onEditUser={handlers.handleEditUserGroup}
        onDeleteUser={handlers.handleDelete}
        onDisableUser={handlers.handleDisable}
      />

      <DefaultRoleSection
        currentOrg={currentOrg}
        groups={groups}
        onDefaultRoleChange={handlers.setDefaultRole}
      />

      <EditUserDialog
        //@ts-expect-error
        departments={departments}
        open={state.editDialogOpen}
        user={state.selectedUser}
        groupId={state.groupId}
        groups={groups}
        onClose={handlers.handleCancelEdit}
        onSave={handlers.handleUpdateUser}
        onDepartmentsChange={handlers.setUserDepartments}
    
        onGroupChange={handlers.setGroup}
        loading={mutations.inviteUserToOrganizationMutation.status === 'pending'}
      />

      <DisableUserDialog
        open={state.disableDialogOpen}
        user={state.selectedUser}
        onClose={() => handlers.setDisableDialogOpen(false)}
        onConfirm={handlers.confirmAccessToggle}
        disabled={state.confirmToggleDisabled}
      />

      <DeleteUserDialog
        open={state.deleteDialogOpen}
        user={state.selectedUser}
        onClose={() => handlers.setDeleteDialogOpen(false)}
        onConfirm={handlers.confirmDelete}
      />

      <InviteUserDialog
        open={state.inviteOpen}
        groups={groups}
        groupId={state.groupId}
        onClose={() => handlers.setInviteOpen(false)}
        onInvite={handlers.handleInvite}
        onEmailsChange={handlers.setInviteEmails}
        onGroupChange={handlers.setGroup}
        loading={mutations.inviteUserToOrganizationMutation.status === 'pending'}
        emails={state.inviteEmails}
      />
    </>
  );
};

export default UserManagement;
