import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

import themeOptions from './muiTheme';

export function getTheme(mode: PaletteMode) {
  themeOptions.palette.mode = mode;
  //themeOptions.colorScheme = mode === 'dark' ? 'dark' : 'light';
  // TODO override theme options based on mode
  return createTheme(themeOptions);
}
