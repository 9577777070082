import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Org } from '@/types/user';
import { del } from '@truefit/http-utils';


export type RemoveDepartmentToOrgModel = {
    orgId: number;
    departmentId: number;
}

const useRemoveDepartmentFromOrgMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: RemoveDepartmentToOrgModel) => {
            return await del<Org>(`/v1.0/org/${model.orgId}/departments/${model.departmentId}`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['org'] });
        },
    });
};
export { useRemoveDepartmentFromOrgMutation }
export default useRemoveDepartmentFromOrgMutation;
