import React, { ReactNode } from 'react';
import useUserState, { UserState } from '../hooks/user/userState';

type Props = {
  children: ReactNode;
};

export const UserStateContext = React.createContext({} as UserState);

const UserStateProvider = ({ children }: Props) => {
  const userState = useUserState();
  return (
    <UserStateContext.Provider value={userState}>
      {children}
    </UserStateContext.Provider>
  );
};

export default UserStateProvider;
