

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';


export type UserDisableModel = {
    userId?: number;
}

export const useDisableUserMutation = (orgId:number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: UserDisableModel):Promise<void> => {
            const response = await put(`/v1.0/users/${model.userId}`, {action: 'toggle'});
            return response.data;
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['AllUsers', orgId] })
    });
};

export default useDisableUserMutation;
