import { Stack, Typography, Tooltip, Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

type Props = {
  title: string;
  description?: string;
  padding: number;
  tooltip?: string;
  mt?: number;
  mb?: number;
  actionIcon?: React.ReactNode;
  onActionClick?: () => void;
};

export const SectionTitle = ({ 
  title, 
  description, 
  padding, 
  tooltip, 
  mt, 
  mb, 
  actionIcon,
  onActionClick 
}: Props) => {
  return (
    <Stack
      direction="column" // Stack elements vertically
      spacing={0.5} // Add spacing between title and description
      sx={{
        mt: mt || 3,
        ml: padding,
        mr: padding - 1,
        mb: mb|| 3,
        '& .MuiIconButton-sizeSmall': {
          fontSize: 10,
        }
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoOutlinedIcon
                sx={{
                  fontSize: 18,
                  marginLeft: 1, // Add spacing between title and icon
                  cursor: 'help', // Change cursor to indicate interactivity
                  color: 'grey.600', // Make the icon slightly subdued
                }}
              />
            </Tooltip>
          )}
        </Box>
        {actionIcon && onActionClick && (
          <IconButton 
            size="small"
            onClick={onActionClick}
            sx={{
              ml: 1,
            }}
          >
            {actionIcon}
          </IconButton>
        )}
      </Box>
      {description && (
        <Typography
          variant="caption"
          sx={{
            color: 'grey.600', // Use Material-UI's grey palette for a subtle grey color
          }}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default SectionTitle;