import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
// Import types and hooks
import { ClipModelFromServer } from '../hooks/useAllClipsQuery';
import useEnqueueClipForExportMutation from '../hooks/useEnqueueClipForExportMutation';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import useClipDownloadMutation from '../hooks/useClipDownloadMutation';
import useDownloadLogsMutation from '../hooks/useDownloadLogsMutation';
import useDownloadConfigMutation from '../hooks/useDownloadConfigMutation';
import useCancelClipExportMutation from '../hooks/useCancelClipExportMutation';
import StatusIndicator from '@/shared/components/ClipStatus';

const ClipsTable: React.FC<{
  setPaginationModel: React.Dispatch<React.SetStateAction<{ page: number; pageSize: number }>>;
  paginationModel: { page: number; pageSize: number };
  isLoading: boolean;
  error: unknown;
  data: { rowCount: number; rows: ClipModelFromServer[] };
}> = ({ setPaginationModel, paginationModel, isLoading, error, data }) => {
  // Export mutation hook
  const exportClipMutation = useEnqueueClipForExportMutation();
  const downloadClipMutation = useClipDownloadMutation();
  const downloadExportLogsMutation = useDownloadLogsMutation();
  const downloadConfigMutation = useDownloadConfigMutation();
  const cancelExportMutation = useCancelClipExportMutation();

  const [canceling, setCanceling] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);

  // Handle cancel export clip
  const handleCancelExportClip = async(clipId: string) => {
    setCanceling(true);
    await cancelExportMutation.mutate(parseInt(clipId));
    setCanceling(false);
  }

  // Handle export clip
  const handleExportClip = async(clipId: string) => {
    setExporting(true);
    await exportClipMutation.mutate(parseInt(clipId));
    setExporting(false);
  };

  const handleDownloadClip = (clipId: string) => {
    downloadClipMutation.mutate({ clipId, video: true });
  };

  const handleDownloadExportLogs = (clipId: string) => {
    downloadExportLogsMutation.mutate(parseInt(clipId));
  }

  const handleDownloadConfig = (clipId: string) => {
    downloadConfigMutation.mutate(parseInt(clipId));
  };


  // Memoized columns
  const columns = useMemo<MRT_ColumnDef<ClipModelFromServer>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },
      {
        accessorKey: 'note',
        header: 'Clip Name',
        size: 200,
        Cell: ({ row }) => (
          <Typography
            variant="body2"
            sx={{
              textTransform: 'capitalize',
              wordBreak: 'break-word',
            }}
          >
            {row.original.note}
          </Typography>
        ),
      },
      {
        accessorKey: 'mediatype',
        header: 'Type',
        size: 120,
        Cell: ({ row }) => (
          <Chip
            label={row.original.mediatype}
            color={row.original.mediatype === 'bookmark' ? 'info' : 'default'}
            size="small"
            variant="outlined"
          />
        ),
      },
      {
        accessorKey: 'game',
        header: 'Game',
        size: 250,
        Cell: ({ row }) => {
          const game = row.original.game;
          return (
            <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '100%' }}>
              <img
                src={getTeamLogoPath(game.awayTeam)}
                alt={`${game.awayTeam.name} logo`}
                width={30}
                height={30}
              />
              <Typography>{game.awayTeam.abbreviation}</Typography>
              <Typography variant="body2">@</Typography>
              <Typography>{game.homeTeam.abbreviation}</Typography>
              <img
                src={getTeamLogoPath(game.homeTeam)}
                alt={`${game.homeTeam.name} logo`}
                width={30}
                height={30}
              />
            </Stack>
          );
        },
      },
      {
        accessorKey: 'startTimestamp',
        header: 'Timestamp',
        enableSorting: true,
        enableHiding: false,
        filterFn: 'betweenDates',
        filterVariant: 'date',
        filterSelectOptions: [
          {
            text: 'Last 24 hours',
            value: 'last24Hours',
          },
          {
            text: 'Last 7 days',
            value: 'last7Days',
          },
          {
            text: 'Last 30 days',
            value: 'last30Days',
          },
          {
            text: 'Last 90 days',
            value: 'last90Days',
          },
          {
            text: 'All Time',
            value: 'allTime',
          },
        ],
        size: 200,
        Cell: ({ row }) => (
          <Typography variant="body2">
            {row.original?.startTimestamp
              ? format(parseISO(row.original.startTimestamp), 'MMM dd, yyyy hh:mm a')
              : ''}
          </Typography>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ row }) => {
          if (row.original.mediatype === 'bookmark') return null;
          return (
            <StatusIndicator status={row.original.status}  />
          );
        },
      },
    ],
    [],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data?.rows || []}
      enablePagination
      manualPagination
      rowCount={data?.rowCount || 0}
      state={{
        isLoading,
        pagination: {
          pageIndex: paginationModel.page,
          pageSize: paginationModel.pageSize,
        },
      }}
      onPaginationChange={(updater) => {
        const newPagination =
          typeof updater === 'function'
            ? updater({ pageIndex: paginationModel.page, pageSize: paginationModel.pageSize })
            : updater;
        setPaginationModel({
          page: newPagination.pageIndex,
          pageSize: newPagination.pageSize,
        });
      }}
      muiTableHeadCellProps={{
        sx: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      }}
      renderRowActions={({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Download Clip">
            <IconButton 
              onClick={() => handleDownloadClip(row.original.id)} 
              disabled={row.original.mediatype === 'bookmark' || row.original.status !== 'READY'}
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.38)', // Darker disabled color
                  opacity: 0.6, // Slightly reduce opacity for additional visual distinction
                }
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Config">
            <IconButton 
              onClick={() => handleDownloadConfig(row.original.id)} 
              disabled={row.original.mediatype === 'bookmark' ||  row.original.status !=='ERROR'}
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.38)',
                  opacity: 0.6,
                }
              }}
            >
              <CodeOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Logs">
            <IconButton 
              onClick={() => handleDownloadExportLogs(row.original.id)} 
              disabled={row.original.mediatype === 'bookmark' || row.original.status !=='ERROR'}
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.38)',
                  opacity: 0.6,
                }
              }}
            >
              <ReceiptLongOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Retry Clip">
            <IconButton 
              onClick={() => handleExportClip(row.original.id)} 
              disabled={row.original.mediatype === 'bookmark' || !['ERROR','CANCELED','UNPROCESSED'].includes(row.original.status) }
              loading={exporting}
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.38)',
                  opacity: 0.6,
                }
              }}
            >
              <ReplayOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Clip">
            <IconButton 
              loading={canceling}
              onClick={() => handleCancelExportClip(row.original.id)} 
              disabled={row.original.mediatype === 'bookmark' || !row.original.status.endsWith('ING') }
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.38)',
                  opacity: 0.6,
                }
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      enableRowActions={true}
      positionActionsColumn="last"
    />
  );
};

export default ClipsTable;
