import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Grid, Chip, ButtonGroup, Stack } from '@mui/material';

import NightsStayIcon from '@mui/icons-material/NightsStay';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import SpeedIcon from '@mui/icons-material/Speed';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import useConfirmationDialogContext from '@/shared/hooks/useConfirmationDialogContext';
import { PowerOff } from 'lucide-react';

const getHourColor = (hour) => {
  // Define the daylight hours (sunrise at 6am, sunset at 6pm)
  const sunrise = 6;
  const sunset = 18;
  const noon = 12;

  // Calculate lightness percentage (darker at night, lighter during day)
  let lightness;

  if (hour >= sunrise && hour <= sunset) {
    // During day: highest lightness at noon, gradually decreasing toward sunrise/sunset
    const distanceFromNoon = Math.abs(hour - noon);
    // Scale from 90% (bright midday) to 60% (dimmer at sunrise/sunset)
    lightness = 90 - (distanceFromNoon / (noon - sunrise)) * 30;
  } else {
    // During night: darker with the darkest point at midnight
    const midnight = 0;
    const distanceFromMidnight = hour < midnight + 6 ? hour : 24 - hour;
    // Scale from 20% (darkest at midnight) to 50% (dimmer approaching sunrise/sunset)
    lightness = 20 + (distanceFromMidnight / 6) * 30;
  }

  // Return HSL color with blue-ish hue for night, yellow-ish for day
  const hue = hour >= sunrise && hour <= sunset ? 210 : 240;
  const saturation = hour >= sunrise && hour <= sunset ? 25 : 30;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export type Planning = {
  hour: number;
  instances: number;
};

export type Props = {
  planning: Planning[];
  onChange: (newPlanning: any[]) => void;
  onApply: () => void;
};

const HourlyScheduler = ({ planning, onChange, onApply }: Props) => {
  const setConfirmationDialog = useConfirmationDialogContext();

  // State for tracking Apply button disabled status
  const [applyDisabled, setApplyDisabled] = useState(false);
  // State for countdown timer display (in seconds)
  const [cooldownTime, setCooldownTime] = useState(0);

  // Check localStorage for button cooldown on component mount
  useEffect(() => {
    const checkButtonStatus = () => {
      const lastApplyClick = localStorage.getItem('lastApplyClick');
      if (lastApplyClick) {
        const lastClickTime = parseInt(lastApplyClick, 10);
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastClickTime;
        const cooldownDuration = 2 * 60 * 1000; // 2 minutes in milliseconds

        if (elapsedTime < cooldownDuration) {
          // Button should still be disabled
          setApplyDisabled(true);

          // Calculate remaining time in seconds
          const remainingTime = Math.ceil((cooldownDuration - elapsedTime) / 1000);
          setCooldownTime(remainingTime);

          // Set a timeout to enable the button when cooldown is complete
          const timerId = setTimeout(() => {
            setApplyDisabled(false);
            setCooldownTime(0);
          }, cooldownDuration - elapsedTime);

          // Set an interval to update the countdown display
          const countdownId = setInterval(() => {
            setCooldownTime((prev) => {
              if (prev <= 1) {
                clearInterval(countdownId);
                return 0;
              }
              return prev - 1;
            });
          }, 1000);

          return () => {
            clearTimeout(timerId);
            clearInterval(countdownId);
          };
        }
      }
    };

    checkButtonStatus();
  }, []);

  // Initial state with default of 0 instance for each hour
  const [schedule, setSchedule] = useState(
    Array.from({ length: 24 }, (_, i) => ({ hour: i, instances: 0 })),
  );

  // Maximum number of instances in the stack
  const maxInstances = 10;

  // Minimum number of instances (start from 0)
  const minInstances = 0;

  // Quick presets for common patterns
  const presets = {
    businessHours: Array.from({ length: 24 }, (_, i) => ({
      hour: i,
      instances: i >= 9 && i < 17 ? 5 : 0,
    })),
    nightReduced: Array.from({ length: 24 }, (_, i) => ({
      hour: i,
      instances: i >= 22 || i < 6 ? 0 : 3,
    })),
    peakLoad: Array.from({ length: 24 }, (_, i) => ({
      hour: i,
      instances: i >= 11 && i < 14 ? 5 : i >= 8 && i < 19 ? 4 : 1,
    })),
    nightFocused: Array.from({ length: 24 }, (_, i) => ({
      hour: i,
      instances: i >= 19 && i < 23 ? 5 : i >= 17 && i < 19 ? 3 : 0,
    })),
    turnOff: Array.from({ length: 24 }, (_, i) => ({
      hour: i,
      instances: 0,
    })),
  };

  // Handler for clicking on stack items
  const handleStackClick = (hour: number, value: number) => {
    setSchedule(
      schedule.map((item) => (item.hour === hour ? { ...item, instances: value } : item)),
    );
  };

  // Apply a preset
  const applyPreset = (presetName: string) => {
    //@ts-expect-error
    if (presets[presetName]) {
      //@ts-expect-error
      setSchedule(presets[presetName]);
    }
  };

  const saveHandler = useCallback(() => {
    setConfirmationDialog({
      confirmButtonStyle: 'warning',
      title: `Save Changes?`,
      message: <Typography>Are you sure you want to save the new schedule?</Typography>,
      confirmText: `Confirm`,
      cancelText: 'Cancel',
      onConfirm: async () => await onChange(schedule),
    });
  }, [setConfirmationDialog, schedule, onChange]);

  const applyHandler = useCallback(() => {
    setConfirmationDialog({
      confirmButtonStyle: 'warning',
      title: `Apply Changes?`,
      message: (
        <Typography>
          Are you sure you want to apply the new schedule? leave 5 minutes for the scheduler to
          adjust the available instances
        </Typography>
      ),
      confirmText: `Confirm`,
      cancelText: 'Cancel',
      onConfirm: async () => await onApply(),
    });
  }, [setConfirmationDialog, onApply]);

  // Format hour for display (12-hour format with AM/PM)
  const formatHour = (hour: number) => {
    const ampm = hour < 12 ? 'AM' : 'PM';
    const h = hour % 12 || 12;
    return `${h}${ampm}`;
  };

  // Total instances for the day
  const totalInstances = schedule.reduce((sum, { instances }) => sum + instances, 0);

  // Average instances per hour
  const avgInstances = (totalInstances / 24).toFixed(1);

  const reset = useCallback(() => {
    setSchedule(planning);
  }, [planning]);

  useEffect(() => {
    reset();
  }, [planning]);

  // Format seconds into mm:ss format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 2,
      }}
    >
      {/* Presets Section */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Quick Presets
        </Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ButtonGroup variant="contained" sx={{ flexWrap: 'wrap', gap: 1 }}>
            <Button
              startIcon={<WbSunnyIcon />}
              onClick={() => applyPreset('businessHours')}
              sx={{ textTransform: 'none' }}
            >
              Business Hours (9AM-5PM)
            </Button>
            <Button
              startIcon={<NightsStayIcon />}
              onClick={() => applyPreset('nightReduced')}
              sx={{ textTransform: 'none' }}
            >
              Night Reduced
            </Button>
            <Button
              startIcon={<SpeedIcon />}
              onClick={() => applyPreset('peakLoad')}
              sx={{ textTransform: 'none' }}
            >
              Peak Load Hours
            </Button>
            <Button
              startIcon={<SportsSoccerIcon />}
              onClick={() => applyPreset('nightFocused')}
              sx={{ textTransform: 'none' }}
            >
              Night Sports Games
            </Button>
            <Button
              startIcon={<PowerOff />}
              color='error'
              onClick={() => applyPreset('turnOff')}
              sx={{ textTransform: 'none' }}
            >
              Turn Off
            </Button>
          </ButtonGroup>
          <Button variant="contained" sx={{ m: 1 }} onClick={reset}>
            Reset
          </Button>
        </Stack>
      </Box>

      {/* Visual Stack Selector */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Hourly Instance Stacks
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* Legend */}
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ width: 60 }}></Box> {/* Spacer for hour labels */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                12AM
              </Typography>
              <Typography variant="caption" color="text.secondary">
                6AM
              </Typography>
              <Typography variant="caption" color="text.secondary">
                12PM
              </Typography>
              <Typography variant="caption" color="text.secondary">
                6PM
              </Typography>
              <Typography variant="caption" color="text.secondary">
                12AM
              </Typography>
            </Box>
          </Box>

          {/* Time-based background */}
          <Box sx={{ position: 'relative', display: 'flex', mb: 2 }}>
            <Box sx={{ width: 60 }}></Box> {/* Spacer for hour labels */}
            <Box sx={{ flex: 1, height: 32, position: 'relative' }}>
              {/* Day/Night Background */}
              {Array.from({ length: 24 }, (_, i) => (
                <Box
                  key={`bg-${i}`}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: `${(i / 24) * 100}%`,
                    width: `${(1 / 24) * 100}%`,
                    bgcolor: getHourColor(i),
                  }}
                />
              ))}

              {/* Hour Separators */}
              {Array.from({ length: 25 }, (_, i) => (
                <Box
                  key={`sep-${i}`}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    width: '1px',
                    bgcolor: 'divider',
                    left: `${(i / 24) * 100}%`,
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* Stack Rows */}
          {Array.from({ length: maxInstances }, (_, row) => {
            // Reverse the row index so 0 is at bottom and maxInstances at top
            const stackPosition = maxInstances - row;

            return (
              <Box key={`row-${row}`} sx={{ display: 'flex', mb: 0.5, alignItems: 'center' }}>
                <Box sx={{ width: 60, pr: 1, textAlign: 'right' }}>
                  <Typography variant="body2" fontWeight="medium">
                    {stackPosition}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, display: 'flex' }}>
                  {schedule.map(({ hour, instances }) => (
                    <Box
                      key={`stack-${hour}-${stackPosition}`}
                      sx={{
                        height: 32,
                        cursor: 'pointer',
                        borderRight: '1px solid white',
                        bgcolor: stackPosition <= instances ? 'primary.main' : 'grey.200',
                        '&:hover': {
                          bgcolor: stackPosition <= instances ? 'primary.light' : 'grey.300',
                        },
                        width: `${(1 / 24) * 100}%`,
                      }}
                      onClick={() => handleStackClick(hour, stackPosition)}
                      title={`Set ${formatHour(hour)} to ${stackPosition} instances`}
                    />
                  ))}
                </Box>
              </Box>
            );
          })}

          {/* Hour Labels */}
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Box sx={{ width: 60 }}></Box> {/* Spacer for alignment */}
            <Box sx={{ flex: 1, display: 'flex' }}>
              {schedule.map(({ hour }) => (
                <Box
                  key={`label-${hour}`}
                  sx={{
                    width: `${(1 / 24) * 100}%`,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="caption">{formatHour(hour)}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Current Configuration */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Current Configuration
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {schedule.map(({ hour, instances }) => (
            <Chip
              key={`config-${hour}`}
              label={`${formatHour(hour)}: ${instances}`}
              variant="outlined"
              size="small"
            />
          ))}
        </Box>
      </Box>

      {/* Summary & Export */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body2">
            <strong>Total Daily Instances:</strong> {totalInstances}
          </Typography>
          <Typography variant="body2">
            <strong>Average Per Hour:</strong> {avgInstances}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={saveHandler}>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={applyHandler}
            disabled={applyDisabled}
            sx={{ ml: 1 }}
          >
            {applyDisabled ? `Apply (${formatTime(cooldownTime)})` : 'Apply'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HourlyScheduler;
