

import { Org } from '@/types/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';


export type UpdateUserDepartmentsModel = {
    userId?: number;
    departmentIds?: number[];
    departmentId?: number;
}

export const updateUserDepartmentsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: UpdateUserDepartmentsModel): Promise<Org> => {
            if(!model.userId) {
                throw new Error('userId is required');
            }
            
            if(!model.departmentIds && !model.departmentId) {
                throw new Error('departmentId(s) is required');
            }

            const response = await put<Org>(`/v1.0/users/${model.userId}/departments`, model);
            return response.data;
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['AllUsers'] })
    });
};

export default updateUserDepartmentsMutation;
