import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface LoadingIndicatorProps {
  message?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ message = 'Loading...' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderColor: '#555',
        borderWidth: '1px',
        borderStyle: 'solid',
        color: 'white',
        borderRadius: '50px',
        padding: '8px 16px',
        width: 'fit-content',
      }}
    >
      <CircularProgress size={24} color="primary" sx={{ mr: 2 }} />
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
};

export default LoadingIndicator;