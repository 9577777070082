import React from 'react';
import { motion } from 'framer-motion';
import { 
  Box, 
  Chip, 
  Typography, 
  Collapse,
  Divider
} from '@mui/material';
import { Videocam } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import TrackedObject from '@/features/teams/components/TrackedObject';
import { Props } from 'react-timezone-select';
import { ClipType } from '../types';

const EnhancedCollapse = ({ 
  clip, 
  expanded, 
  isEditingSomeOtherClip, 
  collapseProps = {}, 
  clipDuration, 
  clipDescription, 
  displayDescription, 
  teams, 
  getCameraNameByCameraId 
}: Props) => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.3,
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <Collapse
      sx={{
        flexShrink: 0,
        backgroundColor: grey[800],
       // borderRadius: '0 0 8px 8px',
        overflow: 'hidden',
        ...(collapseProps.sx || {}),
      }}
      in={expanded && !isEditingSomeOtherClip}
    >
      {expanded && !isEditingSomeOtherClip && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {clip.type === ClipType.Clip && (
            <Box p={2}>
              {/* Header Section */}
              <motion.div variants={itemVariants}>
                <Box 
                  display="flex" 
                  sx={{ 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    marginBottom: 2 
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Clip Details
                  </Typography>
             
                  <Chip
                    size="small"
                    icon={<Videocam />}
                    label={getCameraNameByCameraId(clip.cameraId)}
                    sx={{ 
                      bgcolor: 'primary.main', 
                      color: 'white',
                      '& .MuiChip-icon': { color: 'white' }
                    }}
                  />
                </Box>
              </motion.div>
              
              <Divider sx={{ mb: 2, bgcolor: grey[700] }} />
              
              {/* Tracked Object Section */}
              <motion.div variants={itemVariants}>
                <Box
                  display="flex"
                  sx={{ 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    marginBottom: 2,
                    bgcolor: grey[850],
                    borderRadius: 1,
                    p: 1
                  }}
                >
                  <TrackedObject
                    readOnly={true}
                    clip={clip}
                    teams={teams}
                    sx={{ padding: '0px 16px', border: 'none' }}
                  />
                </Box>
              </motion.div>
              
              {/* Clip Info Section */}
              <motion.div variants={itemVariants}>
                <Box 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                  }}
                >
                  <Typography variant="body2" color={grey[400]} sx={{ mr: 1 }}>
                    Duration:
                  </Typography>
                  <Typography variant="body2" fontWeight="medium">
                    {clipDuration}
                  </Typography>
                </Box>
              </motion.div>

              {/* Description Section */}
              {displayDescription && (
                <motion.div variants={itemVariants}>
                  <Box
                    sx={{
                      overflowWrap: 'break-word',
                      width: '100%',
                      p: 2,
                      bgcolor: grey[850],
                      borderRadius: 1,
                      mb: 2
                    }}
                  >
                    <Typography variant="body2" color={grey[300]}>
                      {clipDescription}
                    </Typography>
                  </Box>
                </motion.div>
              )}
            </Box>
          )}

          {/* Bookmark Type Display */}
          {clip.type === ClipType.Bookmark && displayDescription && (
            <motion.div variants={itemVariants}>
              <Box
                p={2}
                sx={{
                  overflowWrap: 'break-word',
                  width: '100%',
                  bgcolor: grey[850],
                  borderRadius: 1,
                  mb: 2,
                  mx: 2
                }}
              >
                <Typography variant="subtitle2" color={grey[300]}>
                  {clipDescription}
                </Typography>
              </Box>
            </motion.div>
          )}
          
          {/* Footer Section */}
          <motion.div variants={itemVariants}>
            <Box 
              p={2} 
              sx={{ 
                borderTop: `1px solid ${grey[700]}`,
                pt: 1
              }}
            >
              <Typography variant="caption" color={grey[500]}>
                {clip.clouduser.email.toLowerCase()} &#183;&nbsp;
                {format(clip.createdAt, 'PP')}
                &#183;&nbsp;
                #{clip.id}
              </Typography>
            </Box>
          </motion.div>
        </motion.div>
      )}
    </Collapse>
  );
};

export default EnhancedCollapse;