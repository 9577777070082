import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Org } from '@/types/user';
import { del } from '@truefit/http-utils';


export type RemoveUserFromDepartmentModel = {
    userId: number;
    departmentId: number;
}

const useRemoveUserFromDepartmentMutation = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (model: RemoveUserFromDepartmentModel) => {
      return await del<Org>(`/v1.0/departments/${model.departmentId}/users/${model.userId}`);
    },
    onSuccess: () => {
     // queryClient.invalidateQueries({ queryKey: ['org'] });
    },
  });
};
export {useRemoveUserFromDepartmentMutation}
export default useRemoveUserFromDepartmentMutation;
