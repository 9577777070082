import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Org } from '@/types/user';
import { post } from '@truefit/http-utils';


export type AddDepartmentToOrgModel = {
    orgId: number;
    name: string;
}

const useAddDepartmentToOrgMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: AddDepartmentToOrgModel) => {
            return await post<Org>(`/v1.0/org/${model.orgId}/departments`, model);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['org'] });
        },
    });
};
export { useAddDepartmentToOrgMutation }
export default useAddDepartmentToOrgMutation;
