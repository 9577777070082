
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

const useEnqueueClipForExportMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (clipId: number) => {
      await get(`/v1.0/clips/${clipId}/export`);
    },
    gcTime: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clips'] });
    }
  });
}

export default useEnqueueClipForExportMutation;
