import React, { useState, useEffect, use } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Group } from '@/types/user';
import { User } from '@/types/user';

interface Department {
  id: number;
  name: string;
}

interface EditUserDialogProps {
  open: boolean;
  user: User | null;
  groupId: number;
  groups: Group[];
  loading: boolean;
  departments: Department[];
  onClose: () => void;
  onSave: () => void;
  onGroupChange: (groupId: number) => void;
  onDepartmentsChange: (deptIds: number[]) => void;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = ({
  open,
  user,
  groupId,
  groups,
  departments = [], // Provide default empty array
  loading,
  onClose,
  onSave,
  onGroupChange,
  onDepartmentsChange
}) => {
  // Track selected departments
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);

  // Initialize selected departments when user changes or dialog opens
  useEffect(() => {
    if (user && user.userdepartments) {
      setSelectedDepartments(user.userdepartments.map((dept) => dept.departmentId));
    } else {
      setSelectedDepartments([]);
    }
  }, [user, open]);

  // Handle department selection toggle
  const handleDepartmentToggle = (deptId: number) => {
    setSelectedDepartments((prev) => {
      if (prev.includes(deptId)) {
        return prev.filter((id) => id !== deptId);
      } else {
        return [...prev, deptId];
      }
    });
  };

  // Update parent state when departments change
  useEffect(() => {
    onDepartmentsChange(selectedDepartments);
  }, [selectedDepartments]);

  if (!user) return null;
  const isDepartmentsLoading = departments.length === 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: { backdropFilter: 'blur(4px)' },
        },
      }}
    >
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent dividers sx={{ minWidth: 450 }}>
        <Stack spacing={2} direction="column" sx={{ p: 2 }}>
          <FormControl fullWidth>
            <TextField id="email" value={user.email} disabled fullWidth label="Email" />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-label-edit-user">Group</InputLabel>
            <Select
              id="group"
              value={groupId}
              label="Group"
              onChange={(e) => onGroupChange(e.target.value as number)}
              fullWidth
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <Typography variant="subtitle1" gutterBottom>
              Departments
            </Typography>
            {isDepartmentsLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" py={2}>
                <CircularProgress size={24} />
                <Typography variant="body2" sx={{ ml: 2 }}>
                  Loading departments...
                </Typography>
              </Box>
            ) : departments.length > 0 ? (
              <FormGroup>
                {departments.map((dept) => (
                  <FormControlLabel
                    key={dept.id}
                    control={
                      <Checkbox
                        checked={selectedDepartments.includes(dept.id)}
                        onChange={() => handleDepartmentToggle(dept.id)}
                      />
                    }
                    value={dept.id}
                    label={dept.name}
                  />
                ))}
              </FormGroup>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No departments available
              </Typography>
            )}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          onClick={() => {
            // Pass selected departments to parent when saving
            onSave();
          }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
