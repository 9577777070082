import React, { createContext, useContext, ReactNode } from 'react';
import useAutoIsoTeamSelectorState, { AutoIsoTeamSelectorState } from './useAutoIsoTeamSelectorState';

// Create the context with a default empty state
const AutoIsoTeamSelectorContext = createContext<AutoIsoTeamSelectorState | undefined>(undefined);

// Provider props interface
interface AutoIsoTeamSelectorProviderProps {
  children: ReactNode;
}

/**
 * Provider component that wraps its children with the AutoIsoTeamSelector context
 */
export const AutoIsoTeamSelectorProvider: React.FC<AutoIsoTeamSelectorProviderProps> = ({ children }) => {
  // Use the existing hook to get all the state and handlers
  const teamSelectorState = useAutoIsoTeamSelectorState();

  return (
    <AutoIsoTeamSelectorContext.Provider value={teamSelectorState}>
      {children}
    </AutoIsoTeamSelectorContext.Provider>
  );
};

/**
 * Custom hook to use the AutoIsoTeamSelector context
 * @returns The AutoIsoTeamSelector state and handlers
 * @throws Error if used outside of the AutoIsoTeamSelectorProvider
 */
export const useAutoIsoTeamSelector = (): AutoIsoTeamSelectorState => {
  const context = useContext(AutoIsoTeamSelectorContext);
  
  if (context === undefined) {
    throw new Error('useAutoIsoTeamSelector must be used within an AutoIsoTeamSelectorProvider');
  }
  
  return context;
};

export default AutoIsoTeamSelectorContext;